<div class="fail-wrapper">
  <div *ngIf="!mobileView" class="fail-container">
    <div class="d-flex red-code" style="margin-bottom: 8px">
      <img
        src="../../../../../../assets/images/cancel_s.svg"
        style="margin-right: 10px"
      />
      <ng-container *ngFor="let data of codeArray; let index = index">
        <span *ngIf="index != 0" class="hiphen"></span>
        <span>{{ data }}</span>
      </ng-container>
    </div>
    <div class="feedback">
      {{ showError }}
    </div>
    <div class="d-flex justify-content-between">
      <form (keyup.enter)="$event.preventDefault(); submitBtn($event)">
        <app-input
          style="width: 70%"
          [control]="form.get('code')"
          [angularMask]="mask"
          [textAlign]="'center'"
          [class]="codeClass"
          [hint]="'xxxx-xxxx-xxxx-xxxx'"
          [width]="'100%'"
          [height]="'68px'"
        ></app-input>
      </form>
      <app-button
        style="width: 25%"
        [width]="'100%'"
        [height]="'64px'"
        [loading]="loading"
        [class]="'red-primary-m'"
        (click)="submitBtn($event)"
      >
        <app-text class="sub-btn">{{ locale?.failCode.submit }}</app-text>
      </app-button>
    </div>
  </div>
  <div *ngIf="mobileView" class="fail-container-mb">
    <div class="d-flex red-code-mb" style="margin-bottom: 8px">
      <img
        src="../../../../../../assets/images/cancel_s.svg"
        style="margin-right: 10px"
      />
      <ng-container *ngFor="let data of codeArray; let index = index">
        <span *ngIf="index != 0" class="hiphen"></span>
        <span>{{ data }}</span>
      </ng-container>
    </div>
    <div class="feedback-mb">
      {{ showError }}
    </div>
    <div class="d-flex flex-column" style="align-items: center">
      <form
        (keyup.enter)="$event.preventDefault(); submitBtn($event)"
        style="margin-bottom: 16px"
      >
        <app-input
          [control]="form.get('code')"
          [angularMask]="mask"
          [textAlign]="'center'"
          [class]="codeClass"
          [hint]="'xxxx-xxxx-xxxx-xxxx'"
          [width]="'100%'"
          [height]="'68px'"
        ></app-input>
      </form>
      <app-button
        style="width: 100%"
        [width]="'100%'"
        [height]="'64px'"
        [loading]="loading"
        [class]="'red-primary-m'"
        (click)="submitBtn($event)"
      >
        <app-text class="sub-btn">Submit</app-text>
      </app-button>
    </div>
  </div>
  <div *ngIf="!mobileView" class="btn-container">
    <div style="width: 46%">
      <app-button
        [width]="'100%'"
        [class]="'red-border'"
        (click)="trialClick($event)"
      >
        <app-text class="bot-btn">{{ locale?.failCode.trial }}</app-text>
      </app-button>
    </div>
    <div style="width: 46%">
      <app-button
        [width]="'100%'"
        [class]="'green-border'"
        (click)="loginClick($event)"
      >
        <app-text class="bot-btn">{{ locale?.failCode.claim }}</app-text>
      </app-button>
    </div>
  </div>
  <div *ngIf="mobileView" class="btn-container-mb">
    <div>
      <app-button
        [width]="'100%'"
        [class]="'red-border'"
        (click)="trialClick($event)"
      >
        <app-text class="bot-btn">{{ locale?.failCode.trial }}</app-text>
      </app-button>
    </div>
    <div>
      <app-button
        [width]="'100%'"
        [class]="'green-border'"
        (click)="loginClick($event)"
      >
        <app-text class="bot-btn">{{ locale?.failCode.claim }}</app-text>
      </app-button>
    </div>
  </div>
</div>
