<div *ngIf="!mobileView" class="box mt-40 d-flex flex-column">
  <app-text class="header-text text-secondary700">{{
    locale?.digitCode.seeWhatYouHave
  }}</app-text>
  <div class="body-textwrapper">
    <app-text
      *ngFor="let val of locale?.digitCode.lookForThisIcon"
      class="normal-text text-c300"
      >{{ val == "icon" ? "" : val }}
      <app-image
        *ngIf="val == 'icon'"
        [src]="'../../../../../assets/images/plus.svg'"
      ></app-image>
    </app-text>
  </div>
  <app-text class="bold-text1 text-c300 mt-40 mb-16">{{
    locale?.digitCode.enterCode
  }}</app-text>
  <form (keyup.enter)="$event.preventDefault(); submitBtn($event)">
    <app-input
      style="margin-bottom: 16px"
      [control]="form.get('code')"
      [angularMask]="mask"
      [textAlign]="'center'"
      [class]="codeClass"
      [hint]="'xxxx-xxxx-xxxx-xxxx'"
      [width]="'560px'"
      [height]="'68px'"
    ></app-input>
  </form>
  <div *ngIf="form.get('code').errors" class="error">{{ errorMsg }}</div>
  <div
    class="d-flex justify-content-center"
    style="margin-top: 24px !important"
  >
    <app-button
      [class]="'red-primary-m'"
      [loading]="loading"
      [width]="'240px'"
      [height]="'64px'"
      (click)="submitBtn($event)"
    >
      <app-text class="sub-btn">{{ locale?.digitCode.submit }}</app-text>
    </app-button>
  </div>
</div>

<div *ngIf="mobileView" class="box-mb mt-40 d-flex flex-column">
  <app-text class="header-text-mb text-secondary700">{{
    locale?.digitCode.seeWhatYouHave
  }}</app-text>
  <div class="body-textwrapper">
    <app-text
      *ngFor="let val of locale?.digitCode.lookForThisIcon"
      class="normal-text-mb text-c300"
      >{{ val == "icon" ? "" : val }}
      <app-image
        *ngIf="val == 'icon'"
        [src]="'../../../../../assets/images/plus.svg'"
      ></app-image>
    </app-text>
  </div>
  <app-text class="bold-text1-mb text-c300 mb-16" style="margin-top: 24px">{{
    locale?.digitCode.enterCode
  }}</app-text>
  <form (keyup.enter)="$event.preventDefault(); submitBtn($event)">
    <app-input
      style="margin-bottom: 16px"
      [control]="form.get('code')"
      [angularMask]="mask"
      [textAlign]="'center'"
      [class]="codeClass"
      [hint]="'xxxx-xxxx-xxxx-xxxx'"
      [width]="'295px'"
      [height]="'68px'"
    ></app-input>
  </form>
  <div *ngIf="form.get('code').errors" class="error">{{ errorMsg }}</div>
  <div
    class="d-flex justify-content-center"
    style="margin-top: 24px !important"
  >
    <app-button
      [class]="'red-primary-m'"
      [loading]="loading"
      [width]="'240px'"
      [height]="'64px'"
      (click)="submitBtn($event)"
      [height]="'56px'"
    >
      <app-text class="sub-btn">{{ locale?.digitCode.submit }}</app-text>
    </app-button>
  </div>
</div>
