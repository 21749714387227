import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class GlobalLoginStore {

}

export enum GlobalLoginStoreKeys {

}
