import { Component, Input, Output, EventEmitter } from '@angular/core';
import { RegisterInfoStore } from './register-info.store';
import { FormBuilder, Validators } from '@angular/forms';
import { SharedService } from 'src/app/lib/shared-service';
@Component({
  selector: 'app-register-info',
  templateUrl: './register-info.component.html',
  styleUrls: ['./register-info.component.css'],
})
export class RegisterInfoComponent {
  constructor(
    private store: RegisterInfoStore,
    private fb: FormBuilder,
    private shared: SharedService
  ) {}
  public form = this.fb.group({
    username: this.fb.control('', [Validators.required]),
    password: this.fb.control('', [
      Validators.required,
      Validators.minLength(8),
    ]),
  });

  public username;
  public password;
  public usernameClass = 'signup-full';
  public passwordClass = 'signup-full';
  public accountUrl = './assets/images/account.svg';
  public lockUrl = './assets/images/lock.svg';
  public eyeOpenUrl = './assets/images/eye_open.svg';
  public eyeCloseUrl = './assets/images/eye_close.svg';
  public clickedEyeOpen = false;
  public togglePassword = 'password';
  public clickBtn = false;
  @Input() usernameError = false;
  @Input() locale;

  @Input() firstName = 'aureon';
  @Input() loading: boolean = false;
  @Output() nextOutput = new EventEmitter<any>();
  @Output() loginOutput = new EventEmitter<any>();
  @Output() errorOutput = new EventEmitter<any>();

  ngOnInit() {
    this.form.valueChanges.subscribe((value) => {
      this.clickBtn = false;
      this.usernameClass = 'signup-full';
      this.passwordClass = 'signup-full';
      this.usernameError = false;
      this.errorOutput.emit();
    });

    // setInterval(() => {
    //   console.log("username error",this.usernameError);
    //   }, 1000);
  }
  ngOnChanges() {
    console.log('aureon btn', this.loading);
  }

  continueBtnClicked() {
    this.clickBtn = true;
    console.log('clicked');
    // this.existUserName = true;
    if (this.form.valid) {
      this.username = this.form.get('username').value.trim();
      this.password = this.form.get('password').value.trim();
      var registerObject = {
        username: this.username,
        password: this.password,
      };
      this.shared.setRegisterData(registerObject);
      this.nextOutput.emit(registerObject);
      console.log('next');
    } else {
      this.usernameClass = 'input-full-err';
      this.passwordClass = 'input-full-err';
    }
  }

  blurInput($event) {
    console.log('get blur event');
  }

  clickedEye() {
    this.clickedEyeOpen = !this.clickedEyeOpen;
    if (this.clickedEyeOpen) {
      this.togglePassword = 'text';
    } else {
      this.togglePassword = 'password';
    }
  }

  login() {
    this.loginOutput.emit();
  }
}
