import { BusinessLogicRequirements } from '../presentation/business-logic.requirements';
import { NgModule } from '@angular/core';
import { DataRequirementsInjectionToken } from './data.requirements';
import { DataFacade } from '../data/data.facade';
import { CountryMapper } from './mapper/country.mapper';
import { GetCountryListUseCase } from './use-cases/get-country-list.usecase';
import { Observable, of } from 'rxjs';
import { SignupUseCase } from './use-cases/signup.use-case';
import { AdditionalInfoUseCase } from './use-cases/additional-info.use-case';
import { CheckUsernameUseCase } from './use-cases/check-username.use-case';
import { ClaimBundleUseCase } from './use-cases/claim-bundle.use-case';
import { PublishKeyUseCase } from './use-cases/publish-key.use-case';
import { DiscountCheckUseCase } from './use-cases/discount-check.use-case';
import { GetCustomerInfoUseCase } from './use-cases/get-customerinfo.use-case';
import { GetSubscriptionInfoUseCase } from './use-cases/get-subscriptioninfo.use-case';
import { GetPromoInfoUseCase } from './use-cases/get-promoinfo.use-case';
import { GetStateUseCase } from './use-cases/get-state.use-case';
@NgModule({
  imports: [DataFacade],

  providers: [
    {
      provide: DataRequirementsInjectionToken,
      useClass: DataFacade,
    },
    CountryMapper,
  ],
})
export class BusinessLogicFacade implements BusinessLogicRequirements {
  constructor(
    private getCountryUseCase: GetCountryListUseCase,
    private signupUseCase: SignupUseCase,
    private additionalInfoUseCase: AdditionalInfoUseCase,
    private checkUsernameUseCase: CheckUsernameUseCase,
    private claimBundleUseCase: ClaimBundleUseCase,
    private discountCheckUseCase: DiscountCheckUseCase,
    private getCustomerInfoUseCase: GetCustomerInfoUseCase,
    private getSubscriptionInfoUseCase: GetSubscriptionInfoUseCase,
    private getPromoInfoUseCase: GetPromoInfoUseCase,
    private getStateUseCase: GetStateUseCase,
    private publishKeyUseCase: PublishKeyUseCase
  ) {}

  getCountries(): Observable<any> {
    return this.getCountryUseCase.run();
  }
  signup(object): Observable<any> {
    return this.signupUseCase.run(object);
  }
  additionalInfo(object): Observable<any> {
    return this.additionalInfoUseCase.run(object);
  }
  checkUsername(name): Observable<any> {
    return this.checkUsernameUseCase.run(name);
  }
  claimBundle(code): Observable<any> {
    console.log('bl facade');
    return this.claimBundleUseCase.run(code);
  }
  discountCheck(code): Observable<any> {
    return this.discountCheckUseCase.run(code);
  }
  getCustomerInfo(code): Observable<any> {
    return this.getCustomerInfoUseCase.run(code);
  }
  getSubscriptionInfo(data): Observable<any> {
    return this.getSubscriptionInfoUseCase.run(data);
  }
  getPromoInfo(data): Observable<any> {
    return this.getPromoInfoUseCase.run(data);
  }
  getState(data): Observable<any> {
    return this.getStateUseCase.run(data);
  }
  getPublishKey(): Observable<any> {
    return this.publishKeyUseCase.run();
  }
}
