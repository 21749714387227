<app-moving-bg></app-moving-bg>
<div class="back-div" (click)="backButton()">
  <img src="./assets/images/arrow.svg" />
</div>
<div class="d-flex flex-column justify-content-center align-items-center">
  <app-logo [class]="'middle'"></app-logo>
  <!-- <app-info-one
    *ngIf="step == 'one'"
    (continueBtn)="continueClicked($event)"
  ></app-info-one> -->
  <app-info-two
    [loading]="parentLoading"
    (currentCountry)="pickedCountry($event)"
    [countries]="countryList"
    [locale]="locale"
    (saveBtn)="saveClicked($event)"
    *ngIf="step == 'two' && Bundle"
  ></app-info-two>
  <app-successful-show
    [locale]="locale"
    *ngIf="step == 'success' && Bundle"
    [accountType]="'Parent'"
  ></app-successful-show>
  <app-content-code
    *ngIf="!Bundle"
    class="mt-5p"
    [loading]="codeLoading"
    [locale]="locale"
    (submitClicked)="callClaimBundle($event)"
  ></app-content-code>
</div>

<app-custom-feedback
  *ngIf="showFeedback"
  [feedbackTitle]="feedbackTitle"
  [feedbackBody]="feedbackBody"
  [feedbackBtn]="feedbackBtn"
  [tickIcon]="tickIcon"
></app-custom-feedback>
