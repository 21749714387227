import { Inject, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'src/app/lib/shared-service';
import {
  BusinessLogicRequirements,
  BusinessRequirementsInjectionToken,
} from '../business-logic.requirements';
import { TrialStore } from './trial.store';
import { environment } from 'src/environments/environment';
import { BundleModel } from 'src/app/models/bundle.model';
import { AuthService } from 'src/app/lib/auth.service';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocaleService } from 'src/app/lib/locale.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-trial',
  templateUrl: './trial-controller.component.html',
  styleUrls: ['./trial-controller.component.css'],
})
export class TrialControllerComponent {
  constructor(
    @Inject(BusinessRequirementsInjectionToken)
    private business: BusinessLogicRequirements,
    private store: TrialStore,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private shared: SharedService,
    private authService: AuthService,
    private http: HttpClient,
    private _localeService: LocaleService,
    private location: Location
  ) {
    this.getJSON().subscribe((data) => {
      console.log('countries json', data);
      this.countries = data;
    });
  }

  public countries: any;
  public isClaim = true;
  public isRegisterInfo = false;
  public isAdditionalInfo = false;
  public countryList;
  public countryHint;
  public videoUrl = environment.videoUrl;
  public videoType = environment.videoType;
  public showVideo = false;
  public bundleObject: BundleModel;
  public moreInfo: any;
  public usernameError = false;
  public firstName = '';

  public feedbackTitle = '';
  public feedbackBody = '';
  public feedbackBtn = {
    status: false,
    text: '',
  };
  public tickIcon = true;
  public showFeedback = false;

  public mobileView;
  public mobileViewService;
  public refer;
  public vendor;
  public states = ['name', 'register', 'additional'];
  public backBtnState = this.states[0];
  public userInfo: any;
  public countryData: any;
  public popupFlag = false;
  public registerLoading: boolean = false;
  public additionalLoading: boolean = false;
  public dashboardRedir: any = '';
  public locale: any;

  ngOnInit() {
    this.getLocaleJson();
    localStorage.removeItem('bundleInfo');
    localStorage.removeItem('userInfo');
    localStorage.removeItem('state');
    localStorage.removeItem('contentCode');

    // localStorage.setItem('currentCountry',this.countryHint);
    this.mobileViewService = this.shared.GetMobileView.subscribe((data) => {
      this.mobileView = data;
    });
    this.backBtnState = localStorage.getItem('state')
      ? localStorage.getItem('state')
      : this.backBtnState;
    console.log(this.backBtnState, ' bkbtnstate');

    this.getCountries();

    // this.activatedRoute.queryParams
    // .subscribe(params => {
    //   console.log(params);
    //   this.refer = params.ref;
    //   console.log(this.refer); // aureon
    // }
    // );
    this.refer = localStorage.getItem('ref');
    this.vendor = localStorage.getItem('vendor');
    // this.contentCode = localStorage.getItem('contentCode');
  }
  getLocaleJson() {
    console.log('get locale json');

    this._localeService.getJson().subscribe((data) => {
      console.log('plan controller ###############', data);
      this.locale = data;
    });
  }
  public getJSON(): Observable<any> {
    return this.http.get('./assets/countries.json');
  }
  closeClaim(nameObj) {
    localStorage.setItem('state', this.states[1]);
    console.log(nameObj['firstName'], 'name');
    // localStorage.setItem('firstName',nameObj['firstName']);
    // this.shared.setNameData(nameObj);
    this.authService.setNameInfo(nameObj);
    this.isClaim = false;
    this.isRegisterInfo = true;
    this.userInfo = this.authService.getUserData();
    if (this.userInfo) {
      this.firstName = this.userInfo.firstName;
    }
  }

  closeRegister(regInfo) {
    localStorage.setItem('state', this.states[2]);
    console.log(regInfo.username);
    this.registerLoading = true;
    // var encryUsername = this.authService.encrypt(data.username,'');
    // console.log(encryUsername,'en username');
    this.business.checkUsername(btoa(regInfo.username)).subscribe(
      (data) => {
        console.log(data);
        this.registerLoading = false;
        this.authService.setRegisterInfo(regInfo);
        this.isRegisterInfo = false;
        this.isAdditionalInfo = true;
        this.countryHint = this.countryData?.currentCountry
          ? this.countryData?.currentCountry
          : 'Choose your country';
      },
      (err) => {
        console.log(err.message);
        this.registerLoading = false;
        this.usernameError = true;
      }
    );
  }

  getCountries() {
    this.business.getCountries().subscribe(
      (data) => {
        this.countryData = data[0];
        this.countryList = this.countryData.countries;
        this.countryHint = this.countryData.currentCountry;
        console.log('this.countyrHint ', this.countryHint);
        // localStorage.setItem('currentCountry',this.countryHint);
        if (this.backBtnState != this.states[0]) {
          console.log('Go to ', this.backBtnState);
          this.changeState();
        }
        // console.log(this.countryList)
      },
      (err) => {
        this.countryData = this.countries;
        this.countryList = this.countryData.countries;
        this.countryHint = this.countryData.currentCountry;
        if (this.backBtnState != this.states[0]) {
          console.log('Go to ', this.backBtnState);
          this.changeState();
        }
      }
    );
  }

  redirectStemWerkz() {
    window.location.href = this.dashboardRedir;
  }

  createAccount(data) {
    if (!data.country) {
      data.country = this.countryHint;
    }
    this.moreInfo = data;
    this.signUp();
    // this.showVideo = true;
  }

  checkAndDeleteKey() {
    if (this.vendor) this.signupObj.vendor = this.vendor;
    else delete this.signupObj.vendor;

    if (this.refer) this.signupObj.referral = this.refer;
    else delete this.signupObj.referral;

    delete this.signupObj.contentcode;
  }
  public signupObj: any;

  signUp() {
    this.additionalLoading = true;
    this.signupObj = this.authService.getUserData();
    this.checkAndDeleteKey();
    // this.checkAndDeleteKey(this.contentCode,contentcode,signupObj);
    // signupObj.vendor = this.vendor ? this.vendor : "";
    // signupObj.referral = this.refer ? this.refer : "";
    //convert yearold to birthyear
    var birthyear = moment().year() - this.moreInfo.age;

    this.signupObj.yearOfBirth = birthyear.toString();
    this.signupObj.country = this.moreInfo.country;
    this.signupObj.gender = this.moreInfo.gender;
    this.signupObj.password = btoa(this.signupObj.password);
    this.signupObj.trialEnable = true;
    console.log('signup ', this.signupObj);
    this.business.signup(this.signupObj).subscribe(
      (data) => {
        console.log(data);
        this.additionalLoading = false;
        var header = {
          username: data[0].username,
          password: data[0].token,
          user: data[0]._id,
          apikey: data[0].apikey,
          playerid: data[0].idPlayer,
        };
        this.dashboardRedir = this.authService.getRedirectDashboardUrl(header);
        // this.showVideo = true;
        if (environment.isChina) {
          this.showVideo = false;
          this.redirectStemWerkz();
        } else this.showVideo = true;
        localStorage.clear();
      },
      (err) => {
        console.log(err.message);
        this.additionalLoading = false;
        this.feedbackTitle = err.message;
        this.feedbackBody = '';
        this.feedbackBtn = {
          status: false,
          text: '',
        };
        this.tickIcon = false;
        this.showFeedback = true;
        setTimeout(() => {
          this.showFeedback = false;
        }, 3000);
      }
    );
  }

  changeState() {
    console.log('changeState');
    this.userInfo = this.authService.getUserData();
    if (this.userInfo) {
      this.firstName = this.userInfo.firstName;
    }
    switch (this.backBtnState) {
      case this.states[0]:
        console.log('Normal');
        break;
      case this.states[1]:
        {
          this.isClaim = false;
          this.isAdditionalInfo = false;
          this.isRegisterInfo = true;
        }
        break;
      case this.states[2]:
        {
          this.isClaim = false;
          this.isRegisterInfo = false;
          this.isAdditionalInfo = true;
        }
        break;
    }
  }

  showState() {
    console.log('showState');
    this.userInfo = this.authService.getUserData();
    if (this.userInfo) {
      this.firstName = this.userInfo.firstName;
    }
    switch (this.backBtnState) {
      case this.states[0]:
        // this.router.navigate(['']);
        this.location.back();
        break;
      case this.states[1]:
        {
          this.isClaim = true;
          this.isAdditionalInfo = false;
          this.isRegisterInfo = false;
          this.backBtnState = this.states[0];
        }
        break;
      case this.states[2]:
        {
          this.isClaim = false;
          this.isRegisterInfo = true;
          this.isAdditionalInfo = false;
          this.backBtnState = this.states[1];
        }
        break;
      default:
        // this.router.navigate(['']);
        this.location.back();
    }
    localStorage.setItem('state', this.backBtnState);
  }

  clickedBack(event) {
    console.log('Clicked Back');
    // if (this.isClaim) this.router.navigate(['']);
    // var index = this.states.indexOf(this.backBtnState);
    // if(index != 0){
    //   index--;
    // }else{
    //   this.router.navigate(['']);
    // }
    // this.backBtnState = this.states[index];
    // else if (this.isRegisterInfo) this.backBtnState = this.states[0];
    // else if (this.isAdditionalInfo) this.backBtnState = this.states[1];
    // console.log(this.backBtnState);
    this.backBtnState =
      localStorage.getItem('state') != 'null'
        ? localStorage.getItem('state')
        : this.states[0];
    this.showState();
  }

  openModal(e) {
    this.popupFlag = true;
  }

  cancelBtnClicked($event) {
    this.popupFlag = false;
  }
  changeError(data) {
    console.log('change error');
    this.usernameError = data;
  }
}
