<div class="parent-wrap">
  <div class="parent-info">Parent's Information</div>
  <div class="mb-24">
    <div class="mb-12 normal-text">Relationship with child</div>
    <div class="d-flex justify-content-between">
      <div
        [ngClass]="{ active: infoOne.parent == 'father' }"
        class="choice-div"
        (click)="chooseParent('father')"
      >
        Father
      </div>
      <div
        [ngClass]="{ active: infoOne.parent == 'mother' }"
        class="choice-div"
        (click)="chooseParent('mother')"
      >
        Mother
      </div>
    </div>
  </div>
  <div class="mb-24 normal-text">Wechat ID</div>
  <div class="input-div" style="position: relative">
    <input
      [(ngModel)]="infoOne.wechatId"
      placeholder="Wechat ID"
      class="wechat-textbox"
    />
    <img class="wechat-logo" src="./assets/images/wechat_small.svg" />
  </div>
  <div class="d-flex align-items-center justify-content-center">
    <app-button
      (click)="continue()"
      [class]="'monthly-button'"
      [height]="'64px'"
      [width]="'300px'"
      ><span class="continue-text">Continue</span></app-button
    >
  </div>
</div>
