import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalComponentsModule } from '../global-components/global-components.module';
import { SharedComponentsModule } from './presentation/shared-components/shared-components.module';
import { BusinessLogicFacade } from './business-logic/business-logic.facade';
import { BusinessRequirementsInjectionToken } from './presentation/business-logic.requirements';
import { OnboardingModule } from './presentation/onboarding/onboarding.module';
import { OnboardingCnModule } from './presentation/onboarding-cn/onboarding-cn.module';
import { ParentInfoModule } from './presentation/parent-info/parent-info.module';
import { ChildInfoModule } from './presentation/child-info/child-info.module';
import { GlobalLoginModule } from './presentation/global-login/global-login.module';

@NgModule({
  imports: [
    BusinessLogicFacade,
    CommonModule,
    GlobalComponentsModule,
    SharedComponentsModule,
    OnboardingModule,
    OnboardingCnModule,
    ParentInfoModule,
    ChildInfoModule,
    GlobalLoginModule,
  ],
  providers: [
    {
      provide: BusinessRequirementsInjectionToken,
      useClass: BusinessLogicFacade,
    },
  ],
  exports: [],
})
export class HomeFeatureModule {}
