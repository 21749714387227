export const environment = {
  production: true,
  apiurl: 'https://sv-api.stemwerkz.org',
  questurl: 'https://quest-api.stemwerkz.org',
  valleyurl: 'https://sv.stemwerkz.org/',
  dashboardurl: 'https://student.stemwerkz.org/',
  dashboardApiurl: 'https://sv-api.stemwerkz.org/',
  distributorurl: 'https://distributor-api.stemwerkz.org',
  membershipUrl: 'https://account.stemwerkz.org/',
  apikey: 'bc77612ecd1a2e7f6ace383b4cee7848',
  secretkey: 'bc776',
  orgName: 'steam-werkz',
  initState: '',
  nodmaApi: 'https://app.pagewerkz.com', //for get countries list
  videoUrl: 'https://data-amdon-web.s3.amazonaws.com/web/accountSuccess.mp4',
  videoType: 'video/mp4',
  termsOfService: 'https://www.stemwerkz.org/end-user-agreement',
  privacyPolicy: 'https://www.stemwerkz.org/privacypolicy/',
  asia_termsOfService: 'https://asia.stemwerkz.org/end-user-agreement/',
  asia_privacyPolicy: 'https://asia.stemwerkz.org/privacypolicy/',
  china_termsOfService: 'https://www.stemwerkz.cn/end-user-agreement',
  china_privacyPolicy: 'https://www.stemwerkz.cn/privacypolicy/',
  isChina: false,
  startNormalUrl: 'https://www.stemwerkz.org',
  startAsiaUrl: 'https://asia.stemwerkz.org',
  startChinaUrl: 'https://www.stemwerkz.cn',
  locale: 'en-us',
  appleClientID: 'cn.stemwerkz.stemwerkz',
  appleRedirURL: 'apple/redirect/apple/redirect',
  loginMethods: {
    loginForm: true,
    loginWith: true,
  },
};
