import { Component, EventEmitter, Output } from '@angular/core';
import { BackStore } from './back.store';

@Component({
  selector: 'app-back',
  templateUrl: './back.component.html',
  styleUrls: ['./back.component.css'],
})
export class BackComponent {
  constructor(private store: BackStore) {}
  @Output() output = new EventEmitter<any>();
  public arrowUrl = '../../../../assets/images/arrow.svg';
  public arrowhoverUrl = '../../../../assets/images/arrow_red.svg';
  public url = this.arrowUrl;
  public dynamicClass = 'backwrapper';
  btnClicked(event) {
    this.output.emit(event);
  }

  changeUrl(data) {
    this.url = data;
    console.log(data);
    // if (data == "../../../../assets/images/arrow_red.svg")
    //   this.dynamicClass = 'backwrapper-hover';
    // else
    //   this.dynamicClass = 'backwrapper';
  }
}
