import {
  Component,
  OnInit,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-info-one',
  templateUrl: './info-one.component.html',
  styleUrls: ['./info-one.component.css'],
})
export class InfoOneComponent implements OnInit {
  @Input() locale: any;
  @Output() continueBtn = new EventEmitter<any>();
  constructor() {}
  public infoOne: any = {
    firstName: '',
    lastName: '',
  };
  public validName = false;
  ngOnInit(): void {}
  continue() {
    if (this.validName) this.continueBtn.emit(this.infoOne);
  }
  validateName() {
    if (this.infoOne.firstName && this.infoOne.lastName) {
      this.validName = true;
    } else {
      this.validName = false;
    }
  }
  @HostListener('document:keypress', ['$event'])
  onDown(e) {
    if (this.validName && e.keyCode == 13) {
      this.continue();
    }
  }
}
