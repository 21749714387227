import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class YearlySignupStore {

}

export enum YearlySignupStoreKeys {

}
