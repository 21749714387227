import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-city-list',
  templateUrl: './city-list.component.html',
  styleUrls: ['./city-list.component.css'],
})
export class CityListComponent implements OnInit {
  @Input() cities;
  @Input() locale;
  @Output() selectedCity = new EventEmitter<any>();
  constructor(private fb: FormBuilder) {}
  public isScrolled: boolean = false;
  // public form = this.fb.group({
  //   name: this.fb.control(''),
  //   search: this.fb.control(''),
  // });
  public currentCountry: any = {
    name: '',
    translatedName: '',
    countryCode: '',
    isoCode: '',
  };
  // public countryName: any;
  public searchName: any;
  public dropdownToggle: boolean = false;
  ngOnInit(): void {
    // this.form.get('name').valueChanges.subscribe((value) => {
    //   console.log('name has changed', value);
    // });
  }
  ngAfterViewChecked() {
    // try {
    //   this.scrollTo();
    // } catch (error) {
    //   console.log('not loaded yet..');
    // }
  }
  ngOnChanges() {
    try {
      console.log('whats in cities', this.cities);
      this.currentCountry = {
        name: '',
        translatedName: '',
        countryCode: '',
        isoCode: '',
      };
      // this.currentCountry = this.cities[0];
    } catch (error) {
      console.log('not loaded yet..');
    }
  }
  @HostListener('document:click', ['$event'])
  onClick(e) {
    var target = e.target;

    if (target.closest('.city-input')) {
      try {
        this.scrollTo();
      } catch (error) {
        console.log('closing dropdown');
      }
    } else if (!target.closest('.search-input')) {
      this.dropdownToggle = false;
    }
  }

  scrollTo() {
    console.log('scroll in cities', this.currentCountry);

    var translatedName = this.currentCountry.translatedName;
    var myElement = document.getElementById(translatedName);
    var topPos = myElement.offsetTop - 160;
    document.getElementById('scrolling_div').scrollTop = topPos;
  }
  selectCountry(data) {
    this.currentCountry = data;
    // this.countryName = data.translatedName;
    this.selectedCity.emit(data);
  }
  dropdownClicked() {
    this.dropdownToggle = !this.dropdownToggle;
  }
}
