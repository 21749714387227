import { Component, OnInit, Input } from "@angular/core";
import { ImageStore } from "./image.store";

@Component({
  selector: "app-image",
  templateUrl: "./image.component.html",
  styleUrls: ["./image.component.css"],
})
export class ImageComponent {
  @Input() src;
  @Input() alt="alt";
  @Input() width;
  @Input() height;
  @Input() class="default-class";
  @Input() maxWidth;
  @Input() borderRadius;
  @Input() objectFit;
  constructor(private store: ImageStore) {}
  ngOnInit() {
  }
}
