<img
  [src]="src"
  [alt]="alt"
  [ngStyle]="{
    width: width ? width : 'auto',
    height: height ? height : 'auto',
    'max-width': maxWidth ? maxWidth : '100%',
    'border-radius': borderRadius? borderRadius : '',
    'object-fit': objectFit ?  objectFit : ''
  }"
  [class]="class"
/>
