import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';
import { BundleModel } from '../models/bundle.model';

@Injectable()
export class SharedService {
  public mobileView;
  public bundleObject: BundleModel;
  public nameObject;
  public registerObject;
  public origin = null;

  public skill;
  public questId;
  public nextTo;

  setParamsForSkill(questId, skill) {
    console.log('set param');

    this.skill = skill;
    this.questId = questId;
  }

  getParamsForSkill() {
    var obj = { questId: this.questId, skill: this.skill };
    return obj;
  }

  setNextTo(data) {
    this.nextTo = data;
  }
  getNextTo() {
    return this.nextTo;
  }

  setOrigin(value) {
    this.origin = value;
  }

  getOrigin() {
    return this.origin;
  }
  setMobileView(value) {
    this.mobileView = value;
  }

  getMobileView() {
    return this.mobileView;
  }

  private SetMobileView = new BehaviorSubject({});
  GetMobileView = this.SetMobileView.asObservable();
  defineMobileView(data: any) {
    this.SetMobileView.next(data);
  }

  setNameData(data) {
    this.nameObject = data;
  }

  getNameData() {
    return this.nameObject;
  }

  setRegisterData(data) {
    this.registerObject = data;
  }

  getRegisterData() {
    return this.registerObject;
  }
}

enum VendorType {
  times = 'TIMES',
}
