import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ButtonStore {

}

export enum ButtonStoreKeys {

}
