<div class="login-wrap">
  <form
    style="width: 100%"
    (keyup.enter)="$event.preventDefault(); LoginClick()"
  >
    <div class="title d-flex justify-content-center">Log in</div>
    <div>
      <label>Username</label>
      <div class="username">
        <app-input
          style="width: 100%"
          [control]="form.get('username')"
          [textAlign]="'left'"
          [hint]="'Type your username'"
          [class]="usernameClass"
          [width]="'100%'"
          [height]="'48px'"
        ></app-input>
        <div style="margin-top: 12px">
          <app-text
            *ngIf="form.get('username').errors && btnFlag"
            [class]="'form-error-label-global'"
            [withoutImage]="false"
          >
            {{ locale?.login.errorname }}
          </app-text>
        </div>
      </div>
    </div>
    <div>
      <div class="d-flex justify-content-between">
        <label class="login-label">Password</label>
        <label *ngIf="showToggle" class="show" (click)="pwToggle()">hide</label>
        <label *ngIf="!showToggle" class="show" (click)="pwToggle()"
          >show</label
        >
      </div>
      <div class="password">
        <app-input
          style="width: 100%"
          [control]="form.get('password')"
          [type]="togglePassword"
          [textAlign]="'left'"
          [hint]="'Type your password'"
          [class]="pwClass"
          [width]="'100%'"
          [height]="'48px'"
        ></app-input>
        <div style="margin-top: 12px">
          <app-text
            *ngIf="form.get('password').errors && btnFlag"
            [class]="'form-error-label-global'"
            [withoutImage]="false"
          >
            {{ locale?.login.errorpassword }}
          </app-text>
        </div>
      </div>
    </div>
  </form>
  <div style="margin-bottom: 24px">
    <app-button
      style="width: 100%"
      [loading]="loading"
      [class]="'monthly-button'"
      [width]="'100%'"
      [height]="'48px'"
      (click)="LoginClick()"
      >Login</app-button
    >
  </div>
  <a
    target="_blank"
    [href]="forgetLink()"
    class="forgot cursor d-flex justify-content-center"
  >
    Forgot your password?
  </a>
</div>
