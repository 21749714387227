import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CountryCnComponent } from './country-cn/country-cn.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { CountryListComponent } from './country-list/country-list.component';
import { StateListComponent } from './state-list/state-list.component';
import { CityListComponent } from './city-list/city-list.component';
import { MonthListComponent } from './month-list/month-list.component';
import { YearListComponent } from './year-list/year-list.component';
import { SuccessfulShowComponent } from './successful-show/successful-show.component';

const components = [
  CountryCnComponent,
  CountryListComponent,
  StateListComponent,
  CityListComponent,
  MonthListComponent,
  YearListComponent,
  SuccessfulShowComponent,
];

@NgModule({
  declarations: components,
  exports: components,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    Ng2SearchPipeModule,
  ],
})
export class SharedComponentsModule {}
