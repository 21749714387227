<div class="logo-list-wrapper">
  <div class="logo-list" *ngFor="let data of logoList">
    <app-image
      [src]="data"
      [width]="'250px'"
      [height]="'85px'"
      [maxWidth]="'250px'"
    ></app-image>
  </div>
</div>
