import { Inject, Component } from '@angular/core';
import {
  BusinessLogicRequirements,
  BusinessRequirementsInjectionToken,
} from '../business-logic.requirements';
import { ChildInfoStore } from './child-info.store';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/lib/auth.service';
import { environment } from 'src/environments/environment';
import { LocaleService } from 'src/app/lib/locale.service';

@Component({
  selector: 'app-child-info',
  templateUrl: './child-info-controller.component.html',
  styleUrls: ['./child-info-controller.component.css'],
})
export class ChildInfoControllerComponent {
  constructor(
    @Inject(BusinessRequirementsInjectionToken)
    private business: BusinessLogicRequirements,
    private store: ChildInfoStore,
    private router: Router,
    private _localeService: LocaleService,
    private _authService: AuthService
  ) {}
  ngOnInit() {
    if (
      !localStorage.getItem('userInfo') &&
      !localStorage.getItem('dashboardRedir')
    ) {
      this.router.navigateByUrl('');
    }
    this.getCountryList();
    this.getLocaleJson();
  }
  public feedbackTitle = '';
  public feedbackBody = '';
  public feedbackBtn = {
    status: false,
    text: '',
  };
  public tickIcon = true;
  public showFeedback = false;
  public Bundle = true;
  public step: any = 'one';
  public countryList: any;
  public stateList: any;
  public cityList: any;
  public infoOne: any;
  public infoTwo: any;
  public infoThree: any;
  public codeLoading: boolean = false;
  public locale: any;

  continueClickedOne(e) {
    console.log('infoOne is', e);
    this.infoOne = e;
    this.step = 'two';
  }
  continueClickedTwo(e) {
    console.log('infoTwo is ', e);
    this.infoTwo = e;
    this.step = 'three';
    console.log('clicked here');
  }
  getLocaleJson() {
    this._localeService.getJson().subscribe((data) => {
      console.log('locale in home', data);
      this.locale = data;
    });
  }
  public childLoading: boolean = false;
  saveClicked(e) {
    this.childLoading = true;
    console.log('infoThree is ', e);
    this.infoThree = e;
    var userInfo = this._authService.decrypt(
      localStorage.getItem('userInfo'),
      environment.secretkey
    );
    userInfo = JSON.parse(userInfo);
    console.log('user Info', userInfo);

    var header = {
      apikey: userInfo.apikey || userInfo.ApiKey,
      username: userInfo.username,
      password: userInfo.password || userInfo.Token,
      childOrParent: 'child',
      userId: userInfo.childId,
      firstName: this.infoOne.firstName,
      lastName: this.infoOne.lastName,
      birthYear: this.infoTwo.birthYear,
      birthMonth: 'Jan',
      gender: this.infoTwo.gender,
      country: this.infoThree.country.name,
      state: this.infoThree.state.translatedName,
      city: this.infoThree.city.translatedName,
    };
    if (this.infoThree.city == 'skip') header.city = '';
    this.business.updateUserDetail(header).subscribe(
      (data) => {
        this.childLoading = false;
        console.log('data', data);
        if (localStorage.getItem('noSub') == 'true') {
          console.log('no sub flow');

          // checking bundle
          var header2 = {
            apikey: userInfo.apikey || userInfo.ApiKey,
            username: userInfo.username,
            password: userInfo.password || userInfo.Token,
            userId: userInfo.userid || userInfo.userId,
          };
          this.business.checkUserGrade(header2).subscribe(
            (res) => {
              console.log('res', res);
              // if bundle then go dashboard
              if (res.bundledGrades.length != 0) {
                this.step = 'success';
                setTimeout(() => {
                  window.location.href = localStorage.getItem('dashboardRedir');
                }, 3000);
              }
              // if no bundle then open claim box
              else {
                this.step = 'success';
                setTimeout(() => {
                  this.Bundle = false;
                }, 3000);
              }
            },
            // check bundle error
            (err) => {
              console.log(err);
              console.log(err.message);
              this.feedbackTitle = err.error.message || err.message;
              this.feedbackBody = '';
              this.feedbackBtn = {
                status: false,
                text: '',
              };
              this.tickIcon = false;
              this.showFeedback = true;
              setTimeout(() => {
                this.showFeedback = false;
              }, 3000);
            }
          );
        } else {
          this.step = 'success';
          setTimeout(() => {
            window.location.href = localStorage.getItem('dashboardRedir');
          }, 3000);
        }
      },
      (err) => {
        this.childLoading = false;
        console.log(err);
        console.log(err.message);
        this.feedbackTitle = err.error.message || err.message;
        this.feedbackBody = '';
        this.feedbackBtn = {
          status: false,
          text: '',
        };
        this.tickIcon = false;
        this.showFeedback = true;
        setTimeout(() => {
          this.showFeedback = false;
          // this.router.navigate(['']);
        }, 3000);
      }
    );
    // this.router.navigateByUrl('code/successful');
  }
  getCountryList() {
    this.business.getCountryList().subscribe(
      (data) => {
        console.log('country list', data);

        this.countryList = data;
      },
      (err) => {
        console.log(err);
        console.log(err.message);
        this.feedbackTitle = err.error.message || err.message;
        this.feedbackBody = '';
        this.feedbackBtn = {
          status: false,
          text: '',
        };
        this.tickIcon = false;
        this.showFeedback = true;
        setTimeout(() => {
          this.showFeedback = false;
        }, 3000);
      }
    );
  }
  getStateList(code: any) {
    this.business.getStateList(code).subscribe(
      (data) => {
        console.log('country list', data);

        this.stateList = data;
      },
      (err) => {
        console.log(err);
        console.log(err.message);
        this.feedbackTitle = err.error.message || err.message;
        this.feedbackBody = '';
        this.feedbackBtn = {
          status: false,
          text: '',
        };
        this.tickIcon = false;
        this.showFeedback = true;
        setTimeout(() => {
          this.showFeedback = false;
        }, 3000);
      }
    );
  }
  getCityList(cCode: any, sCode) {
    this.business.getCityList(cCode, sCode).subscribe(
      (data) => {
        console.log('country list', data);

        this.cityList = data;
      },
      (err) => {
        console.log(err);
        console.log(err.message);
        this.feedbackTitle = err.error.message || err.message;
        this.feedbackBody = '';
        this.feedbackBtn = {
          status: false,
          text: '',
        };
        this.tickIcon = false;
        this.showFeedback = true;
        setTimeout(() => {
          this.showFeedback = false;
        }, 3000);
      }
    );
  }
  countrySelected(e) {
    console.log('selected country', e);
    this.getStateList(e.isoCode);
  }
  stateSelected(e) {
    console.log('selected country', e);
    this.getCityList(e.countryCode, e.isoCode);
  }
  backButton() {
    switch (this.step) {
      case 'three':
        this.step = 'two';
        break;
      case 'two':
        this.step = 'one';
        break;
      case 'one':
        this.router.navigateByUrl('');
        break;

      default:
        this.router.navigateByUrl('');
        break;
    }
  }
  callClaimBundle(bundleCode) {
    this.codeLoading = true;
    var temp = this._authService.decrypt(
      localStorage.getItem('userInfo'),
      environment.secretkey
    );
    var userInfo = JSON.parse(temp);
    console.log('whats in JSON', userInfo);
    var encryCode = btoa(bundleCode);
    var header = {
      apikey: userInfo.apikey || userInfo.ApiKey,
      username: userInfo.username,
      password: userInfo.password || userInfo.Token,
      user: userInfo.childId,
      code: encryCode,
    };
    this.business.claimBundle(header).subscribe(
      (data) => {
        this.codeLoading = false;
        console.log(data);
        localStorage.setItem(
          'bundleInfo',
          this._authService.encrypt(JSON.stringify(data), null)
        );
        this.router.navigate(['/code/successful']);
        // this.dashboardRedir = this.authService.getRedirectDashboardUrl(header);
        // this.redirectStemWerkz();
      },
      (err) => {
        this.codeLoading = false;
        console.log(err.message);
        this.feedbackTitle = err.error.message || err.message;
        if (err.error.message == 'Invalid code provided.') {
          this.feedbackTitle = this.locale?.login.invalid;
        }
        this.feedbackBody = '';
        this.feedbackBtn = {
          status: false,
          text: '',
        };
        this.tickIcon = false;
        this.showFeedback = true;
        setTimeout(() => {
          this.showFeedback = false;
        }, 3000);
      }
    );
  }
}
