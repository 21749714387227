import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalComponentsModule } from '../../../global-components/global-components.module';
import { SharedComponentsModule } from '../shared-components/shared-components.module';
import { OnboardingCnControllerComponent } from './onboarding-cn-controller.component';
// import {FeatherModule} from 'angular-feather';
import { RouterModule } from '@angular/router';
import { ChoiceButtonComponent } from './components/choice-button/choice-button.component';
import { ContentCodeComponent } from './components/content-code/content-code.component';
// import { HowtoclaimComponent } from './components/howtoclaim/howtoclaim.component';
import { FailcodeComponent } from './components/failcode/failcode.component';
import { LoginStartComponent } from './components/login-start/login-start.component';
import { WechatRedirectComponent } from './components/wechat-redirect/wechat-redirect.component';
import { PhoneLoginComponent } from './components/phone-login/phone-login.component';
import { CodeInputModule } from 'angular-code-input';
import { AppleLoginService } from 'src/app/lib/apple-login.service';
import { AppleLoginComponent } from './components/apple-login/apple-login.component';

@NgModule({
  declarations: [
    OnboardingCnControllerComponent,
    ChoiceButtonComponent,
    ContentCodeComponent,
    // HowtoclaimComponent,
    FailcodeComponent,
    LoginStartComponent,
    WechatRedirectComponent,
    PhoneLoginComponent,
    AppleLoginComponent,
  ],
  exports: [OnboardingCnControllerComponent],
  imports: [
    CommonModule,
    RouterModule,
    GlobalComponentsModule,
    SharedComponentsModule,
    CodeInputModule,
  ],
  providers: [AppleLoginService],
})
export class OnboardingCnModule {}
