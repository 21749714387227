import { HttpClient } from '@angular/common/http';
import { Inject, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppleLoginService } from 'src/app/lib/apple-login.service';
import { AuthService } from 'src/app/lib/auth.service';
import { LocaleService } from 'src/app/lib/locale.service';
import {
  BusinessLogicRequirements,
  BusinessRequirementsInjectionToken,
} from '../../../business-logic.requirements';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-apple-login',
  templateUrl: './apple-login.component.html',
  styleUrls: ['./apple-login.component.css'],
})
export class AppleLoginComponent implements OnInit {
  constructor(
    @Inject(BusinessRequirementsInjectionToken)
    private business: BusinessLogicRequirements,
    private appleService: AppleLoginService,
    private http: HttpClient,
    private authService: AuthService,
    private router: Router,
    private _localeService: LocaleService
  ) {}

  getLocaleJson() {
    this._localeService.getJson().subscribe((data) => {
      console.log('locale in home', data);
      this.locale = data;
    });
  }

  public feedbackTitle = '';
  public feedbackBody = '';
  public feedbackBtn = {
    status: false,
    text: '',
  };
  public tickIcon = true;
  public showFeedback = false;
  public dummyApple: any;
  public Bundle: boolean = true;
  public codeLoading: boolean = false;
  public locale: any;
  public dashboardRedir = '';
  public verifyLoading: boolean = false;

  ngOnInit(): void {
    this.getLocaleJson();
    this.appleService.defineDetail(null);
  }
  ngAfterViewInit() {
    this.appleService.signIn();
    this.dummyApple = this.appleService.GetDetail.subscribe((data) => {
      console.log('apple sub test', data);
      if (data != null) {
        this.appleLogin(data);
      }
    });
  }
  ngOnChanges() {}
  appleLogin(data) {
    var body = {
      code: data.authorization.code,
      id_token: data.authorization.id_token,
    };
    this.business.appleLogin(body).subscribe(
      (data) => {
        console.log('res', data);
        this.feedbackTitle = this.locale?.login.loggedIn;
        this.feedbackBody = '';
        this.feedbackBtn = {
          status: false,
          text: '',
        };
        this.showFeedback = true;
        this.tickIcon = true;
        setTimeout(() => {
          this.showFeedback = false;
        }, 3000);

        this.verifyLoading = false;
        console.log('otp otp', data);
        if (data.error.code == 'subscriptionExpiryError') {
          localStorage.setItem('noSub', 'true');
        }
        let userInfo = this.authService.encrypt(JSON.stringify(data), null);
        localStorage.setItem('userInfo', userInfo);
        var header = {
          apikey: data.ApiKey,
          username: data.username,
          user: data.userId,
          password: data.Token,
          playerid: data.idPlayer ? data.idPlayer : data.idUser,
        };
        this.dashboardRedir = this.authService.getRedirectDashboardUrl(header);
        localStorage.setItem('dashboardRedir', this.dashboardRedir);
        console.log('head test', header);
        if (data.UserType == 3) {
          var header2 = {
            apikey: data.ApiKey,
            username: data.username,
            user: data.userId,
            password: data.Token,
            childId: data.childId,
            parentId: data.parentId,
          };
          this.business.checkUserInfo(header2).subscribe(
            (res) => {
              console.log('info detail', res);
              if (res.skipChildInfo == true && res.skipParentInfo == true) {
                this.business.checkUserGrade(header).subscribe(
                  (res) => {
                    console.log('res', res);
                    // if bundle then go dashboard
                    if (res.bundledGrades.length != 0) {
                      this.redirectStemWerkz();
                    }
                    // if no bundle then open claim box
                    else {
                      this.Bundle = false;
                    }
                  },
                  // check bundle error
                  (err) => {
                    console.log(err);
                    console.log(err.message);
                    this.feedbackTitle = err.error.message || err.message;
                    this.feedbackBody = '';
                    this.feedbackBtn = {
                      status: false,
                      text: '',
                    };
                    this.tickIcon = false;
                    this.showFeedback = true;
                    setTimeout(() => {
                      this.showFeedback = false;
                      // this.Bundle = false;
                      this.router.navigate(['']);
                    }, 3000);
                  }
                );
              }
              if (res.skipChildInfo == false && res.skipParentInfo == false) {
                localStorage.setItem('bothInfo', 'true');
                this.router.navigateByUrl('info/parent');
              }
              if (res.skipChildInfo == false && res.skipParentInfo == true) {
                this.router.navigateByUrl('info/child');
              }
              if (res.skipChildInfo == true && res.skipParentInfo == false) {
                this.router.navigateByUrl('info/parent');
              }
            },
            //check bundle api error
            (err) => {}
          );
        } else if (data.UserType == 5) {
          this.redirectStemWerkz();
        }
      },
      (err) => {
        this.verifyLoading = false;
        console.log(err);
        console.log(err.message);
        this.feedbackTitle = err.error.message;
        this.feedbackBody = '';
        this.feedbackBtn = {
          status: false,
          text: '',
        };
        this.tickIcon = false;
        this.showFeedback = true;
        setTimeout(() => {
          this.showFeedback = false;
        }, 3000);
        //call DOM CHANGES
      }
    );
  }
  callClaimBundle(bundleCode) {
    this.codeLoading = true;
    var encryCode = btoa(bundleCode);
    var userInfo = this.authService.decrypt(
      localStorage.getItem('userInfo'),
      environment.secretkey
    );
    userInfo = JSON.parse(userInfo);
    var header = {
      apikey: userInfo.ApiKey,
      username: userInfo.username,
      user: userInfo.userId,
      password: userInfo.Token,
      playerid: userInfo.idPlayer ? userInfo.idPlayer : userInfo.idUser,
      code: encryCode,
    };
    this.business.claimBundle(header).subscribe(
      (data) => {
        this.codeLoading = false;
        console.log(data);
        localStorage.setItem(
          'bundleInfo',
          this.authService.encrypt(JSON.stringify(data), null)
        );
        this.router.navigate(['/code/successful']);
      },
      (err) => {
        this.codeLoading = false;
        console.log(err.message);
        this.feedbackTitle = err.error.message || err.message;
        if (err.error.message == 'Invalid code provided.') {
          this.feedbackTitle = this.locale?.login.invalid;
        }
        this.feedbackBody = '';
        this.feedbackBtn = {
          status: false,
          text: '',
        };
        this.tickIcon = false;
        this.showFeedback = true;
        setTimeout(() => {
          this.showFeedback = false;
        }, 3000);
      }
    );
  }
  redirectStemWerkz() {
    window.location.href = this.dashboardRedir;
  }
}
