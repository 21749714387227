<div *ngIf="Bundle">
  <div [ngClass]="{ 'hidden-div': toggleVerify }" class="phone-wrap">
    <div class="ph-text">{{ locale?.mainPage.loginWithPhNo }}</div>
    <div class="enter-text">{{ locale?.mainPage.enterPhNo }}</div>
    <app-country-cn
      [locale]="locale"
      (pickedCountry)="selectedCountry($event)"
      (phoneNumber)="phoneNumberSelected($event)"
      (clickedEnter)="continueVerify()"
      [countries]="countries"
    ></app-country-cn>

    <div class="digit-code">{{ locale?.mainPage.a4DigitCode }}</div>
    <div style="width: 100%" class="d-flex justify-content-center">
      <app-button
        [disabled]="!phoneFilled"
        (click)="continueVerify()"
        [class]="
          sendOtpLoading
            ? 'monthly-button-blue-disable'
            : phoneFilled
            ? 'monthly-button-blue'
            : 'monthly-button-blue-disable'
        "
        ><span class="continue-text">{{
          locale?.mainPage.continue
        }}</span></app-button
      >
    </div>
  </div>
  <div [ngClass]="{ 'hidden-div': !toggleVerify }" class="veri-wrap">
    <div class="veri-text">{{ locale?.mainPage.verificationCode }}</div>
    <div class="enter-text">{{ locale?.mainPage.enterCode }}</div>
    <code-input
      [codeLength]="4"
      (codeChanged)="onCodeChanged($event)"
      (codeCompleted)="onCodeCompleted($event)"
    >
    </code-input>
    <div class="invalid-OTP" *ngIf="invalidOTP">
      <img
        style="margin-right: 12px"
        src="../../../../../../assets/images/red_cancel_s.svg"
      />{{ locale?.mainPage.failVerify }}
    </div>
    <div id="time" class="timer" [ngClass]="{ 'hidden-div': timerToggle }">
      1:00
    </div>
    <div class="resend" [ngClass]="{ 'hidden-div': !timerToggle }">
      {{ locale?.mainPage.receiveNot }}
      <span (click)="continueVerify()" class="resend-text">{{
        locale?.mainPage.resend
      }}</span>
    </div>
    <div style="width: 100%" class="d-flex justify-content-center">
      <app-button
        (click)="verifyBtn()"
        [class]="
          verifyLoading
            ? 'monthly-button-blue-disable'
            : codeFilled
            ? 'monthly-button-blue'
            : 'monthly-button-blue-disable'
        "
        ><span class="continue-text">{{
          locale?.mainPage.verify
        }}</span></app-button
      >
    </div>
  </div>
</div>
<div class="d-flex flex-column align-items-center pt-40" *ngIf="!Bundle">
  <app-content-code
    class="mt-5p"
    [loading]="codeLoading"
    [locale]="locale"
    (submitClicked)="callClaimBundle($event)"
  ></app-content-code>
</div>
<app-custom-feedback
  *ngIf="showFeedback"
  [feedbackTitle]="feedbackTitle"
  [feedbackBody]="feedbackBody"
  [feedbackBtn]="feedbackBtn"
  [tickIcon]="tickIcon"
></app-custom-feedback>
