import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-country-list',
  templateUrl: './country-list.component.html',
  styleUrls: ['./country-list.component.css'],
})
export class CountryListComponent implements OnInit {
  @Input() countries;
  @Input() locale;
  @Output() selectedCountry = new EventEmitter<any>();

  constructor(private fb: FormBuilder) {}
  public isScrolled: boolean = false;
  // public form = this.fb.group({
  //   name: this.fb.control(''),
  //   search: this.fb.control(''),
  // });
  public currentCountry: any;
  public countryName: any;
  public searchName: any;
  public dropdownToggle: boolean = false;
  ngOnInit(): void {
    // this.form.get('name').valueChanges.subscribe((value) => {
    //   console.log('name has changed', value);
    // });
  }
  ngAfterViewChecked() {
    // try {
    //   this.scrollTo();
    // } catch (error) {
    //   console.log('not loaded yet..');
    // }
  }
  @HostListener('document:click', ['$event'])
  onClick(e) {
    var target = e.target;

    if (target.closest('.country-origin')) {
      try {
        this.scrollTo();
      } catch (error) {
        console.log('closing dropdown');
      }
    } else if (!target.closest('.search-input')) {
      this.dropdownToggle = false;
    }
  }
  ngOnChanges() {
    try {
      var tempCountry = {
        isoCode: this.countries.isoCode,
        name: this.countries.name,
        phonecode: this.countries.phonecode,
      };
      this.currentCountry = tempCountry;
      this.selectedCountry.emit(this.currentCountry);
      // this.countryName = this.currentCountry.name;
    } catch (error) {
      console.log('not loaded yet..');
    }
  }

  scrollTo() {
    var isoCode = this.currentCountry.isoCode;
    var myElement = document.getElementById(isoCode);
    var topPos = myElement.offsetTop - 160;
    document.getElementById('scrolling_div').scrollTop = topPos;
  }
  selectCountry(data) {
    this.currentCountry = data;
    this.countryName = data.name;
    this.selectedCountry.emit(data);
  }
  dropdownClicked() {
    this.dropdownToggle = !this.dropdownToggle;
  }
}
