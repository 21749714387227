import { UserModel } from '../../../models/user.model';
import { ItemMapper } from '../../../lib/item-mapper';
import { environment } from '../../../../environments/environment';

export class PlayerMapper extends ItemMapper<UserModel> {
  protected mapFromItem(item): UserModel {
    console.log('got here');

    const userModel = new UserModel();
    userModel.password = item.Token;
    userModel.userid = item.userId;
    userModel.apikey = item.ApiKey ? item.ApiKey : environment.apikey;
    userModel.usertype = item.UserType;
    userModel.childId = item.childId;
    userModel.parentId = item.parentId;
    userModel.playerid = item.idPlayer ? item.idPlayer : item.idUser;
    userModel.idUser = item.idUser;
    userModel.orgname = item.Name;
    userModel.username = item.username;
    userModel.permittedApps = item.permittedApps;

    delete userModel.profileImg;
    delete userModel.text;
    delete userModel.locale;
    delete userModel.currentLocale;
    delete userModel.secondaryLocale;
    delete userModel.profileImgBorder;
    console.log('not here');
    return userModel;
  }
}
