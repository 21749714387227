import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalComponentsModule } from '../../../global-components/global-components.module';
import { SharedComponentsModule } from '../shared-components/shared-components.module';
import { ParentInfoControllerComponent } from './parent-info-controller.component';
import { RouterModule } from '@angular/router';
import { InfoOneComponent } from './components/info-one/info-one.component';
import { InfoTwoComponent } from './components/info-two/info-two.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContentCodeComponent } from './components/content-code/content-code.component';

@NgModule({
  declarations: [
    ParentInfoControllerComponent,
    InfoOneComponent,
    InfoTwoComponent,
    ContentCodeComponent,
  ],
  exports: [ParentInfoControllerComponent],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    GlobalComponentsModule,
    SharedComponentsModule,
  ],
})
export class ParentInfoModule {}
