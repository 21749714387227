<button
  [class]="loading ? loadingClass : class"
  (click)="onButtonClick($event)"
  (mousedown)="onButtonDown($event)"
  (mouseup)="onButtonUp($event)"
  (touchstart)="onButtonStart($event)"
  (touchend)="onButtonEnd($event)"
  (touchcanel)="onButtonCancel($event)"
  [disabled]="disabled"
  [ngStyle]="{
    width: width ? width : '',
    height: height ? height : ''
  }"
  [ngClass]="disabled ? 'disabled' : ''"
>
  <ng-content></ng-content>
</button>
