import {
  Component,
  OnInit,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-info-three',
  templateUrl: './info-three.component.html',
  styleUrls: ['./info-three.component.css'],
})
export class InfoThreeComponent implements OnInit {
  @Output() saveBtn = new EventEmitter<any>();
  @Output() countryOutput = new EventEmitter<any>();
  @Output() stateOutput = new EventEmitter<any>();
  @Input() locale: any;
  @Input() countries: any;
  @Input() states: any;
  @Input() cities: any;
  @Input() loading: any;
  public infoThree: any = {
    country: '',
    state: '',
    city: '',
  };
  public filledAllData = false;
  constructor() {}

  ngOnInit(): void {}
  ngOnChanges(): void {
    console.log('cities cities', this.cities);
    if (this.cities?.length == 0) {
      console.log('skip city');

      this.infoThree.city = 'skip';
      this.validateData();
    }
  }
  save() {
    if (this.filledAllData && !this.loading) this.saveBtn.emit(this.infoThree);
  }
  countrySelect(e) {
    this.infoThree.country = e;
    this.infoThree.state = '';
    this.infoThree.city = '';
    this.validateData();
    this.countryOutput.emit(e);
  }
  stateSelect(e) {
    this.infoThree.state = e;
    this.infoThree.city = '';
    this.validateData();
    this.stateOutput.emit(e);
  }
  citySelect(e) {
    this.infoThree.city = e;
    this.validateData();
  }

  validateData() {
    if (this.infoThree.country && this.infoThree.state && this.infoThree.city) {
      this.filledAllData = true;
    } else {
      this.filledAllData = false;
    }
  }
  @HostListener('document:keypress', ['$event'])
  onDown(e) {
    if (this.filledAllData && e.keyCode == 13) {
      this.save();
    }
  }
}
