<div>
  <div style="position: relative">
    <div class="country-icon" (click)="dropdownClicked()">
      <div style="margin-right: 16px">{{ currentCountry?.phonecode }}</div>
      <img src="./assets/images/downIcon.svg" />
    </div>
    <input
      (keyup.enter)="enterEvent($event)"
      type="number"
      [placeholder]="locale?.parentInfo.phNo"
      [(ngModel)]="phoneNo"
      (input)="changeMethod($event.target.value)"
      class="country-input"
      id="inputBoxNum"
    />
  </div>
  <div *ngIf="dropdownToggle" class="dropdown">
    <input
      [placeholder]="locale?.placeholder.searchCountry"
      [(ngModel)]="searchName"
      class="search-input"
    />
    <div id="scrolling_div" class="country-list">
      <div
        [ngClass]="
          currentCountry.isoCode == item.isoCode
            ? 'country-selected'
            : 'country-item'
        "
        (click)="selectCountry(item)"
        [id]="item.isoCode"
        class="country-item d-flex justify-content-between"
        *ngFor="let item of countries.countries | filter: searchName"
      >
        <span>{{ item.name }}</span>
        <span>{{ item.phonecode }}</span>
      </div>
    </div>
  </div>
</div>
