import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AdditionalInfoStore } from './additional-info.store';

@Component({
  selector: 'app-additional-info',
  templateUrl: './additional-info.component.html',
  styleUrls: ['./additional-info.component.css'],
})
export class AdditionalInfoComponent {
  constructor(
    private store: AdditionalInfoStore,
    private activatedRoute: ActivatedRoute
  ) {}
  @Input() countryList;
  @Input() countryHint;
  @Input() loading: boolean = false;
  @Input() locale;
  @Output() createAccount = new EventEmitter<any>();

  public additionalInfo = {
    gender: '',
    age: null,
    country: '',
  };
  public genderError = false;
  public ageError = false;
  public countryError = false;

  public btnText = '';

  ngOnInit() {
    // this.countryHint = localStorage.getItem('currentCountry');
    console.log('chint', this.countryHint);
  }
  ngOnChanges() {
    var url = this.activatedRoute.snapshot.url;
    console.log('url is ', url[0].path);
    if (url[0].path == 'trial')
      this.btnText = this.locale.trialSignup.startTrial;
    else this.btnText = this.locale.claimedSignup.createmyAcc;
  }
  createBtnClicked() {
    console.log(this.additionalInfo, 'add info');
    if (this.additionalInfo.gender && this.additionalInfo.age != null)
      this.createAccount.emit(this.additionalInfo);
    else {
      console.log('error else', this.additionalInfo);
      if (!this.additionalInfo.gender) this.genderError = true;
      if (this.additionalInfo.age == null) this.ageError = true;
      // if(!this.additionalInfo.country) this.countryError = true;
    }
  }

  chooseGender(gender) {
    this.additionalInfo.gender = gender;
    this.genderError = false;
  }

  chooseAge(age) {
    this.additionalInfo.age = age;
    this.ageError = false;
  }

  chooseCountry(country) {
    this.additionalInfo.country = country;
  }
}
