import {Component, Input} from '@angular/core';
import {GenderItemStore} from './gender-item.store'

@Component({
    selector: 'app-gender-item',
    templateUrl: './gender-item.component.html',
    styleUrls: ['./gender-item.component.css']
})
export class GenderItemComponent {
    constructor(private store: GenderItemStore) {}
    @Input() itemName;
    @Input() index;
    @Input() class;
    @Input() itemClass;

    ngOnInit(){
      console.log('index ',this.index);
    }
}
