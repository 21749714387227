import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class TrialStore {

}

export enum TrialStoreKeys {

}
