<div
  class="w-100"
  style="position: relative"
  (keyup.enter)="$event.preventDefault(); dosomething()"
>
  <app-input
    [hint]="hint"
    [control]="form.get('listItem')"
    [width]="'100%'"
    [readonly]="true"
    [class]="'signup-full cursor'"
    [doubleImage]="true"
    [image1]="image1"
    [image2]="image2"
    (blurEvent)="blurInput($event)"
    class="list-select-input"
    id="list-select-input"
  >
    <!-- <div class="arrow-down mr-2 cursor"></div> -->
    <!-- <div class="arrow">
      <app-image [src]="arrowUrl" [width]="'40px'" [height]="'48px'" [maxWidth]="'40px'"></app-image>

    </div> -->
  </app-input>
  <div
    *ngIf="showBox && itemList"
    class="list-dropdown w-100 bg-s0"
    [ngClass]="{ 'position-absolute': !pure }"
  >
    <app-input
      [hint]="''"
      [control]="form.get('searchItem')"
      [width]="'100%'"
      [readonly]="false"
      [ngClass]="{ country: !pure, numonly: pure }"
      [class]="'signup-full cursor clear-padding-left'"
      [type]="pure ? 'number' : 'text'"
      [numberOnly]="pure ? true : false"
      (blurEvent)="blurInput($event)"
    >
    </app-input>
    <div class="list-box w-100 bg-s0 mt-1" id="list-scroll">
      <div
        *ngFor="let item of tempList; let last = last; let first = first"
        class="item-list cursor p-3 focus-element"
        [ngClass]="{
          'last-row': last,
          'bg-b100 text-s0': checkSelected(item),
          'first-row': first
        }"
        (click)="chooseItem(item)"
      >
        <app-text>{{ pure ? item : item.name }}</app-text>
      </div>
    </div>
  </div>
</div>

<!-- 'item-list-hover': first && item.name !== form.get('listItem').value && !checkSelected(item) -->
