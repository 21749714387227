import { Inject, Component } from '@angular/core';
import {
  BusinessLogicRequirements,
  BusinessRequirementsInjectionToken,
} from '../business-logic.requirements';
import { SignupHomeStore } from './signup-home.store';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { BundleModel } from 'src/app/models/bundle.model';
import { SharedService } from 'src/app/lib/shared-service';
import { AuthService } from 'src/app/lib/auth.service';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocaleService } from 'src/app/lib/locale.service';

@Component({
  selector: 'app-signup-home',
  templateUrl: './signup-home-controller.component.html',
  styleUrls: ['./signup-home-controller.component.css'],
})
export class SignupHomeControllerComponent {
  constructor(
    @Inject(BusinessRequirementsInjectionToken)
    private business: BusinessLogicRequirements,
    private store: SignupHomeStore,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private shared: SharedService,
    private authService: AuthService,
    private http: HttpClient,
    private _localeService: LocaleService
  ) {
    this.getJSON().subscribe((data) => {
      console.log('countries json', data);
      this.countries = data;
    });
  }
  public countries: any;
  public isClaim = true;
  public isRegisterInfo = false;
  public isAdditionalInfo = false;
  public countryList;
  public countryHint = 'Choose your country';
  public videoUrl = environment.videoUrl;
  public videoType = environment.videoType;
  public showVideo = false;
  public logoList = [];
  public previewList = [];
  public bundleObject;
  public moreInfo: any;
  public upperPic: any;
  public lowerPic: any;
  public usernameError = false;
  public imgLoaded: boolean = false;
  public firstName = '';
  public subInfo = {
    type: '',
    infoText: null,
  };
  public grades;

  public feedbackTitle = '';
  public feedbackBody = '';
  public feedbackBtn = {
    status: false,
    text: '',
  };
  public tickIcon = true;
  public showFeedback = false;

  public mobileView;
  public mobileViewService;
  public refer;
  public vendor;
  public contentCode;
  public states = ['name', 'register', 'additional'];
  public backBtnState = this.states[0];
  public userInfo: any;
  public countryData: any;
  public popupFlag: boolean = false;
  public nameError = false;
  public registerLoading: boolean = false;
  public dashboardRedir: any = '';
  public additionalLoading: boolean = false;
  public locale: any;

  ngOnInit() {
    this.getLocaleJson();
    this.refer = localStorage.getItem('ref');
    this.vendor = localStorage.getItem('vendor');
    this.contentCode = localStorage.getItem('contentCode');

    console.log('ls', this.refer, this.vendor, this.contentCode);

    this.mobileViewService = this.shared.GetMobileView.subscribe((data) => {
      this.mobileView = data;
    });
    console.log(localStorage.getItem('state') != 'null');

    this.backBtnState =
      localStorage.getItem('state') != 'null'
        ? localStorage.getItem('state')
        : this.backBtnState;
    console.log(this.backBtnState, ' bkbtnstate');
    this.getCountries();
    var test = this.authService.getBundleInforInfo();
    console.log(test);
    this.subInfo.infoText = test.subscriptionMonth > 0 && test.subscriptionInDays > 0 ? test.subscriptionMonth : test.subscriptionMonth > 0 ? test.subscriptionMonth : test.subscriptionInDays > 0 ? test.subscriptionInDays : 0;
    this.subInfo.type = test.subscriptionMonth > 0 && test.subscriptionInDays > 0 ? 'month' : test.subscriptionMonth > 0 ? 'month' : test.subscriptionInDays > 0 ? 'day' : '';
    this.grades = test.grades;
    // check sub and bundle

    // 1. no bundle with sub
    if (this.subInfo.infoText && this.grades.length == 0) {
      console.log('1. no bundle with sub');
      this.upperPic =
        'https://cdn-1.pagewerkz.com/other/questDefaultBackground.jpg';
      this.lowerPic =
        'https://cdn-1.pagewerkz.com/other/questDefaultBackgroundBottom.png';
      this.logoList.push(
        'https://cdn-1.pagewerkz.com/other/questDefaultBackgroundTop.png'
      );
      // this.previewList.push(
      //   'https://cdn-1.pagewerkz.com/other/questDefaultBundle.png'
      // );
    } else if (this.subInfo.infoText && this.grades.length > 0) {
      this.upperPic = this.grades[0].levelBackground[0].url;
      this.lowerPic = this.grades[0].levelBackground[1].url;
      console.log('2. multi bundle with sub');
      this.parseBundle();
    } else if (!this.subInfo.infoText && this.grades.length > 0) {
      console.log('3. bundle with no sub');
      this.upperPic = this.grades[0].levelBackground[0].url;
      this.lowerPic = this.grades[0].levelBackground[1].url;
      this.parseBundle();
    } else {
      this.router.navigate(['']);
    }
  }
  getLocaleJson() {
    console.log('get locale json');

    this._localeService.getJson().subscribe((data) => {
      console.log('plan controller ###############', data);
      this.locale = data;
    });
  }
  parseBundle() {
    for (var i = 0; i < this.grades.length; i++) {
      console.log(this.grades[i]);
      this.logoList.push(this.grades[i].nameImageURL);
    }
    for (var j = 0; j < this.grades.length; j++) {
      console.log(this.grades[j]);
      this.previewList.push(this.grades[j].dashBoardMediumImageURL);
    }
    console.log(this.logoList, this.previewList);
  }

  closeClaim(nameObj) {
    console.log(nameObj['firstName'], 'name');
    localStorage.setItem('state', this.states[1]);
    // localStorage.setItem('firstName',nameObj['firstName']);
    // this.shared.setNameData(nameObj);
    this.authService.setNameInfo(nameObj);
    this.userInfo = this.authService.getUserData();
    if (this.userInfo) {
      this.firstName = this.userInfo.firstName;
    }
    this.isClaim = false;
    this.isRegisterInfo = true;
  }

  closeRegister(regInfo) {
    console.log(regInfo.username);
    this.registerLoading = true;

    // var encryUsername = this.authService.encrypt(data.username,'');
    // console.log(encryUsername,'en username');
    this.business.checkUsername(btoa(regInfo.username)).subscribe(
      (data) => {
        this.registerLoading = false;
        this.authService.setRegisterInfo(regInfo);
        console.log(regInfo);
        this.countryHint = this.countryData?.currentCountry
          ? this.countryData?.currentCountry
          : 'Choose your country';
        this.isRegisterInfo = false;
        this.isAdditionalInfo = true;
        console.log('check c hint ', this.countryData.currentCountry);
        localStorage.setItem('state', this.states[2]);
        // if (this.countryData){
        // console.log('county exists ');
        // }else{
        // this.countryHint = "Choose your country";
        // console.log('country does not exist ');
        // }
      },
      (err) => {
        console.log(err.message);
        this.registerLoading = false;
        this.usernameError = true;
        this.nameError = true;
      }
    );
  }
  public getJSON(): Observable<any> {
    return this.http.get('./assets/countries.json');
  }
  getCountries() {
    this.business.getCountries().subscribe(
      (data) => {
        this.countryData = data[0];
        this.countryList = this.countryData.countries;
        this.countryHint = this.countryData.currentCountry;
        console.log('this.countyrHint ', this.countryHint);
        // localStorage.setItem('currentCountry',this.countryHint);
        if (this.backBtnState != this.states[0]) {
          console.log('Go to ', this.backBtnState);
          this.changeState();
        }
        // console.log(this.countryList)
      },
      (err) => {
        console.log('failed');

        this.countryData = this.countries;
        this.countryList = this.countryData.countries;
        this.countryHint = this.countryData.currentCountry;
        console.log(
          'here failed',
          this.countryData,
          this.countryList,
          this.countryHint
        );

        if (this.backBtnState != this.states[0]) {
          console.log('Go to ', this.backBtnState);
          this.changeState();
        }
      }
    );
  }
  redirectStemWerkz() {
    window.location.href = this.dashboardRedir;
    // console.log(this.dashboardRedir);
  }

  createAccount(data) {
    if (!data.country) {
      data.country = this.countryHint;
    }
    this.moreInfo = data;
    this.signUp();
    // this.showVideo = true;
  }

  checkAndDeleteKey() {
    if (this.vendor) this.signupObj.vendor = this.vendor;
    else delete this.signupObj.vendor;

    if (this.refer) this.signupObj.referral = this.refer;
    else delete this.signupObj.referral;
    if (this.contentCode) this.signupObj.contentcode = atob(this.contentCode);
    else delete this.signupObj.contentcode;
  }
  public signupObj: any;

  signUp() {
    this.additionalLoading = true;
    this.signupObj = this.authService.getUserData();
    this.checkAndDeleteKey();

    // signupObj.vendor = this.vendor ? this.vendor : "";
    // signupObj.referral = this.refer ? this.refer : "";
    // signupObj.contentcode = this.contentCode ? this.contentCode : "";
    //convert yearold to birthyear
    var birthyear = moment().year() - this.moreInfo.age;

    this.signupObj.yearOfBirth = birthyear.toString();
    this.signupObj.country = this.moreInfo.country;
    this.signupObj.gender = this.moreInfo.gender;
    this.signupObj.password = btoa(this.signupObj.password);
    console.log('signup ', this.signupObj);
    this.business.signup(this.signupObj).subscribe(
      (data) => {
        console.log(data);
        this.additionalLoading = false;
        this.callClaimBundle(data[0]);
        // this.showVideo = true;
        localStorage.clear();
      },
      (err) => {
        console.log(err.message);
        this.additionalLoading = false;
        this.feedbackTitle = err.message;
        this.feedbackBody = '';
        this.feedbackBtn = {
          status: false,
          text: '',
        };
        this.tickIcon = false;
        this.showFeedback = true;
        setTimeout(() => {
          this.showFeedback = false;
        }, 3000);
      }
    );
  }

  imgLoad() {
    console.log('img is loaded');
    this.imgLoaded = true;
  }

  changeState() {
    this.userInfo = this.authService.getUserData();
    if (this.userInfo) {
      this.firstName = this.userInfo.firstName;
    }
    switch (this.backBtnState) {
      case this.states[0]:
        console.log('Normal');
        break;
      case this.states[1]:
        {
          this.isClaim = false;
          this.isAdditionalInfo = false;
          this.isRegisterInfo = true;
        }
        break;
      case this.states[2]:
        {
          this.countryHint = this.countryData?.currentCountry
            ? this.countryData?.currentCountry
            : 'Choose your country';
          this.isClaim = false;
          this.isRegisterInfo = false;
          this.isAdditionalInfo = true;
        }
        break;
    }
  }

  showState() {
    console.log('showState', this.backBtnState);
    this.userInfo = this.authService.getUserData();
    if (this.userInfo) {
      this.firstName = this.userInfo.firstName;
    }
    switch (this.backBtnState) {
      case this.states[0]:
        this.router.navigate(['']);
        break;
      case this.states[1]:
        {
          this.isClaim = true;
          this.isAdditionalInfo = false;
          this.isRegisterInfo = false;
          this.backBtnState = this.states[0];
        }
        break;
      case this.states[2]:
        {
          this.isClaim = false;
          this.isRegisterInfo = true;
          this.isAdditionalInfo = false;
          this.backBtnState = this.states[1];
        }
        break;
      default:
        this.router.navigate(['']);
    }
    localStorage.setItem('state', this.backBtnState);
  }

  clickedBack(event) {
    console.log('Clicked Back');
    // if (this.isClaim) this.router.navigate(['']);
    // var index = this.states.indexOf(this.backBtnState);
    // if(index != 0){
    //   index--;
    // }else{
    //   this.router.navigate(['']);
    // }
    // this.backBtnState = this.states[index];
    // else if (this.isRegisterInfo) this.backBtnState = this.states[0];
    // else if (this.isAdditionalInfo) this.backBtnState = this.states[1];
    // console.log(this.backBtnState);
    this.backBtnState =
      localStorage.getItem('state') != 'null'
        ? localStorage.getItem('state')
        : this.states[0];
    this.showState();
  }
  openPopup() {
    console.log('test test test');

    this.popupFlag = true;
  }

  cancelBtnClicked($event) {
    this.popupFlag = false;
  }

  callClaimBundle(data) {
    var header = {
      username: data.username,
      password: data.token,
      user: data._id,
      code: this.contentCode,
      apikey: data.apikey,
      playerid: data.idPlayer,
    };
    this.business.claimBundle(header).subscribe(
      (data) => {
        console.log(data);
        this.dashboardRedir = this.authService.getRedirectDashboardUrl(header);
        if (environment.isChina) {
          this.showVideo = false;
          this.redirectStemWerkz();
        } else this.showVideo = true;
      },
      (err) => {
        console.log(err, 'error show me show me');
        console.log(err.message);
        this.feedbackTitle = err.message;
        this.feedbackBody = '';
        this.feedbackBtn = {
          status: false,
          text: '',
        };
        this.tickIcon = false;
        this.showFeedback = true;
        setTimeout(() => {
          this.showFeedback = false;
        }, 3000);
      }
    );
  }

  openModal(e) {
    this.popupFlag = true;
  }

  changeError(data) {
    console.log('change error');
    this.usernameError = data;
  }

  changeBackground(index) {
    if (this.upperPic != this.grades[index].levelBackground[0].url) {
      this.upperPic = this.grades[index].levelBackground[0].url;
      this.lowerPic = this.grades[index].levelBackground[1].url;
    }
  }
}
