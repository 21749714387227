import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-country-cn',
  templateUrl: './country-cn.component.html',
  styleUrls: ['./country-cn.component.css'],
})
export class CountryCnComponent implements OnInit {
  @Input() countries;
  @Input() locale;
  @Output() phoneNumber = new EventEmitter<any>();
  @Output() pickedCountry = new EventEmitter<any>();
  @Output() clickedEnter = new EventEmitter<any>();

  constructor(private fb: FormBuilder) {}
  public isScrolled: boolean = false;
  // public form = this.fb.group({
  //   name: this.fb.control(''),
  //   search: this.fb.control(''),
  // });
  public currentCountry: any;
  public phoneNo: any;
  public searchName: any;
  public dropdownToggle: boolean = false;
  ngOnInit(): void {
    // this.form.get('name').valueChanges.subscribe((value) => {
    //   console.log('name has changed', value);
    // });
  }
  ngAfterViewChecked() {
    // try {
    //   this.scrollTo();
    // } catch (error) {
    //   console.log('not loaded yet..');
    // }
  }
  @HostListener('document:click', ['$event'])
  onClick(e) {
    var target = e.target;

    if (target.closest('.country-input') || target.closest('.country-icon')) {
      try {
        this.scrollTo();
      } catch (error) {
        console.log('closing dropdown');
      }
    } else if (
      !target.closest('.search-input') &&
      !target.closest('.country-icon')
    ) {
      this.dropdownToggle = false;
    }
  }

  ngAfterViewInit() {
    var navigationKeys = [
      'Backspace',
      'Delete',
      'Tab',
      'Escape',
      'Enter',
      'ArrowLeft',
      'ArrowRight',
    ];
    var inputBox = document.getElementById('inputBoxNum');

    // var invalidChars = ['-', '+', 'e'];

    inputBox.addEventListener('keydown', function (e) {
      if (
        // Allow: Delete, Backspace, Tab, Escape, Enter, etc
        navigationKeys.indexOf(e.key) > -1 ||
        (e.key === 'a' && e.ctrlKey === true) || // Allow: Ctrl+A
        (e.key === 'c' && e.ctrlKey === true) || // Allow: Ctrl+C
        (e.key === 'v' && e.ctrlKey === true) || // Allow: Ctrl+V
        (e.key === 'x' && e.ctrlKey === true) || // Allow: Ctrl+X
        (e.key === 'a' && e.metaKey === true) || // Cmd+A (Mac)
        (e.key === 'c' && e.metaKey === true) || // Cmd+C (Mac)
        (e.key === 'v' && e.metaKey === true) || // Cmd+V (Mac)
        (e.key === 'x' && e.metaKey === true) // Cmd+X (Mac)
      ) {
        console.log('commands');

        return; // let it happen, don't do anything
      }
      if (isNaN(Number(e.key))) {
        console.log('nan');

        e.preventDefault();
      }
    });
  }

  ngOnChanges() {
    console.log('countries in country-cn', this.countries);
    try {
      var tempCountry = {
        isoCode: this.countries.isoCode,
        name: this.countries.name,
        phonecode: this.countries.phonecode,
      };
      this.currentCountry = tempCountry;
      this.pickedCountry.emit(this.currentCountry);
    } catch (error) {
      console.log('not loaded yet..');
    }
  }

  scrollTo() {
    console.log('scroll func');
    var isoCode = this.currentCountry.isoCode;
    var myElement = document.getElementById(isoCode);
    var topPos = myElement.offsetTop - 160;
    document.getElementById('scrolling_div').scrollTop = topPos;
  }
  selectCountry(data) {
    this.currentCountry = data;
    this.pickedCountry.emit(data);
    this.dropdownToggle = false;
  }
  dropdownClicked() {
    console.log('toggle');

    this.dropdownToggle = !this.dropdownToggle;
  }
  changeMethod(e) {
    this.phoneNumber.emit(e);
  }
  enterEvent(e) {
    console.log('enter clicked');

    this.clickedEnter.emit(e);
  }
}
