import { DataRequirements } from '../business-logic/data.requirements';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserApiService } from './api-services/user-api.service';
@NgModule({
  imports: [HttpClientModule],
})
export class DataFacade implements DataRequirements {
  constructor(private userApi: UserApiService) {}
  getCountries(): Observable<any> {
    return this.userApi.getCountries();
  }
  signup(object): Observable<any> {
    return this.userApi.signup(object);
  }
  additionalInfo(object): Observable<any> {
    return this.userApi.additionalInfo(object);
  }
  checkUsername(name): Observable<any> {
    return this.userApi.checkUsername(name);
  }
  claimBundle(code): Observable<any> {
    return this.userApi.claimBundle(code);
  }
  discountCheck(code): Observable<any> {
    return this.userApi.discountCheck(code);
  }
  getCustomerInfo(code): Observable<any> {
    return this.userApi.getCustomerInfo(code);
  }
  getPublishKey(): Observable<any> {
    return this.userApi.getPublishKey();
  }
  getSubscriptionInfo(data): Observable<any> {
    return this.userApi.getSubscriptionInfo(data);
  }
  getPromoInfo(data): Observable<any> {
    return this.userApi.getPromoInfo(data);
  }
  getState(data): Observable<any> {
    return this.userApi.getState(data);
  }
}
