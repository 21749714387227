<div class="div-wrapper">
  <div *ngIf="!mobileView" class="container-div">
    <div class="title">
      How to claim your custom content from existing account.
      <img class="cursor" (click)="cancelClicked($event)" src="../../../assets/images/cancel_l.svg" />
    </div>
    <div style="margin-top: 32px;">
      <ul class="lists">
        <li class="mb-24">Log in to your Dashboard <img src="../../../assets/images/dashb.png" /></li>
        <li class="mb-24">Click this button<img src="../../../assets/images/step_1.png"/> on the top right side of the screen.</li>
        <li class="mb-24">Enter the 16-digit code</li>
      </ul>
      <img style="margin:0 0 32px 24px;" src="../../../assets/images/step_2.png"/>
    </div>
    <!-- <div class="gotoBtn cursor" (click)="gotoDashboard()">
      Go to Dashboard <img src="../../../assets/images/dashb.png" />
    </div> -->
    <app-button [class]="'red-primary-m'" (click)="gotoDashboard($event)" [width]="'100%'" [height]="'56px'">
      <app-text class="sub-btn">Go to Dashboard</app-text>
      <img src="../../../assets/images/dashb.png" />
    </app-button>
  </div>
  <div *ngIf="mobileView" class="container-div-mb">
    <div class="title-mb">
      How to claim your custom content from existing account.
      <img class="cursor" (click)="cancelClicked($event)" src="../../../assets/images/cancel_l.svg" />
    </div>
    <div style="margin-top: 24px;">
      <ul class="lists-mb">
        <li class="mb-16">Log in to your Dashboard <img src="../../../assets/images/dashb.png" /></li>
        <li class="mb-16">Click this button<img src="../../../assets/images/step_1.png"/> on the top right side of the screen.</li>
        <li class="mb-16">Enter the 16-digit code</li>
      </ul>
      <img style="margin:0 0 24px 24px;" src="../../../assets/images/step_2.png"/>
    </div>
    <!-- <div class="gotoBtn cursor" (click)="gotoDashboard()">
      Go to Dashboard <img src="../../../assets/images/dashb.png" />
    </div> -->
    <app-button [class]="'red-primary-m'" (click)="gotoDashboard($event)" [width]="'100%'" [height]="'56px'">
      <app-text class="sub-btn">Go to Dashboard</app-text>
      <img src="../../../assets/images/dashb.png" />
    </app-button>
  </div>
</div>
