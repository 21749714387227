import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
export interface DataRequirements {
  getCountries(): Observable<any>;
  signup(object): Observable<any>;
  additionalInfo(object): Observable<any>;
  checkUsername(name): Observable<any>;
  claimBundle(code): Observable<any>;
  discountCheck(code): Observable<any>;
  getCustomerInfo(code): Observable<any>;
  getPublishKey(): Observable<any>;
  getSubscriptionInfo(data): Observable<any>;
  getPromoInfo(data): Observable<any>;
  getState(data): Observable<any>;
}

export const DataRequirementsInjectionToken =
  new InjectionToken<DataRequirements>('signup Data Requirements');
