export class UserModel {
  username: string = 'Stemwerkz player';
  password: string = 'xxxxx';
  apikey: string = 'xxxxxx';
  userid: string = 'xxxxxx';
  profileImg: string = 'xxxx';
  text: string = 'xx';
  profileImgBorder: string = 'xxx';
  usertype: string = 'xxxxxx';
  playerid: string = 'xxxxxx';
  idUser: string = 'xxxxxx';
  orgname: string = 'xxxxxx';
  permittedApps: any = [];
  locale: string = 'xx-xx';
  currentLocale: string = 'xx-xx';
  secondaryLocale: string = 'xx-xx';
  childId: '';
  parentId: '';
}
