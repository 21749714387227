import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  Output,
  ElementRef,
} from '@angular/core';
import { SharedService } from 'src/app/lib/shared-service';
import { ChoiceButtonStore } from './choice-button.store';

@Component({
  selector: 'app-choice-button',
  templateUrl: './choice-button.component.html',
  styleUrls: ['./choice-button.component.css'],
})
export class ChoiceButtonComponent {
  @Input() locale: any;
  @Output() wechatBtn = new EventEmitter<any>();
  @Output() appleReq = new EventEmitter<any>();
  @Output() phBtn = new EventEmitter<any>();
  @Output() toggleLogin = new EventEmitter<any>();

  public mobileView;
  public mobileViewService;

  ngOnInit() {
    this.mobileViewService = this.shared.GetMobileView.subscribe((data) => {
      this.mobileView = data;
      console.log('mobo test', this.mobileView);
    });
  }
  ngOnChanges() {
    console.log('locale choice', this.locale);
  }
  ngAfterViewInit() {
    var s = document.createElement('script');
    s.type = 'text/javascript';
    s.text = ` var obj = new WxLogin({
      self_redirect: false,
      id: "login_container",
      appid: "wxbccf4a9b85a7aae9",

      scope: "snsapi_login",

      redirect_uri: encodeURIComponent(window.location.href + "/wechat/login"),

      state: Math.ceil(Math.random() * 1000),

      style: "black",
      href: "data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDIwMHB4O30KLmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lO30KLmltcG93ZXJCb3ggLmluZm8ge3dpZHRoOiAyMDBweDt9Ci5zdGF0dXNfaWNvbiB7ZGlzcGxheTogbm9uZX0KLmltcG93ZXJCb3ggLnN0YXR1cyB7dGV4dC1hbGlnbjogY2VudGVyO30gCiN3eF9kZWZhdWx0X3RpcCwgI3d4X2FmdGVyX3NjYW4sICN3eF9hZnRlcl9jYW5jZWwge2Rpc3BsYXk6IG5vbmUgIWltcG9ydGFudH0KLnFyY29kZSB7IHdpZHRoOiAyNTJweCAhaW1wb3J0YW50OyBoZWlnaHQ6IDI1MnB4ICFpbXBvcnRhbnQ7fQppZnJhbWUge3dpZHRoOiAyNTJweCAhaW1wb3J0YW50OyBoZWlnaHQ6IDI1MnB4ICFpbXBvcnRhbnQ7fQ==",
    });`;
    this.elementRef.nativeElement.appendChild(s);
  }

  wechatLogin($event) {
    this.wechatBtn.emit($event);
  }
  appleSendReq($event) {
    this.appleReq.emit($event);
  }
  phLogin($event) {
    this.phBtn.emit($event);
  }

  constructor(
    private store: ChoiceButtonStore,
    private shared: SharedService,
    private elementRef: ElementRef
  ) {}
  loginToggle() {
    this.toggleLogin.emit();
  }
}
