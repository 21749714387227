<div class="claim-wrapper">
  <app-text class="text-accent500 successful-text">{{
    locale?.codeSuccessful.codeSuccessful
  }}</app-text>
  <app-preview
    [previewList]="previewList"
    [locale]="locale"
    [subInfo]="subInfo"
    class="w-100"
    (changeBackground)="changeBackground($event)"
  ></app-preview>
</div>
