<ng-container *ngIf="class != 'middle'">
  <img
    [src]="logoUrl"
    [alt]="alt"
    [ngStyle]="{
      width: width ? width : 'auto',
      height: height ? height : 'auto',
      'max-width': maxWidth ? maxWidth : '100%',
      'border-radius': borderRadius ? borderRadius : '',
      'object-fit': objectFit ? objectFit : '',
      cursor: 'pointer'
    }"
    [class]="class"
    (click)="logoClicked()"
  />
</ng-container>

<div [ngClass]="'middle-img'" *ngIf="class == 'middle'">
  <img
    [src]="logoUrl"
    [alt]="alt"
    [ngStyle]="{
      width: width ? width : 'auto',
      height: height ? height : 'auto',
      'max-width': maxWidth ? maxWidth : '100%',
      'border-radius': borderRadius ? borderRadius : '',
      'object-fit': objectFit ? objectFit : '',
      cursor: 'pointer'
    }"
    [class]="class"
    (click)="logoClicked()"
  />
</div>
