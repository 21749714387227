import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OnboardingControllerComponent } from './home/presentation/onboarding/onboarding-controller.component';
import { ContinueSignupControllerComponent } from './signup/presentation/continue-signup/continue-signup-controller.component';
import { MonthlySignupControllerComponent } from './signup/presentation/monthly-signup/monthly-signup-controller.component';
import { AdditionalInfoComponent } from './signup/presentation/shared-components/additional-info/additional-info.component';
import { AgeComponent } from './signup/presentation/shared-components/age/age.component';
import { SignupHomeControllerComponent } from './signup/presentation/signup-home/signup-home-controller.component';
import { TrialControllerComponent } from './signup/presentation/trial/trial-controller.component';
import { YearlySignupControllerComponent } from './signup/presentation/yearly-signup/yearly-signup-controller.component';
import { environment } from 'src/environments/environment';
import { OnboardingCnControllerComponent } from './home/presentation/onboarding-cn/onboarding-cn-controller.component';
import { LoginStartComponent } from './home/presentation/onboarding-cn/components/login-start/login-start.component';
import { SignupHomeControllerCnComponent } from './signup/presentation/signup-home-cn/signup-home-controller-cn.component';
import { WechatRedirectComponent } from './home/presentation/onboarding-cn/components/wechat-redirect/wechat-redirect.component';
import { ParentInfoControllerComponent } from './home/presentation/parent-info/parent-info-controller.component';
import { ChildInfoControllerComponent } from './home/presentation/child-info/child-info-controller.component';
import { AppleLoginComponent } from './home/presentation/onboarding-cn/components/apple-login/apple-login.component';
// import { CleverLoginComponent } from './home/presentation/onboarding/components/clever-login/clever-login.component';
import { CleverLoginComponent } from './home/presentation/global-login/components/clever-login/clever-login.component';
import { GlobalLoginControllerComponent } from './home/presentation/global-login/global-login-controller.component';
import { ClaimPageComponent } from './home/presentation/global-login/components/claim-page/claim-page.component';
import { PromoSignupControllerComponent } from './signup/presentation/promo-signup/promo-signup-controller.component';

const routes: Routes = [
  {
    path: '',
    component:
      environment.locale == 'en-us'
        ? GlobalLoginControllerComponent
        : OnboardingCnControllerComponent,
  },
  {
    path: ':id',
    component:
      environment.locale == 'en-us'
        ? ClaimPageComponent
        : OnboardingCnControllerComponent,
  },
  {
    path: 'code/signup',
    component: SignupHomeControllerComponent,
  },
  {
    path: 'info/parent',
    component: ParentInfoControllerComponent,
  },
  {
    path: 'info/child',
    component: ChildInfoControllerComponent,
  },
  {
    path: 'trial/signup',
    component: TrialControllerComponent,
  },
  {
    path: 'custom/age',
    component: AgeComponent,
  },
  {
    path: 'monthly/signup',
    component: MonthlySignupControllerComponent,
  },
  {
    path: 'yearly/signup',
    component: YearlySignupControllerComponent,
  },
  {
    path: 'continue/signup',
    component: ContinueSignupControllerComponent,
  },
  {
    path: 'code/successful',
    component: SignupHomeControllerCnComponent,
  },
  {
    path: 'wechat/login',
    component: WechatRedirectComponent,
  },
  {
    path: 'apple/redirect',
    component: AppleLoginComponent,
  },
  {
    path: 'clever/redirect',
    component: CleverLoginComponent,
  },
  {
    path: 'global/legacy',
    component: OnboardingControllerComponent,
  },
  {
    path: 'claim/code',
    component: ClaimPageComponent,
  },
  {
    path: 'special/:code',
    component: PromoSignupControllerComponent,
  },

  // { path: '', pathMatch: 'full', redirectTo: 'redirect'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
