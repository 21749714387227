import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GlobalComponentsModule} from '../../../global-components/global-components.module';
import {SharedComponentsModule} from '../shared-components/shared-components.module';
import {OnboardingControllerComponent} from './onboarding-controller.component';
// import {FeatherModule} from 'angular-feather';
import {RouterModule} from '@angular/router';
import { ChoiceButtonComponent } from './components/choice-button/choice-button.component';
import { ContentCodeComponent } from './components/content-code/content-code.component';
// import { HowtoclaimComponent } from './components/howtoclaim/howtoclaim.component';
import { FailcodeComponent } from './components/failcode/failcode.component';
import { CleverLoginComponent } from './components/clever-login/clever-login.component';

@NgModule({
    declarations: [
        OnboardingControllerComponent,
        ChoiceButtonComponent,
        ContentCodeComponent,
        // HowtoclaimComponent,
        FailcodeComponent,
        CleverLoginComponent
    ],
    exports: [
        OnboardingControllerComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        GlobalComponentsModule,
        SharedComponentsModule,
    ]
})
export class OnboardingModule {}
