import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
export interface BusinessLogicRequirements {
  getCountries(): Observable<any>;
  signup(object): Observable<any>;
  additionalInfo(object): Observable<any>;
  checkUsername(name): Observable<any>;
  claimBundle(code): Observable<any>;
  discountCheck(code): Observable<any>;
  getCustomerInfo(code): Observable<any>;
  getSubscriptionInfo(data): Observable<any>;
  getPromoInfo(data): Observable<any>;
  getState(data): Observable<any>;
  getPublishKey(): Observable<any>;
}

export const BusinessRequirementsInjectionToken =
  new InjectionToken<BusinessLogicRequirements>('signup Business Requirements');
