import {
  Component,
  OnInit,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-info-one',
  templateUrl: './info-one.component.html',
  styleUrls: ['./info-one.component.css'],
})
export class InfoOneComponent implements OnInit {
  @Output() continueBtn = new EventEmitter<any>();
  constructor() {}
  public infoOne = {
    parent: '',
    wechatId: '',
  };
  ngOnInit(): void {}
  continue() {
    this.continueBtn.emit(this.infoOne);
  }
  chooseParent(data) {
    this.infoOne.parent = data;
  }
}
