import { Inject, Component } from '@angular/core';
import {
  BusinessLogicRequirements,
  BusinessRequirementsInjectionToken,
} from '../business-logic.requirements';
import { OnboardingStore } from './onboarding.store';
import { Router, ActivatedRoute } from '@angular/router';
import { SharedService } from 'src/app/lib/shared-service';
import { AuthService } from 'src/app/lib/auth.service';
import { environment } from 'src/environments/environment';
import { param } from 'jquery';
import { LocaleService } from 'src/app/lib/locale.service';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding-controller.component.html',
  styleUrls: ['./onboarding-controller.component.css'],
})
export class OnboardingControllerComponent {
  constructor(
    @Inject(BusinessRequirementsInjectionToken)
    private business: BusinessLogicRequirements,
    private store: OnboardingStore,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private shared: SharedService,
    private authService: AuthService,
    private _localeService: LocaleService
  ) {
    this.code = this.activatedRoute.snapshot.paramMap.get('id');
  }
  getLocaleJson() {
    this._localeService.getJson().subscribe((data) => {
      console.log('locale in home', data);
      this.locale = data;
    });
  }

  public logoUrl = '../../../../assets/images/STEMWerkzLogo.svg';
  public plusUrl = '../../../../assets/images/plus.svg';
  public polygonUrl = '../../../../assets/images/polygon.svg';
  public arrowUrl = '../../../../assets/images/arrow.svg';
  public popupFlag: boolean = false;
  public locale: any;
  public isBoarding = true;
  public code;
  public refer;
  public contentCode;
  public apiLoading = false;
  public codeError: any = '';
  public codeFlag: boolean = false;

  public mobileView;
  public mobileViewService;
  // public tos = environment.termsOfService;
  // public pp = environment.privacyPolicy;
  public tos;
  public pp;
  public codeLoading: boolean = false;
  public origin;

  public questId;
  public skill;

  ngOnInit() {
    this.getLocaleJson();
    setTimeout(() => {
      this.origin = this.shared.getOrigin();
      this.goOrigin();
    }, 1000);
    this.activatedRoute.queryParams.subscribe((params) => {
      console.log(params);
      this.questId = params.questID;
      this.skill = params.skill;
      if (this.questId && this.skill) {
        this.isBoarding = false;
        this.shared.setParamsForSkill(this.questId, this.skill);
      }
    });
    localStorage.removeItem('bundleInfo');
    localStorage.removeItem('userInfo');
    localStorage.removeItem('state');
    localStorage.removeItem('contentCode');
    localStorage.removeItem('sessionId');
    localStorage.removeItem('dashboardRedir');
    if (this.code) {
      this.apiLoading = true;
      this.claimCodeClick(this.code);
    }
    this.mobileViewService = this.shared.GetMobileView.subscribe((data) => {
      this.mobileView = data;
    });
  }

  ngAfterViewInit() {}

  goOrigin() {
    console.log(this.origin);
    switch (this.origin) {
      case 'us':
        this.tos = environment.termsOfService;
        this.pp = environment.privacyPolicy;
        console.log('us');
        break;
      case 'asia':
        this.tos = environment.asia_termsOfService;
        this.pp = environment.asia_privacyPolicy;
        console.log('asia');
        break;
      default:
        this.checkChina();
        break;
    }
  }

  checkChina() {
    if (environment.isChina) {
      this.tos = environment.china_termsOfService;
      this.pp = environment.china_privacyPolicy;
      console.log('china');
    } else {
      this.tos = environment.termsOfService;
      this.pp = environment.privacyPolicy;
      console.log('none');
    }
  }

  trialClicked(e) {
    // if (this.refer) this.router.navigate(['/trial/signup']);
    // else
    this.router.navigate(['/trial/signup']);
  }

  claimClick(e) {
    console.log('you want to claim?');
    this.isBoarding = false;
  }

  cleverClick($event) {
    console.log('clever Click');
    var uri = window.origin + '/clever/redirect';
    var client_id = 'f8d9c88c42cb1de653c3';
    var url =
      'https://clever.com/oauth/authorize?response_type=code&redirect_uri=' +
      uri +
      '&client_id=' +
      client_id;
    window.location.replace(url);
  }

  openPopup() {
    this.popupFlag = true;
  }

  cancelBtnClicked($event) {
    this.popupFlag = false;
  }

  claimCodeClick(bundleCode) {
    this.codeLoading = true;
    this.code = bundleCode;
    var encryCode = btoa(bundleCode);
    this.business.checkBundle(encryCode).subscribe(
      (data) => {
        this.codeLoading = false;
        // this.shared.setBundleData(data[0]);
        // this.authService.setBundleInfo(data[0]);
        localStorage.setItem(
          'bundleInfo',
          this.authService.encrypt(JSON.stringify(data), null)
        );
        this.apiLoading = false;
        console.log(this.refer && this.contentCode);
        localStorage.setItem('contentCode', btoa(this.code));
        this.router.navigate(['/code/signup']);
      },
      (err) => {
        this.codeLoading = false;
        console.log(err);
        this.codeError = err.status;
        this.codeFlag = true;
      }
    );
  }
}
