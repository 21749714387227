import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-month-list',
  templateUrl: './month-list.component.html',
  styleUrls: ['./month-list.component.css'],
})
export class MonthListComponent implements OnInit {
  public months: any = [
    { name: '1月' },
    { name: '2月' },
    { name: '3月' },
    { name: '4月' },
    { name: '5月' },
    { name: '6月' },
    { name: '7月' },
    { name: '8月' },
    { name: '9月' },
    { name: '10月' },
    { name: '11月' },
    { name: '12月' },
  ];
  @Input() locale;
  @Output() pickedMonth = new EventEmitter<any>();

  constructor(private fb: FormBuilder) {}
  public isScrolled: boolean = false;
  // public form = this.fb.group({
  //   name: this.fb.control(''),
  //   search: this.fb.control(''),
  // });
  public currentMonth: any = 'Month';

  public searchName: any;
  public dropdownToggle: boolean = false;
  ngOnInit(): void {
    this.currentMonth = this.locale?.childInfo.month;
  }
  ngAfterViewChecked() {}
  @HostListener('document:click', ['$event'])
  onClick(e) {
    var target = e.target;

    if (target.closest('.country-input')) {
      try {
        // this.scrollTo();
      } catch (error) {
        console.log('closing dropdown');
      }
    } else if (
      !target.closest('.month-div') &&
      !target.closest('.search-input')
    ) {
      this.dropdownToggle = false;
    }
  }

  ngOnChanges() {}

  scrollTo() {
    console.log('scroll func');

    var myElement = document.getElementById('MM');
    var topPos = myElement.offsetTop - 160;
    document.getElementById('scrolling_div').scrollTop = topPos;
  }
  selectCountry(data) {
    this.currentMonth = data.name;
    this.pickedMonth.emit(this.currentMonth);
    this.dropdownToggle = false;
  }
  dropdownClicked() {
    console.log('dropdown func');

    this.dropdownToggle = !this.dropdownToggle;
  }
}
