import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ButtonStore } from './button.store';
import * as $ from 'jquery';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.css'],
})
export class ButtonComponent {
  @Input() disabled = false;
  @Input() class = '';
  @Input() width;
  @Input() height;
  @Input() loading = false;
  @Output() btnClick = new EventEmitter<any>();
  @Output() btnDown = new EventEmitter<any>();
  @Output() btnUp = new EventEmitter<any>();
  @Output() btnStart = new EventEmitter<any>();
  @Output() btnEnd = new EventEmitter<any>();
  @Output() btnCancel = new EventEmitter<any>();

  public loadingClass = 'btn-disable ' + this.class;
  private getVal;
  private tmpArr;

  onButtonDown($event) {
    this.btnDown.emit($event);
  }

  onButtonUp($event) {
    this.btnUp.emit($event);
  }

  onButtonStart($event) {
    this.btnStart.emit($event);
  }

  onButtonEnd($event) {
    this.btnEnd.emit($event);
  }

  onButtonCancel($event) {
    this.btnCancel.emit($event);
  }

  onButtonClick($event) {
    this.tmpArr = this.class.split(' ');
    if (this.tmpArr[0] == 'btn-gender') {
      this.getVal = this.tmpArr[1];
      console.log(this.getVal);
      $('.btn-gender').removeClass('btn-select');
      $('.' + this.getVal).addClass('btn-select');
      this.btnClick.emit(this.getVal);
    } else this.btnClick.emit($event);
  }
  constructor(private store: ButtonStore) {}
}
