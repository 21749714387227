<div class="wrapper">
  <app-gender
    [locale]="locale"
    (genderOutput)="chooseGender($event)"
    [error]="genderError"
  ></app-gender>
  <app-age
    [locale]="locale"
    (ageOutput)="chooseAge($event)"
    [error]="ageError"
  ></app-age>
  <app-country
    [hint]="countryHint"
    [defaultValue]="countryHint"
    [itemList]="countryList"
    (countryOutput)="chooseCountry($event)"
    [error]="countryError"
  ></app-country>
  <div
    class="d-flex justify-content-center"
    style="margin-top: 32px; margin-bottom: 4px; white-space: nowrap"
    (click)="createBtnClicked()"
  >
    <app-button
      [class]="!loading ? 'red-primary-l' : 'btn-disable red-primary-l'"
      >{{ btnText }}</app-button
    >
  </div>
</div>

<script>
  $(".selectpicker").selectpicker();
</script>
