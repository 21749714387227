import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  Output,
} from '@angular/core';
import { SharedService } from 'src/app/lib/shared-service';
import { ChoiceButtonStore } from './choice-button.store';

@Component({
  selector: 'app-choice-button',
  templateUrl: './choice-button.component.html',
  styleUrls: ['./choice-button.component.css'],
})
export class ChoiceButtonComponent {
  @Input() locale: any;
  @Output() clever = new EventEmitter<any>();
  @Output() trialBtn = new EventEmitter<any>();
  @Output() claimBtn = new EventEmitter<any>();
  public mobileView;
  public mobileViewService;

  ngOnInit() {
    this.mobileViewService = this.shared.GetMobileView.subscribe((data) => {
      this.mobileView = data;
      console.log('mobo test', this.mobileView);
    });
  }
  ngOnChanges() {
    console.log('locale choice', this.locale);
  }

  trialClick($event) {
    this.trialBtn.emit($event);
  }

  claimClick($event) {
    this.claimBtn.emit($event);
  }
  cleverLogin($event) {
    this.clever.emit($event);
  }

  constructor(
    private store: ChoiceButtonStore,
    private shared: SharedService
  ) {}
}
