<div class="login-wrapper">
  <div *ngIf="!mobileView" class="stemwallpaper">
    <app-logo [class]="'middle'"></app-logo>
  </div>
  <div
    class="form-wrapper"
    [ngStyle]="{ width: mobileView ? '100%' : '560px' }"
  >
    <div class="kids">{{ locale?.login.hello }}</div>
    <form
      style="width: 100%"
      (keyup.enter)="$event.preventDefault(); LoginClick()"
    >
      <label class="login-label">{{ locale?.login.username }}</label>
      <div class="username">
        <app-input
          [control]="form.get('username')"
          [textAlign]="'left'"
          [hint]="locale?.login.typename"
          [class]="usernameClass"
          [width]="'492px'"
          [height]="'48px'"
        ></app-input>
        <div style="margin-top: 12px">
          <app-text
            *ngIf="form.get('username').errors && btnFlag"
            [class]="'form-error-label'"
            [withoutImage]="false"
          >
            {{ locale?.login.errorname }}
          </app-text>
        </div>
      </div>
      <div class="d-flex justify-content-between">
        <label class="login-label">{{ locale?.login.password }}</label>
        <label *ngIf="showToggle" class="show" (click)="pwToggle()">{{
          locale?.login.show
        }}</label>
        <label *ngIf="!showToggle" class="show" (click)="pwToggle()">{{
          locale?.login.hide
        }}</label>
      </div>
      <div class="password">
        <app-input
          [control]="form.get('password')"
          [textAlign]="'left'"
          [type]="togglePassword"
          [hint]="locale?.login.typepassword"
          [class]="pwClass"
          [width]="'492px'"
          [height]="'48px'"
        ></app-input>
        <div style="margin-top: 12px">
          <app-text
            *ngIf="form.get('password').errors && btnFlag"
            [class]="'form-error-label'"
            [withoutImage]="false"
          >
            {{ locale?.login.errorpassword }}
          </app-text>
        </div>
      </div>
    </form>
    <div style="margin-bottom: 24px">
      <app-button
        style="width: 100%"
        [loading]="loading"
        [class]="'monthly-button'"
        [width]="'100%'"
        [height]="'48px'"
        (click)="LoginClick()"
        >{{ locale?.login.login }}</app-button
      >
    </div>
    <div style="margin-bottom: 24px">
      <app-button
        style="width: 100%"
        (click)="cancelClick()"
        [class]="'cancel-button'"
        [height]="'48px'"
        [width]="'100%'"
        >{{ locale?.login.cancel }}</app-button
      >
    </div>
    <a [href]="forgetLink()">
      <div class="forget d-flex justify-content-center">
        {{ locale?.login.forget }}
      </div>
    </a>
  </div>
</div>
