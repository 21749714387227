<app-howtoclaim
  class="coverall"
  (cancelBtn)="cancelBtnClicked($event)"
  *ngIf="popupFlag"
></app-howtoclaim>
<div class="wrapper">
  <app-moving-bg></app-moving-bg>
  <div class="onboard-wrap">
    <div class="foreground" *ngIf="!isBoarding">
      <div class="backwrapper back cursor" (click)="backHome()">
        <app-image [src]="arrowUrl"></app-image>
      </div>
    </div>
    <div
      *ngIf="!isLogin && !isPhoneLogin"
      class="d-flex flex-column align-items-center pt-40"
    >
      <!-- <app-image [ngStyle]="{ 'width' : mobileView ? '175px' : '' }" [src]="logoUrl"></app-image> -->

      <app-logo [class]="'middle'"></app-logo>
      <ng-container *ngIf="!apiLoading && !codeFlag">
        <app-choice-button
          *ngIf="isBoarding"
          style="max-width: 680px"
          (wechatBtn)="wechatClicked($event)"
          (appleReq)="appleClicked($event)"
          (phBtn)="phoneClicked($event)"
          (toggleLogin)="loginToggle($event)"
          [locale]="locale"
        ></app-choice-button>
        <!-- <app-content-code
          *ngIf="!isBoarding"
          class="mt-5p"
          [loading]="codeLoading"
          [locale]="locale"
          (submitClicked)="claimCodeClick($event)"
        ></app-content-code> -->
      </ng-container>
      <ng-container *ngIf="apiLoading || codeFlag">
        <app-failcode
          (loginBtn)="openPopup()"
          [locale]="locale"
          [loading]="codeLoading"
          (trialBtn)="trialClicked($event)"
          [codeNo]="code"
          [errorMsg]="codeError"
          (submitClicked)="claimCodeClick($event)"
        ></app-failcode>
      </ng-container>
    </div>
    <!-- <div class="terms" [ngClass]="!mobileView ? 'pos-ab' : ''"> -->
    <div *ngIf="!isLogin && !isPhoneLogin" class="terms">
      <span *ngFor="let terms of locale?.mainPage.policy">
        {{ terms == "tos" || terms == "pripolicy" ? "" : terms }}
        <a
          style="color: #3394ab"
          [href]="tos"
          target="_blank"
          *ngIf="terms == 'tos'"
          >{{ locale.mainPage[terms] }}</a
        >
        <a
          style="color: #3394ab"
          [href]="pp"
          target="_blank"
          *ngIf="terms == 'pripolicy'"
          >{{ locale.mainPage[terms] }}</a
        >
      </span>
    </div>
    <div
      *ngIf="isPhoneLogin"
      class="d-flex flex-column align-items-center pt-40"
    >
      <div class="foreground" style="left: 10px; top: 0px">
        <div class="backwrapper back cursor" (click)="isPhoneLogin = false">
          <app-image [src]="arrowUrl"></app-image>
        </div>
      </div>
      <app-logo [class]="'middle'"></app-logo>
      <app-phone-login
        [countries]="countryList"
        [locale]="locale"
      ></app-phone-login>
    </div>
    <app-login-start
      [locale]="locale"
      [loading]="logInLoading"
      (cancelBtn)="loginToggle()"
      (loginEvent)="loginClicked($event)"
      *ngIf="isLogin && Bundle"
    ></app-login-start>
    <div
      class="d-flex flex-column align-items-center pt-40"
      *ngIf="isLogin && !Bundle"
    >
      <app-logo [class]="'middle'"></app-logo>
      <app-content-code
        class="mt-5p"
        [loading]="codeLoading"
        [locale]="locale"
        (submitClicked)="callClaimBundle($event)"
      ></app-content-code>
    </div>
  </div>
</div>
<app-custom-feedback
  *ngIf="showFeedback"
  [feedbackTitle]="feedbackTitle"
  [feedbackBody]="feedbackBody"
  [feedbackBtn]="feedbackBtn"
  [tickIcon]="tickIcon"
></app-custom-feedback>

<app-query-params-ls></app-query-params-ls>
