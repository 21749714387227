import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
// import { AuthService } from "src/app/lib/auth.service";
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserApiService {
  private baseUrl = environment.apiurl;
  private nodmaUrl = environment.nodmaApi;
  private questUrl = environment.questurl;
  private distributorUrl = environment.distributorurl;
  private userInfo = {
    username: '',
    password: '',
    api_key: '',
  };
  constructor(
    private http: HttpClient,
    private _router: Router // private _authService: AuthService
  ) {
    // this.userInfo = this._authService.getUserInfo();
    // this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    console.log('Home DataFacade', this.userInfo);
  }

  getCountries() {
    console.log('hello--->');
    let url = this.nodmaUrl + '/api/v1/country';
    return this.http.get(url).pipe(
      map((res: Response) => {
        let result = [res];
        return result;
      })
    );
  }

  checkUsername(name) {
    console.log('testing--->', name);
    let url = this.baseUrl + '/api/v1/users/checkusername/' + name;
    return this.http.get(url).pipe(
      map((res: Response) => {
        let result = res;
        return result;
      })
    );
  }

  signup(object) {
    let url = this.baseUrl + '/app/register';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        apikey: environment.apikey,
      }),
    };
    return this.http.post(url, object, httpOptions).pipe(
      map((res: Response) => {
        let result = [res];
        return result;
      })
    );
  }

  additionalInfo(object) {
    console.log('testing--->');
    let url = this.nodmaUrl + '/api/v1/country';
    return this.http.get(url).pipe(
      map((res: Response) => {
        let result = [res];
        return result;
      })
    );
  }

  claimBundle(code) {
    let url =
      this.questUrl +
      '/api/v1/bundledgrade/claim?code=' +
      code.code +
      '&onboarding=true';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        apikey: code.apikey,
        username: code.username,
        password: code.password,
        user: code.user,
      }),
    };

    var body = {};

    return this.http.post(url, body, httpOptions).pipe(
      map(
        (res: Response) => {
          // let result = [];
          // result = [res];
          console.log('data');
          return res;
        },
        (err) => {
          console.log('data error');
          console.log(err);
        }
      )
    );
  }

  getPublishKey() {
    let url = this.baseUrl + '/stripe/publish-key';
    console.log(url);
    return this.http.get(url).pipe(
      map((res: Response) => {
        let result = res;
        console.log(result);
        return result;
      })
    );
  }
  discountCheck(code) {
    let url = this.baseUrl + '/api/v1/discount-info/' + code;
    console.log(url);
    return this.http.get(url).pipe(
      map((res: Response) => {
        let result = res;
        console.log(result);
        return result;
      })
    );
  }
  getCustomerInfo(code) {
    let url = this.distributorUrl + '/api/v1/customers/' + code;
    console.log(url);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Basic ' + btoa('distributor-admin:*bf7K%hxTC@^H!B'),
      }),
    };
    return this.http.get(url, httpOptions).pipe(
      map((res: Response) => {
        let result = res;
        console.log(result);
        return result;
      })
    );
  }
  getSubscriptionInfo(data) {
    let url =
      this.baseUrl + '/api/v1/subscriptions?interval=' + data + '&tag=ONEPAGE';
    console.log(url);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.get(url, httpOptions).pipe(
      map((res: Response) => {
        let result = res;
        console.log(result);
        return result;
      })
    );
  }
  getPromoInfo(data) {
    let url = this.baseUrl + '/api/v1/subscriptions?tag=' + data;
    console.log(url);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.get(url, httpOptions).pipe(
      map((res: Response) => {
        let result = res;
        console.log(result);
        return result;
      })
    );
  }
  getState(data) {
    let url = this.baseUrl + '/api/v1/states?countryCode=' + data;
    console.log(url);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.get(url, httpOptions).pipe(
      map((res: Response) => {
        let result = res;
        console.log(result);
        return result;
      })
    );
  }
}
