<div>
  <app-moving-bg></app-moving-bg>
  <div *ngIf="Bundle" class="d-flex justify-content-center align-items-center">
    <div class="apple-wrap">
      <div style="margin-bottom: 12px">Logging in with Apple ...</div>
      <div class="spinner-border text-success" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
  <div class="d-flex flex-column align-items-center pt-40" *ngIf="!Bundle">
    <app-logo [class]="'middle'"></app-logo>
    <app-content-code
      class="mt-5p"
      [loading]="codeLoading"
      [locale]="locale"
      (submitClicked)="callClaimBundle($event)"
    ></app-content-code>
  </div>
</div>
<app-custom-feedback
  *ngIf="showFeedback"
  [feedbackTitle]="feedbackTitle"
  [feedbackBody]="feedbackBody"
  [feedbackBtn]="feedbackBtn"
  [tickIcon]="tickIcon"
></app-custom-feedback>
