<div class="btn-div">
  <div class="header-text text-c300">{{ locale?.mainPage.welcome }}</div>
  <div class="normal-text text-c300 mt-8">
    {{ locale?.mainPage.whatWouldYouDo }}
  </div>
  <div style="position: relative">
    <div
      id="login_container"
      class="btnwrapper mt-16 d-flex justify-content-around align-items-center"
    ></div>
    <div class="wechat-text d-flex justify-content-center">
      <img src="../../../../../../assets/images/wechat_icon.svg" />
      <span style="margin-left: 8px"> {{ locale?.mainPage.wechatLogin }}</span>
    </div>
  </div>
  <div class="or-wrap d-flex justify-content-center align-items-center">
    <div class="or-text d-flex justify-content-center align-items-center">
      OR
    </div>
  </div>
  <div class="phLogin-wrap">
    <div class="phLogin-btn" (click)="phLogin($event)">
      <svg
        class="phone-icon"
        width="40"
        height="41"
        viewBox="0 0 40 41"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M24.2812 9.00391H13.3438C12.0498 9.00391 11 10.0537 11 11.3477V31.6602C11 32.9541 12.0498 34.0039 13.3438 34.0039H24.2812C25.5752 34.0039 26.625 32.9541 26.625 31.6602V11.3477C26.625 10.0537 25.5752 9.00391 24.2812 9.00391ZM18.8125 32.4414C17.9482 32.4414 17.25 31.7432 17.25 30.8789C17.25 30.0146 17.9482 29.3164 18.8125 29.3164C19.6768 29.3164 20.375 30.0146 20.375 30.8789C20.375 31.7432 19.6768 32.4414 18.8125 32.4414ZM24.2812 27.168C24.2812 27.4902 24.0176 27.7539 23.6953 27.7539H13.9297C13.6074 27.7539 13.3438 27.4902 13.3438 27.168V11.9336C13.3438 11.6113 13.6074 11.3477 13.9297 11.3477H23.6953C24.0176 11.3477 24.2812 11.6113 24.2812 11.9336V27.168Z"
        />
      </svg>

      <span>{{ locale?.mainPage.phLogin }}</span>
    </div>
    <div class="appleLogin-btn" (click)="appleSendReq($event)">
      <img class="apple-icon" src="./assets/images/apple.svg" />

      <span>Log in with Apple</span>
    </div>
  </div>
  <div (click)="loginToggle()" class="cursor email-signin mt-5p">
    {{ locale?.mainPage.emailSignin }}
  </div>
</div>
