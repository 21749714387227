<!-- <ng-container >

</ng-container> -->

<!-- <div class="d-flex align-item-center" *ngIf="!withoutImage">
  <app-image [src]="redCross" *ngIf="!withoutImage" [width]="'20px'" [height]="'20px'" [maxWidth]="'20px'" style="margin-right: 12px"></app-image>
  <span [class]="loading ? 'btn-disable-text' : class" (click)="onTextClick($event)">
    <ng-content></ng-content>
  </span>
</div> -->

<ng-container *ngIf="!withoutImage">
  <div class="d-flex align-item-center" *ngIf="!withoutImage">
    <app-image [src]="redCross" *ngIf="!withoutImage" [width]="'20px'" [height]="'20px'" [maxWidth]="'20px'" style="margin-right: 12px"></app-image>
    <span [class]="loading ? 'btn-disable-text' : class" (click)="onTextClick($event)">
      <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
    </span>
  </div>
</ng-container>

<ng-container *ngIf="withoutImage">
  <span [class]="loading ? 'btn-disable-text' : class" (click)="onTextClick($event)">
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
  </span>
</ng-container>

<ng-template #contentTpl><ng-content></ng-content></ng-template>





