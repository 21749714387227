import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RegisterInfoComponent } from './register-info/register-info.component';
import { GlobalComponentsModule } from 'src/app/global-components/global-components.module';
import { AdditionalInfoComponent } from './additional-info/additional-info.component';
import { GenderComponent } from './gender/gender.component';
import { GenderItemComponent } from './gender-item/gender-item.component';
import { AgeComponent } from './age/age.component';
import { CountryComponent } from './country/country.component';
import { TrialcreateComponent } from './trialcreate/trialcreate.component';
import { BackComponent } from './back/back.component';
import { StateListComponent } from './state-list/state-list.component';
import { CountryListComponent } from './country-list/country-list.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';

const components = [
  RegisterInfoComponent,
  AdditionalInfoComponent,
  GenderComponent,
  GenderItemComponent,
  AgeComponent,
  CountryComponent,
  TrialcreateComponent,
  BackComponent,
  StateListComponent,
  CountryListComponent,
];

@NgModule({
  declarations: components,
  exports: components,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    GlobalComponentsModule,
    FormsModule,
    Ng2SearchPipeModule,
  ],
})
export class SharedComponentsModule {}
