import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/lib/auth.service';
import { LocaleService } from 'src/app/lib/locale.service';
import { SharedService } from 'src/app/lib/shared-service';
import {
  BusinessLogicRequirements,
  BusinessRequirementsInjectionToken,
} from '../../../business-logic.requirements';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-claim-page',
  templateUrl: './claim-page.component.html',
  styleUrls: ['./claim-page.component.css'],
})
export class ClaimPageComponent implements OnInit {
  public locale: any;
  public codeLoading: boolean = false;
  public code: any;
  public codeError: any;
  public codeFlag: boolean = false;
  public popupFlag: boolean = false;
  public questId;
  public skill;
  public feedbackTitle = '';
  public feedbackBody = '';
  public feedbackBtn = {
    status: false,
    text: '',
  };
  public tickIcon = true;
  public showFeedback = false;

  constructor(
    @Inject(BusinessRequirementsInjectionToken)
    private business: BusinessLogicRequirements,
    private _localeService: LocaleService,
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private shared: SharedService
  ) {
    this.code = this.activatedRoute.snapshot.paramMap.get('id');
  }

  ngOnInit(): void {
    this.getLocaleJson();
    if (this.code) {
      this.claimCodeClick(this.code);
    }
    this.activatedRoute.queryParams.subscribe((params) => {
      console.log('params', params);
      this.questId = params.questID;
      this.skill = params.skill;
      if (this.questId && this.skill) {
        this.shared.setParamsForSkill(this.questId, this.skill);
      }
    });
  }
  getLocaleJson() {
    this._localeService.getJson().subscribe((data) => {
      console.log('locale in home', data);
      this.locale = data;
    });
  }
  claimCodeClick(bundleCode) {
    this.codeLoading = true;
    this.code = bundleCode;
    var encryCode = btoa(bundleCode);
    this.business.checkBundle(encryCode).subscribe(
      (data) => {
        this.codeLoading = false;
        // this.shared.setBundleData(data[0]);
        // this.authService.setBundleInfo(data[0]);
        localStorage.setItem(
          'bundleInfo',
          this.authService.encrypt(JSON.stringify(data), null)
        );
        localStorage.setItem('contentCode', btoa(this.code));
        if(localStorage.getItem("noSub&Grade") == "true") {
          var temp = this.authService.decrypt(
            localStorage.getItem('userInfo'),
            environment.secretkey
          );
          var userInfo = JSON.parse(temp);
          var header = {
            apikey: userInfo.apikey || userInfo.ApiKey,
            username: userInfo.username,
            password: userInfo.password || userInfo.Token,
            user: userInfo.userId,
            code: encryCode,
          };
          this.business.claimBundle(header).subscribe(
            (data) => {
              this.codeLoading = false;
              console.log(data);
              localStorage.setItem(
                'bundleInfo',
                this.authService.encrypt(JSON.stringify(data), null)
              );
              this.router.navigate(['/code/successful']);
              // this.dashboardRedir = this.authService.getRedirectDashboardUrl(header);
              // this.redirectStemWerkz();
            },
            (err) => {
              this.codeLoading = false;
              console.log(err.message);

            }
          );
        }
        else this.router.navigate(['/code/signup']);
      },
      (err) => {
        this.codeLoading = false;
        console.log(err);
        this.codeError = err.status;
        if(localStorage.getItem("noSub&Grade") == "true") {
          this.feedbackTitle =
          err.error.message == null
            ? err.error.error.message
            : err.error.message;
        this.tickIcon = false;
        this.feedbackBody = '';
        this.feedbackBtn = {
          status: false,
          text: '',
        };
        this.showFeedback = true;
        setTimeout(() => {
          this.showFeedback = false;
        }, 3000);
        }
        else this.codeFlag = true;
      }
    );
  }
  backButton() {
    this.router.navigate(['']);
  }
  openPopup() {
    this.popupFlag = true;
  }
  cancelBtnClicked() {
    this.popupFlag = false;
  }
  trialClicked() {
    this.router.navigate(['/trial/signup']);
  }
}
