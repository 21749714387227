import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  Output,
} from '@angular/core';
import { ContentCodeStore } from './content-code.store';
import { FormBuilder, Validators } from '@angular/forms';
import { SharedService } from 'src/app/lib/shared-service';

@Component({
  selector: 'app-content-code',
  templateUrl: './content-code.component.html',
  styleUrls: ['./content-code.component.css'],
})
export class ContentCodeComponent {
  @Output() submitClicked = new EventEmitter<any>();
  @Input() loading: boolean;
  @Input() locale: any;
  public mobileView;
  public mobileViewService;
  public loadflag: boolean = true;

  ngOnInit() {
    console.log('loading state', this.loading);

    this.form.valueChanges.subscribe((value) => {
      this.codeClass = 'content-code';
    });
    this.mobileViewService = this.shared.GetMobileView.subscribe((data) => {
      this.mobileView = data;
      console.log('mobo test', this.mobileView);
    });
  }

  public form = this.fb.group({
    code: this.fb.control('', [Validators.required, Validators.minLength(19)]),
  });
  public code: any;
  public codeClass: any = 'content-code';
  // public showerror: boolean = false;
  public errorMsg: any = '';

  public mask = {
    guide: false,
    showMask: true,
    mask: [
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      '-',
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      '-',
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      '-',
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
    ],
  };

  submitBtn($event) {
    if (this.form.valid) {
      // this.showerror = false;
      // console.log("code",this.form.get('code').value);
      var newStr = this.form.get('code').value.replace(/-/g, '');
      this.submitClicked.emit(newStr);
    } else {
      this.codeClass = 'code-error';
      // this.showerror = true;
      this.errorMsg = this.locale.digitCode.pleaseEnter;
    }
  }

  constructor(
    private store: ContentCodeStore,
    private fb: FormBuilder,
    private shared: SharedService
  ) {}
}
