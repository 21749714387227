<div class="success-wrap">
  <img style="margin-bottom: 24px" src="./assets/images/form_tick.png" />
  <div class="success">Success!</div>
  <div class="info" *ngIf="accountType == 'Parent'">
    {{ locale?.parentInfo.savedSuccessfully }}
  </div>
  <div class="info" *ngIf="accountType == 'Child'">
    {{ locale?.parentInfo.savedSuccessfully }}
  </div>
</div>
