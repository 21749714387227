import { DOCUMENT } from '@angular/common';
import { Inject, Component, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/lib/auth.service';
import { LocaleService } from 'src/app/lib/locale.service';
import { SharedService } from 'src/app/lib/shared-service';
import {
  BusinessLogicRequirements,
  BusinessRequirementsInjectionToken,
} from '../business-logic.requirements';
import { GlobalLoginStore } from './global-login.store';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-global-login',
  templateUrl: './global-login-controller.component.html',
  styleUrls: ['./global-login-controller.component.css'],
})
export class GlobalLoginControllerComponent {
  public tos = environment.termsOfService;
  public pp = environment.privacyPolicy;
  constructor(
    @Inject(BusinessRequirementsInjectionToken)
    private business: BusinessLogicRequirements,
    private store: GlobalLoginStore,
    @Inject(DOCUMENT) private document: Document,
    private _localeService: LocaleService,
    private renderer: Renderer2,
    private authService: AuthService,
    private router: Router,
    private shared: SharedService,
    private activatedRoute: ActivatedRoute
  ) {}
  public dashboardRedir: any = '';
  public loginLoading: boolean = false;
  public locale: any;
  public feedbackTitle = '';
  public feedbackBody = '';
  public feedbackBtn = {
    status: false,
    text: '',
  };
  public tickIcon = true;
  public showFeedback = false;
  public origin;
  public questId;
  public skill;
  public loginMethods = environment.loginMethods;

  ngOnInit(): void {
    this.getLocaleJson();
    this.renderer.addClass(this.document.body, 'embedded-body');
    // localStorage.removeItem('bundleInfo');
    // localStorage.removeItem('userInfo');
    // localStorage.removeItem('state');
    // localStorage.removeItem('contentCode');
    // localStorage.removeItem('sessionId');
    // localStorage.removeItem('dashboardRedir');
    if (localStorage.getItem('oam-sessionId')) {
      var sessionId = localStorage.getItem('oam-sessionId');
    }
    localStorage.clear();
    localStorage.setItem('oam-sessionId', sessionId);
    setTimeout(() => {
      this.origin = this.shared.getOrigin();
      this.goOrigin();
    }, 1000);
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(this.document.body, 'embedded-body');
  }
  getLocaleJson() {
    this._localeService.getJson().subscribe((data) => {
      console.log('locale in home', data);
      this.locale = data;
    });
  }
  loginClicked(data) {
    this.loginLoading = true;
    console.log('data is', data);
    this.business.globalLogin(data.username, data.password).subscribe(
      (res) => {
        console.log('res', res);
        let userInfo = this.authService.encrypt(JSON.stringify(res), null);
        localStorage.setItem('userInfo', userInfo);
        var header = {
          apikey: res.ApiKey,
          username: res.username,
          user: res.userId,
          password: res.Token,
          playerid: res.idPlayer,
        };
        // var header = {
        //   apikey: res[0].apikey,
        //   username: res[0].username,
        //   user: res[0].userid,
        //   password: res[0].password,
        //   playerid: res[0].playerid,
        // };
        this.dashboardRedir = this.authService.getRedirectDashboardUrl(header);
        localStorage.setItem('dashboardRedir', this.dashboardRedir);
        // window.location.replace(this.dashboardRedir);
        this.checkPlan(header);
      },
      // check bundle error
      (err) => {
        this.loginLoading = false;
        console.log(err);
        this.feedbackTitle =
          err.error.message == null
            ? err.error.error.message
            : err.error.message;
        this.tickIcon = false;
        this.feedbackBody = '';
        this.feedbackBtn = {
          status: false,
          text: '',
        };
        this.showFeedback = true;
        setTimeout(() => {
          this.showFeedback = false;
        }, 3000);
        if (err.error.username != null && err.error.username != undefined) {
          var header = {
            apikey: err.error.ApiKey,
            username: err.error.username,
            user: err.error.userId,
            password: err.error.Token,
            playerid: err.error.idPlayer,
          };
          let userInfo = this.authService.encrypt(
            JSON.stringify(err.error),
            null
          );
          localStorage.setItem('userInfo', userInfo);
          this.dashboardRedir =
            this.authService.getRedirectDashboardUrl(header);
          localStorage.setItem('dashboardRedir', this.dashboardRedir);
          this.checkPlan(header);
        }
      }
    );
  }
  checkPlan(header) {
    this.business.checkPlan(header).subscribe(
      (res) => {
        console.log('check plan', res);
        window.location.replace(this.dashboardRedir);
      },
      (err) => {
        console.log(err);
        console.log(err);
        this.feedbackTitle =
          err.error.message == null
            ? err.error.error.message
            : err.error.message;
        this.tickIcon = false;
        this.feedbackBody = '';
        this.feedbackBtn = {
          status: false,
          text: '',
        };
        this.showFeedback = true;
        setTimeout(() => {
          this.showFeedback = false;
        }, 3000);
        this.checkGrade(header);
      }
    );
  }
  checkGrade(header) {
    this.business.checkUserGrade(header).subscribe(
      (res) => {
        console.log('check plan', res);
        if (res.bundledGrades.length != 0) {
          setTimeout(() => {
            window.location.replace(this.dashboardRedir);
          }, 3000);
        } else {
          localStorage.setItem('noSub&Grade', 'true');
          this.claimClicked();
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
  cleverClicked() {
    console.log('clever Click');
    var uri = window.origin + '/clever/redirect';
    var client_id = 'f8d9c88c42cb1de653c3';
    var url =
      'https://clever.com/oauth/authorize?response_type=code&redirect_uri=' +
      uri +
      '&client_id=' +
      client_id;
    window.location.replace(url);
  }
  trialClicked() {
    this.router.navigate(['/trial/signup']);
  }
  claimClicked() {
    this.router.navigate(['/claim/code']);
  }
  goOrigin() {
    console.log(this.origin);
    switch (this.origin) {
      case 'us':
        this.tos = environment.termsOfService;
        this.pp = environment.privacyPolicy;
        console.log('us');
        break;
      case 'asia':
        this.tos = environment.asia_termsOfService;
        this.pp = environment.asia_privacyPolicy;
        console.log('asia');
        break;
      default:
        break;
    }
  }
}
