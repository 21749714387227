import { Component, HostListener } from '@angular/core';
import { SharedService } from './lib/shared-service';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'stemwerkz-start';
  constructor(private shared: SharedService) {}

  ngOnInit() {
    if (window.innerWidth < 768 || document.body.clientWidth < 768) {
      this.shared.setMobileView(true);
      this.shared.defineMobileView(true);
    } else this.shared.defineMobileView(false);
    if (!localStorage.getItem('oam-sessionId')) {
      var sessionId = this.makeid(16);
      console.log('oam-sessionId', sessionId);
      localStorage.setItem('oam-sessionId', sessionId);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(e) {
    // console.log('resize',window.innerWidth,document.body.clientWidth)
    if (window.innerWidth < 768 || document.body.clientWidth < 768)
      this.shared.defineMobileView(true);
    else this.shared.defineMobileView(false);
  }
  makeid(length) {
    // var result = '';
    // var characters =
    //   'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789012345678901234567890123456789';
    // var charactersLength = characters.length;
    // for (var i = 0; i < length; i++) {
    //   result += characters.charAt(Math.floor(Math.random() * charactersLength));
    // }
    // return result;
    return uuidv4();
  }
}
