<div class="parent-wrap">
  <div class="parent-info">{{ locale?.childInfo.childInfo }}</div>
  <div class="mb-32">
    <div class="mb-12 normal-text">{{ locale?.childInfo.birthDate }}</div>
    <div style="position: relative" class="d-flex justify-content-between">
      <!-- <img class="birthday" src="./assets/images/birthday.svg" /> -->
      <!-- <input
        [(ngModel)]="infoTwo.birthYear"
        placeholder="Choose your Birth year"
        class="wechat-textbox"
      /> -->
      <app-month-list
        [locale]="locale"
        style="margin-right: 4px"
        (pickedMonth)="selectedMonth($event)"
      ></app-month-list>
      <app-year-list
        [locale]="locale"
        style="margin-left: 4px"
        (pickedYear)="selectedYear($event)"
      ></app-year-list>
    </div>
    <div class="yearsold" *ngIf="infoTwo.birthMonth && infoTwo.birthYear">
      <!-- <span class="yearsold" style="margin-top: 12px;">{{yearsold}} {{yearsold > 1 ? 'years' : 'year' }} old</span> -->
      <span class="yearsold" style="margin-top: 12px"
        >{{ yearsold }} {{ locale?.childInfo.old }}</span
      >
    </div>
  </div>
  <div class="mb-12 normal-text">{{ locale?.childInfo.gender }}</div>
  <div style="flex-flow: wrap" class="d-flex mb-32">
    <div
      [ngClass]="{ active: infoTwo.gender == 'boy' }"
      class="choice-div boy"
      (click)="chooseGender('boy')"
    >
      {{ locale?.childInfo.boy }}
    </div>
    <div
      [ngClass]="{ active: infoTwo.gender == 'girl' }"
      class="choice-div girl"
      (click)="chooseGender('girl')"
    >
      {{ locale?.childInfo.girl }}
    </div>
    <div
      [ngClass]="{ active: infoTwo.gender == 'i-prefer-not-to-say' }"
      class="choice-div prefer"
      (click)="chooseGender('i-prefer-not-to-say')"
    >
      {{ locale?.childInfo.preferNot }}
    </div>
  </div>
  <div class="d-flex align-items-center justify-content-center">
    <app-button
      (click)="continue()"
      [class]="
        filledAllData ? 'monthly-button-blue' : 'monthly-button-blue-disable'
      "
      [height]="'64px'"
      [width]="'300px'"
      ><span class="continue-text">{{
        locale?.childInfo.continue
      }}</span></app-button
    >
  </div>
</div>
