import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ContinueSignupStore {

}

export enum ContinueSignupStoreKeys {

}
