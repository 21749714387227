<div *ngIf="!multiline && angularMask">
  <div class="d-flex input-div">
    <input
      [class]="class"
      [ngClass]="ngClass"
      #single
      [formControl]="control"
      [placeholder]="hint"
      *ngIf="!multiline"
      [type]="type"
      [readonly]="readonly"
      [ngStyle]="{
        width: width ? width : 'auto',
        height: height ? height : 'auto',
        'text-align': textAlign ? textAlign : 'left'
      }"
      [value]="value"
      (blur)="onBlur($event)"
      (keyup)="keyUp($event)"
      [textMask]="angularMask"
      [maxlength]="maxlength"
    />
    <span class="content-div"> <ng-content></ng-content></span>
  </div>
</div>

<div
  *ngIf="!multiline && angularMask == null && !doubleImage"
  [ngClass]="{ 'margin-for-regInfo': registerInfo }"
>
  <div class="d-flex input-div">
    <input
      [class]="class"
      [ngClass]="ngClass"
      #single
      [formControl]="control"
      [placeholder]="hint"
      *ngIf="!multiline"
      [type]="type"
      [readonly]="readonly"
      [ngStyle]="{
        width: width ? width : 'auto',
        height: height ? height : 'auto',
        'text-align': textAlign ? textAlign : 'left'
      }"
      [value]="value"
      (blur)="onBlur($event)"
      (keyup)="keyUp($event)"
      [maxlength]="maxlength"
    />
    <span [ngClass]="!startIcon ? 'content-div' : 'start-div'">
      <ng-content></ng-content
    ></span>
  </div>
</div>

<div *ngIf="!multiline && angularMask == null && doubleImage">
  <div class="d-flex input-div">
    <span class="front-image cursor">
      <app-image
        [src]="image1.src"
        [width]="image1.width"
        [height]="image1.height"
        [maxWidth]="image1.maxWidth"
      ></app-image>
    </span>
    <input
      [class]="class"
      [ngClass]="ngClass"
      #single
      [formControl]="control"
      [placeholder]="hint"
      *ngIf="!multiline"
      [type]="type"
      [readonly]="readonly"
      [ngStyle]="{
        width: width ? width : 'auto',
        height: height ? height : 'auto',
        'text-align': textAlign ? textAlign : 'left'
      }"
      [value]="value"
      (blur)="onBlur($event)"
      (keyup)="keyUp($event)"
      [maxlength]="maxlength"
    />
    <span class="back-image cursor">
      <app-image
        [src]="image2.src"
        [width]="image2.width"
        [height]="image2.height"
        [maxWidth]="image2.maxWidth"
      ></app-image>
    </span>
  </div>
</div>

<div *ngIf="multiline">
  <textarea
    [class]="class"
    #multi
    [formControl]="control"
    [rows]="rows"
    *ngIf="multiline"
    [placeholder]="hint"
    [readonly]="readonly"
    [ngStyle]="{ width: width ? width : 'auto', height: height }"
  ></textarea>
</div>
