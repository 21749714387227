<div class="outer" *ngIf="!showVideo">
  <app-back (output)="clickedBack($event)"></app-back>
  <img style="display: none" src="{{ upperPic }}" (load)="imgLoad()" />
  <img style="display: none" src="{{ lowerPic }}" (load)="imgLoad()" />
  <div class="img-cover">
    <img
      *ngIf="imgLoaded"
      class="upper-img"
      style="position: absolute; width: 100%; z-index: -10"
      src="{{ upperPic }}"
    />
    <img
      *ngIf="imgLoaded"
      class="lower-img"
      style="position: absolute; width: 100%; z-index: -10"
      src="{{ lowerPic }}"
    />
  </div>
  <!-- <div class="help-us-wrapper">
    <app-text *ngIf="isAdditionalInfo" class="help-us-text text-c300">Help us get to know you better!</app-text>
  </div> -->
  <app-howtoclaim
    class="coverall"
    (cancelBtn)="cancelBtnClicked($event)"
    *ngIf="popupFlag"
  ></app-howtoclaim>
  <app-logo-list [logoList]="logoList"></app-logo-list>
  <app-logo [class]="'middle'" *ngIf="!mobileView"></app-logo>
  <div class="wrapper">
    <div
      class="box"
      [ngClass]="{ 'h-small': !isAdditionalInfo, 'h-large': isAdditionalInfo }"
    >
      <app-claim
        *ngIf="isClaim"
        [previewList]="previewList"
        [locale]="locale"
        [subInfo]="subInfo"
        (nextOutput)="closeClaim($event)"
        (popUpFlag)="openPopup()"
        (backgroundFlag)="changeBackground($event)"
      ></app-claim>
      <app-register-info
        [usernameError]="usernameError"
        [loading]="registerLoading"
        [locale]="locale"
        [firstName]="firstName"
        *ngIf="isRegisterInfo"
        (nextOutput)="closeRegister($event)"
        (loginOutput)="openModal($event)"
        (errorOutput)="changeError(false)"
      ></app-register-info>
      <app-additional-info
        *ngIf="isAdditionalInfo"
        [loading]="additionalLoading"
        [locale]="locale"
        [countryList]="countryList"
        [countryHint]="countryHint"
        (createAccount)="createAccount($event)"
      ></app-additional-info>
    </div>
  </div>
</div>
<app-video
  *ngIf="showVideo"
  [src]="videoUrl"
  [type]="videoType"
  (videoEndNow)="redirectStemWerkz()"
></app-video>
<app-custom-feedback
  *ngIf="showFeedback"
  [feedbackTitle]="feedbackTitle"
  [feedbackBody]="feedbackBody"
  [feedbackBtn]="feedbackBtn"
  [tickIcon]="tickIcon"
></app-custom-feedback>
<app-query-params-ls></app-query-params-ls>
