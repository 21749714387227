import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class VideoStore {

}

export enum VideoStoreKeys {

}
