<app-howtoclaim
  class="coverall"
  (cancelBtn)="cancelBtnClicked($event)"
  *ngIf="popupFlag"
></app-howtoclaim>
<app-moving-bg></app-moving-bg>
<div class="d-flex justify-content-center flex-column align-items-center">
  <div class="backwrapper back cursor" (click)="backButton()">
    <app-image [src]="'./assets/images/arrow.svg'"></app-image>
  </div>

  <app-logo [class]="'middle'"></app-logo>
  <app-content-code
    *ngIf="!codeFlag"
    class="mt-5p"
    [loading]="codeLoading"
    [locale]="locale"
    (submitClicked)="claimCodeClick($event)"
  ></app-content-code>
  <app-failcode
    *ngIf="codeFlag"
    (loginBtn)="openPopup()"
    [locale]="locale"
    [loading]="codeLoading"
    (trialBtn)="trialClicked()"
    [codeNo]="code"
    [errorMsg]="codeError"
    (submitClicked)="claimCodeClick($event)"
  ></app-failcode>
</div>
<app-custom-feedback
  *ngIf="showFeedback"
  [feedbackTitle]="feedbackTitle"
  [feedbackBody]="feedbackBody"
  [feedbackBtn]="feedbackBtn"
  [tickIcon]="tickIcon"
></app-custom-feedback>
