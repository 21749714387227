import { HttpClient } from '@angular/common/http';
import { Inject, Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  BusinessLogicRequirements,
  BusinessRequirementsInjectionToken,
} from '../../../business-logic.requirements';
import { AuthService } from 'src/app/lib/auth.service';
import { LocaleService } from 'src/app/lib/locale.service';

@Component({
  selector: 'app-wechat-redirect',
  templateUrl: './wechat-redirect.component.html',
  styleUrls: ['./wechat-redirect.component.css'],
})
export class WechatRedirectComponent implements OnInit {
  constructor(
    @Inject(BusinessRequirementsInjectionToken)
    private business: BusinessLogicRequirements,
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    private authService: AuthService,
    private router: Router,
    private _localeService: LocaleService
  ) {}
  getLocaleJson() {
    this._localeService.getJson().subscribe((data) => {
      console.log('locale in wechat', data);
      this.locale = data;
    });
  }
  ngOnInit(): void {
    this.getLocaleJson();
    this.activatedRoute.queryParams.subscribe((params) => {
      console.log('params wechat', params);
      this.code = params.code;
    });
    console.log('code is', this.code);
    if (this.code) {
      this.wechatLogin();
    }
  }
  public code;
  public res;
  public dashboardRedir = '';
  public Bundle: boolean = true;
  public locale: any;
  public userInfo: any;
  public feedbackTitle = '';
  public feedbackBody = '';
  public feedbackBtn = {
    status: false,
    text: '',
  };
  public tickIcon = true;
  public showFeedback = false;
  public spinning: boolean = false;
  public codeLoading: boolean = false;
  //login step1
  wechatLogin() {
    this.spinning = true;
    var body = {
      appid: 'wxbccf4a9b85a7aae9',
      secret: '2a0511bfde635eff695ee731897610d4',
      code: this.code,
    };
    this.business.wechatLogin(body).subscribe(
      (data) => {
        this.feedbackTitle = this.locale?.login.loggedIn;
        this.feedbackBody = '';
        this.feedbackBtn = {
          status: false,
          text: '',
        };
        this.showFeedback = true;
        this.tickIcon = true;
        setTimeout(() => {
          this.showFeedback = false;
        }, 3000);

        console.log(data);
        let userInfo = this.authService.encrypt(JSON.stringify(data), null);
        localStorage.setItem('userInfo', userInfo);
        // alert(JSON.stringify(data, null, 2));
        this.userInfo = data;
        var header = {
          apikey: data.ApiKey,
          username: data.username,
          user: data.userId,
          password: data.Token,
          playerid: data.idPlayer ? data.idPlayer : data.idUser,
        };
        this.dashboardRedir = this.authService.getRedirectDashboardUrl(header);
        localStorage.setItem('dashboardRedir', this.dashboardRedir);
        // this.redirectStemWerkz(); //if sub then go
        if (data.UserType == 3) {
          var header2 = {
            apikey: data.ApiKey,
            username: data.username,
            user: data.userId,
            password: data.Token,
            childId: data.childId,
            parentId: data.parentId,
          };
          this.business.checkUserInfo(header2).subscribe(
            (res) => {
              console.log('info detail', res);
              if (res.skipChildInfo == true && res.skipParentInfo == true) {
                this.redirectStemWerkz(); // if sub then go flow
              }
              if (res.skipChildInfo == false && res.skipParentInfo == false) {
                localStorage.setItem('bothInfo', 'true');
                this.router.navigateByUrl('info/parent');
              }
              if (res.skipChildInfo == false && res.skipParentInfo == true) {
                this.router.navigateByUrl('info/child');
              }
              if (res.skipChildInfo == true && res.skipParentInfo == false) {
                this.router.navigateByUrl('info/parent');
              }
            },
            //check bundle api error
            (err) => {}
          );
        } else if (data.UserType == 5) {
          this.redirectStemWerkz();
        }
      },
      //login error
      (err) => {
        localStorage.setItem('noSub', 'true');
        console.log(err);
        this.feedbackTitle =
          err.error.message == null
            ? err.error.error.message
            : err.error.message;
        this.tickIcon = false;
        if (err.error.error?.code == 'subscriptionExpiryError') {
          this.feedbackTitle = this.locale?.login.loggedIn;
          this.tickIcon = true;
        }
        this.feedbackBody = '';
        this.feedbackBtn = {
          status: false,
          text: '',
        };
        this.showFeedback = true;
        setTimeout(() => {
          this.showFeedback = false;
        }, 3000);
        if (err.status == 500) {
          this.router.navigate(['']);
        }
        // if subscription error then check bundle
        else if (err.error.error?.code == 'subscriptionExpiryError') {
          let userInfo = this.authService.encrypt(
            JSON.stringify(err.error),
            null
          );
          localStorage.setItem('userInfo', userInfo);
          var header = {
            apikey: err.error.ApiKey,
            username: err.error.username,
            user: err.error.userId,
            password: err.error.Token,
            playerid: err.error.idPlayer
              ? err.error.idPlayer
              : err.error.idUser,
          };
          this.dashboardRedir =
            this.authService.getRedirectDashboardUrl(header);
          localStorage.setItem('dashboardRedir', this.dashboardRedir);
          this.userInfo = err.error;

          if (err.error.UserType == 3) {
            var header2 = {
              apikey: err.error.ApiKey,
              username: err.error.username,
              user: err.error.userId,
              password: err.error.Token,
              childId: err.error.childId,
              parentId: err.error.parentId,
            };
            this.business.checkUserInfo(header2).subscribe(
              (res) => {
                console.log('info detail', res);
                if (res.skipChildInfo == true && res.skipParentInfo == true) {
                  // checking bundle
                  this.business.checkUserGrade(header).subscribe(
                    (res) => {
                      console.log('res', res);
                      // if bundle then go dashboard
                      if (res.bundledGrades.length != 0) {
                        this.redirectStemWerkz();
                      }
                      // if no bundle then open claim box
                      else {
                        this.Bundle = false;
                      }
                    },
                    // check bundle error
                    (err) => {
                      console.log(err);
                      console.log(err.message);
                      this.feedbackTitle = err.error.message;
                      this.feedbackBody = '';
                      this.feedbackBtn = {
                        status: false,
                        text: '',
                      };
                      this.tickIcon = false;
                      this.showFeedback = true;
                      setTimeout(() => {
                        this.showFeedback = false;
                        // this.Bundle = false;
                        this.router.navigate(['']);
                      }, 3000);
                    }
                  );
                }
                if (res.skipChildInfo == false && res.skipParentInfo == false) {
                  localStorage.setItem('bothInfo', 'true');
                  this.router.navigateByUrl('info/parent');
                }
                if (res.skipChildInfo == false && res.skipParentInfo == true) {
                  this.router.navigateByUrl('info/child');
                }
                if (res.skipChildInfo == true && res.skipParentInfo == false) {
                  this.router.navigateByUrl('info/parent');
                }
              },
              //check bundle api error
              (err) => {}
            );
          } else if (err.error.UserType == 5) {
            // checking bundle
            this.business.checkUserGrade(header).subscribe(
              (res) => {
                console.log('res', res);
                // if bundle then go dashboard
                if (res.bundledGrades.length != 0) {
                  this.redirectStemWerkz();
                }
                // if no bundle then open claim box
                else {
                  this.Bundle = false;
                }
              },
              // check bundle error
              (err) => {
                console.log(err);
                console.log(err.message);
                this.feedbackTitle = err.error.message;
                this.feedbackBody = '';
                this.feedbackBtn = {
                  status: false,
                  text: '',
                };
                this.tickIcon = false;
                this.showFeedback = true;
                setTimeout(() => {
                  this.showFeedback = false;
                  // this.Bundle = false;
                  this.router.navigate(['']);
                }, 3000);
              }
            );
          }
        }
      }
    );
  }

  redirectStemWerkz() {
    window.location.href = this.dashboardRedir;
  }

  callClaimBundle(bundleCode) {
    this.codeLoading = true;
    var encryCode = btoa(bundleCode);
    var header = {
      apikey: this.userInfo.ApiKey,
      username: this.userInfo.username,
      user: this.userInfo.userId,
      password: this.userInfo.Token,
      playerid: this.userInfo.idPlayer
        ? this.userInfo.idPlayer
        : this.userInfo.idUser,
      code: encryCode,
    };
    this.business.claimBundle(header).subscribe(
      (data) => {
        this.codeLoading = false;
        console.log(data);
        localStorage.setItem(
          'bundleInfo',
          this.authService.encrypt(JSON.stringify(data), null)
        );
        this.router.navigate(['/code/successful']);
        // this.dashboardRedir = this.authService.getRedirectDashboardUrl(header);
        // this.redirectStemWerkz();
      },
      (err) => {
        this.codeLoading = false;
        console.log(err.message);
        this.feedbackTitle = err.error.message;
        if (err.error.message == 'Invalid code provided.') {
          this.feedbackTitle = this.locale?.login.invalid;
        }
        this.feedbackBody = '';
        this.feedbackBtn = {
          status: false,
          text: '',
        };
        this.tickIcon = false;
        this.showFeedback = true;
        setTimeout(() => {
          this.showFeedback = false;
        }, 3000);
      }
    );
  }
}
