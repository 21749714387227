import { Component, EventEmitter, HostListener, Inject, Input, Output } from '@angular/core';
import { SharedService } from 'src/app/lib/shared-service';
import {HowtoclaimStore} from './howtoclaim.store'
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-howtoclaim',
    templateUrl: './howtoclaim.component.html',
    styleUrls: ['./howtoclaim.component.css']
})
export class HowtoclaimComponent {
    constructor(private store: HowtoclaimStore, private shared: SharedService) {}
    @Output() cancelBtn = new EventEmitter<any>();
    public mobileView;
    public mobileViewService;

    ngOnInit() {
      let body = (document.getElementsByTagName("body")[0].style.overflow =
      "hidden");
      window.scrollTo(0, 0);

      this.mobileViewService = this.shared.GetMobileView.subscribe(data=>{
        this.mobileView = data;
        console.log("mobo test", this.mobileView);

      });
    }

    ngOnDestroy() {
    let body = (document.getElementsByTagName("body")[0].style.overflow =
      "auto");
    this.mobileViewService.unsubscribe();
  }

    cancelClicked($event){
      this.cancelBtn.emit($event);
    }

    gotoDashboard() {
      window.location.href = environment.dashboardurl;
    }
}
