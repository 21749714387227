import {Inject, Injectable} from '@angular/core';
import {DataRequirements, DataRequirementsInjectionToken} from '../data.requirements';

@Injectable({
    providedIn: 'root'
})
export class SignupUseCase {
    constructor(
        @Inject(DataRequirementsInjectionToken) private data: DataRequirements
    ) {}

    run(object) {
      return this.data.signup(object);
    }
}
