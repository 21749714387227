import { Component, EventEmitter, Output, Input } from '@angular/core';
import { GenderStore } from './gender.store';

@Component({
  selector: 'app-gender',
  templateUrl: './gender.component.html',
  styleUrls: ['./gender.component.css'],
})
export class GenderComponent {
  constructor(private store: GenderStore) {}
  @Input() error;
  @Input() locale;
  @Output() genderOutput = new EventEmitter<any>();

  public itemClass = 'normal-color';
  public genderList = [
    {
      gender: 'Boy',
      class: 'normal-color',
      wrapperClass: 'normal-wrapper',
      forApi: 'boy',
    },
    {
      gender: 'Girl',
      class: 'normal-color',
      wrapperClass: 'normal-wrapper',
      forApi: 'girl',
    },
    {
      gender: 'I prefer not to say',
      class: 'normal-color',
      wrapperClass: 'normal-wrapper',
      forApi: 'i-prefer-not-to-say',
    },
  ];

  ngOnChanges() {
    this.genderList = [
      {
        gender: this.locale.claimedSignup.boy,
        class: 'normal-color',
        wrapperClass: 'normal-wrapper',
        forApi: 'boy',
      },
      {
        gender: this.locale.claimedSignup.girl,
        class: 'normal-color',
        wrapperClass: 'normal-wrapper',
        forApi: 'girl',
      },
      {
        gender: this.locale.claimedSignup.preferNot,
        class: 'normal-color',
        wrapperClass: 'normal-wrapper',
        forApi: 'i-prefer-not-to-say',
      },
    ];
  }

  chooseGender(index) {
    console.log(index);
    this.genderOutput.emit(this.genderList[index].forApi);
    switch (index) {
      case 0:
        this.selectUI(0, 1, 2);
        break;
      case 1:
        this.selectUI(1, 0, 2);
        break;
      case 2:
        this.selectUI(2, 1, 0);
        break;
    }
  }

  selectUI(select, unselect1, unselect2) {
    this.genderList[select].class = 'selected-color';
    this.genderList[select].wrapperClass = 'selected-wrapper';
    this.genderList[unselect1].class = 'unselected-color';
    this.genderList[unselect1].wrapperClass = 'normal-wrapper';
    this.genderList[unselect2].class = 'unselected-color';
    this.genderList[unselect2].wrapperClass = 'normal-wrapper';
  }

  errorUI() {
    for (var i = 0; i < this.genderList.length; i++) {
      this.genderList[i].class = 'error-color';
      this.genderList[i].wrapperClass = 'normal-wrapper';
    }
  }
}
