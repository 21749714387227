import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalComponentsModule } from '../../../global-components/global-components.module';
import { SharedComponentsModule } from '../shared-components/shared-components.module';
import { YearlySignupControllerComponent } from './yearly-signup-controller.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [YearlySignupControllerComponent],
  exports: [YearlySignupControllerComponent],
  imports: [
    CommonModule,
    RouterModule,
    GlobalComponentsModule,
    SharedComponentsModule,
  ],
})
export class YearlySignupModule {}
