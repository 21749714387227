<div class="parent-wrap">
  <div class="parent-info">{{ locale?.parentInfo.parentInfo }}</div>
  <div class="mb-32">
    <div class="mb-12 normal-text">{{ locale?.parentInfo.relation }}</div>
    <div class="d-flex">
      <div
        [ngClass]="{ active: infoTwo.parent == 'father' }"
        class="choice-div father"
        (click)="chooseParent('father')"
      >
        {{ locale?.parentInfo.father }}
      </div>
      <div
        [ngClass]="{ active: infoTwo.parent == 'mother' }"
        class="choice-div mother"
        (click)="chooseParent('mother')"
      >
        {{ locale?.parentInfo.mother }}
      </div>
      <div
        [ngClass]="{ active: infoTwo.parent == 'others' }"
        class="choice-div"
        (click)="chooseParent('others')"
      >
        {{ locale?.parentInfo.other }}
      </div>
    </div>
  </div>
  <div class="mb-32">
    <div class="mb-12 normal-text">{{ locale?.parentInfo.phNo }}</div>
    <app-country-cn
      [locale]="locale"
      (pickedCountry)="selectedCountry($event)"
      (phoneNumber)="phoneNumberSelected($event)"
      [countries]="countries"
    ></app-country-cn>
  </div>
  <div class="mb-32 normal-text">
    {{ locale?.parentInfo.email }}
    <span class="optional-text">{{ locale?.parentInfo.optional }}</span>
  </div>
  <div style="position: relative" class="input-div">
    <img class="email-logo" src="./assets/images/email.svg" />
    <input
      [(ngModel)]="infoTwo.email"
      (ngModelChange)="validateEmail($event)"
      [placeholder]="locale?.parentInfo.enterEmail"
      class="wechat-textbox"
    />
  </div>
  <div class="d-flex align-items-center justify-content-center">
    <app-button
      (click)="save()"
      [height]="'64px'"
      [width]="'300px'"
      [class]="
        loading
          ? 'monthly-button-blue-disable'
          : allDataFilled
          ? 'monthly-button-blue'
          : 'monthly-button-blue-disable'
      "
      ><span class="continue-text">{{
        locale?.parentInfo.save
      }}</span></app-button
    >
  </div>
</div>
