import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class TrialcreateStore {

}

export enum TrialcreateStoreKeys {

}
