<app-moving-bg></app-moving-bg>
<div *ngIf="cancelFlag || failFlag">
  <!-- <div class="back" (click)="back()">< back</div> -->
  <div
    class="
      container
      flex-column
      d-flex
      justify-content-center
      align-items-center
    "
  >
    <app-logo></app-logo>
    <div class="wrapper">
      <div class="title">
        Something went wrong with payment, please try again.
      </div>
      <div class="name-wrapper">
        <div class="un-title">Name</div>
        <div class="un" style="margin-bottom: 24px">{{ name }}</div>

        <div class="un-title">Username</div>
        <div class="un">{{ username }}</div>
      </div>
      <div class="btn-font d-flex justify-content-center">
        <app-button
          style="width: 100%"
          (click)="subscription()"
          [class]="'red-primary-m'"
          [width]="'100%'"
          [height]="'64px'"
          [loading]="loading"
          >Back to Payment</app-button
        >
      </div>
    </div>
  </div>
</div>
<div *ngIf="!cancelFlag && !failFlag && !createFlag">
  <!-- <div class="back" (click)="back()">< back</div> -->
  <div
    class="
      container
      flex-column
      d-flex
      justify-content-center
      align-items-center
    "
  >
    <app-logo></app-logo>
    <div class="wrapper2 align-items-center">
      <div class="title">Payment successful!</div>
      <div class="un-title">Redirecting to Dashboard</div>
      <div class="spinner-border text-success" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</div>

<div *ngIf="createFlag">
  <!-- <div class="back" (click)="back()">< back</div> -->
  <div
    class="
      container
      flex-column
      d-flex
      justify-content-center
      align-items-center
    "
  >
    <app-logo></app-logo>
    <div class="wrapper2 align-items-center">
      <div class="title">{{ message }}</div>
      <div class="spinner-border text-success" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</div>
