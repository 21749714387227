<div>
  <div style="position: relative" (click)="dropdownClicked()">
    <input
      [placeholder]="locale?.placeholder.chooseCity"
      [(ngModel)]="currentCountry.translatedName"
      class="country-input city-input"
      readonly
    />
    <img class="down-icon" src="./assets/images/downIcon.svg" />
  </div>
  <div *ngIf="dropdownToggle" class="dropdown">
    <input
      [placeholder]="locale?.placeholder.searchCity"
      [(ngModel)]="searchName"
      class="search-input"
    />
    <div id="scrolling_div" class="country-list">
      <div
        [ngClass]="
          currentCountry.isoCode == item.isoCode
            ? 'country-selected'
            : 'country-item'
        "
        (click)="selectCountry(item)"
        [id]="item.translatedName"
        class="country-item"
        *ngFor="let item of cities | filter: searchName"
      >
        {{ item.translatedName }}
      </div>
    </div>
  </div>
</div>
