<div class="register-wrapper">
  <app-text class="hey-text text-c300"
    >{{ locale?.claimedSignup.heyThere }},
    <span class="text-accent500">{{ firstName }}</span
    >!</app-text
  >
  <div class="form-wrapper">
    <form (keyup.enter)="$event.preventDefault(); continueBtnClicked()">
      <app-text class="create-text text-c100">{{
        locale?.claimedSignup.accessContent.access1
      }}</app-text>
      <app-logo
        class="small-logo"
        [width]="'150px'"
        [height]="'36px'"
        style="margin: 0px 8px 0px 8px"
      ></app-logo>
      <app-text class="create-text text-c100">{{
        locale?.claimedSignup.accessContent.access2
      }}</app-text>
      <app-text class="create-text-mobile text-c100"
        >Create your log in username and password.</app-text
      >
      <app-input
        [control]="form.get('username')"
        [hint]="locale?.claimedSignup.pickUsername"
        [class]="'signup-full'"
        [startIcon]="true"
        [registerInfo]="true"
        (blurEvent)="blurInput($event)"
      >
        <app-image
          [src]="accountUrl"
          [width]="'40px'"
          [height]="'48px'"
          [maxWidth]="'40px'"
        ></app-image>
      </app-input>
      <ng-container
        *ngIf="(!form.get('username').errors || !clickBtn) && !usernameError"
      >
        <app-text class="text-c100 desc-text">{{
          locale?.claimedSignup.goodToUse
        }}</app-text>
      </ng-container>
      <ng-container
        *ngIf="form.get('username').errors && clickBtn && !usernameError"
      >
        <app-text [class]="'form-error-label'" [withoutImage]="false"
          >Please enter username</app-text
        >
      </ng-container>
      <ng-container *ngIf="usernameError">
        <app-text [class]="'form-error-label'" [withoutImage]="false"
          >There is already an account with this username. If this is you
          <a (click)="login()" class="cursor">log in</a> now.</app-text
        >
      </ng-container>
      <div style="position: relative">
        <app-input
          [control]="form.get('password')"
          [type]="togglePassword"
          [hint]="locale?.claimedSignup.setPassword"
          [class]="'signup-full'"
          [startIcon]="true"
          [registerInfo]="true"
          (blurEvent)="blurInput($event)"
        >
          <app-image
            [src]="lockUrl"
            [width]="'40px'"
            [height]="'48px'"
            [maxWidth]="'40px'"
          ></app-image>
        </app-input>
        <app-image
          class="cursor"
          [class]="'eye'"
          *ngIf="!clickedEyeOpen"
          [src]="eyeCloseUrl"
          [width]="'24px'"
          [height]="'24px'"
          [maxWidth]="'24px'"
          (click)="clickedEye()"
        ></app-image>
        <app-image
          class="cursor"
          [class]="'eye'"
          *ngIf="clickedEyeOpen"
          [src]="eyeOpenUrl"
          [width]="'24px'"
          [height]="'24px'"
          [maxWidth]="'24px'"
          (click)="clickedEye()"
        ></app-image>
      </div>
      <ng-container *ngIf="!form.get('password').errors || !clickBtn">
        <app-text class="text-c100 desc-text">{{
          locale?.claimedSignup.eightCharLong
        }}</app-text>
      </ng-container>
      <ng-container *ngIf="form.get('password').errors && clickBtn">
        <app-text [class]="'form-error-label'" [withoutImage]="false">{{
          locale?.claimedSignup.eightCharLong
        }}</app-text>
      </ng-container>
      <div
        class="d-flex justify-content-center continue-btn"
        (click)="continueBtnClicked()"
      >
        <app-button
          [class]="!loading ? 'red-primary-l' : 'btn-disable red-primary-l'"
          >{{ locale?.claimedSignup.continue }}</app-button
        >
      </div>
      <!-- <div *ngIf="usernameError">
      User name error occurs
    </div> -->
    </form>
  </div>
</div>
