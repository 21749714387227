<app-custom-feedback
  style="position: fixed; top: 0; right: 0; z-index: 999"
  *ngIf="showFeedback"
  [feedbackTitle]="feedbackTitle"
  [feedbackBody]="feedbackBody"
  [feedbackBtn]="feedbackBtn"
  [tickIcon]="tickIcon"
></app-custom-feedback>
<div *ngIf="invalidCode" class="cover-all"></div>
<div *ngIf="invalidCode" class="invalid-code">
  <div class="invalid-title d-flex justify-content-center">Invalid Code</div>
  <div class="invalid-text">
    We could not verify the code you entered. Please check your code again.
  </div>
  <div
    (click)="backHome()"
    class="cursor invalid-btn d-flex justify-content-center align-items-center"
  >
    Back to Home
  </div>
</div>
<div class="d-flex flex-column" style="height: 100%">
  <div class="banner d-flex flex-column">
    <div class="d-flex justify-content-between upper-div">
      <app-logo [width]="'200px'" [height]="'48px'"></app-logo>
      <app-button
        id="login"
        [class]="'monthly-button'"
        class="login"
        (click)="backHome()"
        >Log in</app-button
      >
    </div>
    <div class="container-wrapper d-flex" style="height: 100%">
      <div class="d-flex banner-wrapper">
        <div class="left-div d-flex justify-content-center align-items-center">
          <div>
            <div class="title-1" style="white-space: nowrap">
              Become a Member!
            </div>
            <div class="title-2">Easy Enrollment</div>
          </div>
        </div>
        <div
          class="alpha right-div d-flex justify-content-center align-items-center"
          style="position: relative"
        >
          <div>
            <img class="kat" src="../../../../assets/images/alpha.png" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="wrapper">
    <div class="form-container">
      <div style="margin-bottom: 60px">
        <div
          class="special-offer d-flex justify-content-center align-items-center"
        >
          Special Subscription Offer
        </div>
        <div class="yellow-wrap d-flex flex-column align-items-center">
          <img class="offer-img" [src]="offerImgSetting()" />
        </div>
        <div class="white-wrap">
          <div style="border-bottom: 1px solid #e3e4e5; padding-bottom: 20px">
            Then {{ addCurrencySymbol(monthlyPrice) }}/<span
              *ngIf="intervalType == 'monthly'"
              >month</span
            ><span *ngIf="intervalType == 'yearly'">year</span>, until cancelled
            in the Parent Portal.
          </div>
          <div class="easy-wrap">
            <div class="easy-title">Easy Cancellation</div>
            <div>
              Cancellation can be done in the Parent Portal at any time to avoid
              future charges if you do not wish to continue.
            </div>
          </div>
        </div>
      </div>
      <div class="step1 d-flex align-items-center">
        <img
          class="hexa"
          style="margin-right: 24px"
          src="../../../../assets/images/hexa1.png"
        />
        <div id="step1" class="step-title">
          <span class="step_no">1. </span>Create Player Account
        </div>
      </div>
      <div class="form-1 d-flex" style="margin-top: 40px">
        <div id="name" class="form-font width-25">Name</div>
        <div
          style="position: relative"
          class="d-flex justify-content-between width-75"
        >
          <div style="width: 48%; position: relative">
            <app-input
              [control]="form.get('firstname')"
              [hint]="'First name'"
              [class]="firstnameClass"
              [startIcon]="true"
              [registerInfo]="false"
            >
            </app-input
            ><img
              *ngIf="form.get('firstname').errors && clickBtn"
              class="form-alert"
              src="../../../../assets/images/form_alert.png"
            />
            <img
              *ngIf="form.get('firstname').valid"
              class="form-alert"
              src="../../../../assets/images/form_tick.png"
            />
          </div>
          <div style="width: 48%; position: relative">
            <app-input
              [control]="form.get('lastname')"
              [hint]="'Last name'"
              [class]="lastnameClass"
              [startIcon]="true"
              [registerInfo]="false"
            ></app-input>
            <img
              *ngIf="form.get('lastname').errors && clickBtn"
              class="form-alert"
              src="../../../../assets/images/form_alert.png"
            />
            <img
              *ngIf="form.get('lastname').valid"
              class="form-alert"
              src="../../../../assets/images/form_tick.png"
            />
          </div>
        </div>
      </div>
      <div
        *ngIf="
          (form.get('firstname').errors || form.get('lastname').errors) &&
          clickBtn
        "
        class="error-msg"
      >
        Please enter First name and Last name
      </div>
      <div class="form-1 d-flex" style="margin-top: 32px">
        <div id="username" class="form-font width-25">Username</div>
        <div style="position: relative" class="d-flex width-75">
          <app-input
            style="width: 100%"
            [control]="form.get('username')"
            [hint]="'Pick player username'"
            [class]="usernameClass"
            [startIcon]="true"
            [registerInfo]="false"
            (blurEvent)="blurInput($event)"
          ></app-input>
          <img
            *ngIf="(form.get('username').errors && clickBtn) || usernameTaken"
            class="form-alert"
            src="../../../../assets/images/form_alert.png"
          />
          <img
            *ngIf="form.get('username').valid && !usernameTaken"
            class="form-alert"
            src="../../../../assets/images/form_tick.png"
          />
        </div>
      </div>
      <div *ngIf="form.get('username').errors && clickBtn" class="error-msg">
        Please enter username
      </div>
      <div
        *ngIf="form.get('username').valid && usernameTaken"
        class="error-msg"
      >
        There is already an account with this username. Please pick another
        username.
      </div>
      <div class="form-1 d-flex" style="margin-top: 32px">
        <div id="password" class="form-font width-25">Password</div>
        <div style="position: relative" class="d-flex width-75">
          <app-input
            style="width: 100%"
            [control]="form.get('password')"
            [hint]="'Set player password'"
            [type]="togglePassword"
            [class]="pwClass"
            [startIcon]="true"
            [registerInfo]="false"
          ></app-input>
          <img
            *ngIf="!eyeFlag"
            (click)="eyeToggle()"
            class="eye"
            src="../../../../assets/images/eye_open.svg"
          />
          <img
            *ngIf="eyeFlag"
            (click)="eyeToggle()"
            class="eye"
            src="../../../../assets/images/eye_close.svg"
          />
          <!-- <img src="../../../../assets/images/eye_open.svg"/> -->
        </div>
      </div>
      <div
        class="pw-length note"
        *ngIf="
          form.get('password').value.length > 0 &&
          form.get('password').errors &&
          !clickBtn
        "
      >
        Passwords must be 8 characters or more
      </div>
      <div *ngIf="form.get('password').errors && clickBtn" class="error-msg">
        Passwords must be 8 characters or more
      </div>
      <div class="form-1 d-flex" style="margin-top: 32px">
        <div id="gender" class="form-font width-25">Gender</div>
        <div class="d-flex gender-container width-75">
          <div
            [ngClass]="{ 'selected-gender': gender.boy }"
            (click)="genderSelect('boy')"
            class="gender d-flex justify-content-center align-items-center"
          >
            Boy
          </div>
          <div
            [ngClass]="{ 'selected-gender': gender.girl }"
            (click)="genderSelect('girl')"
            class="girl gender d-flex justify-content-center align-items-center"
          >
            Girl
          </div>
          <div
            [ngClass]="{ 'selected-gender': gender.other }"
            (click)="genderSelect('other')"
            class="other gender d-flex justify-content-center align-items-center"
          >
            I prefer not to say
          </div>
        </div>
      </div>
      <div
        *ngIf="!gender.boy && !gender.girl && !gender.other && clickBtn"
        class="error-msg"
      >
        Please choose player gender
      </div>
      <div class="form-1 d-flex" style="margin-top: 32px">
        <div id="yob" class="form-font width-25">Year of birth</div>
        <div style="position: relative" class="d-flex width-75">
          <app-input
            style="width: 100%"
            [control]="form.get('yob')"
            [hint]="'Enter player birth year'"
            [class]="yobClass"
            [startIcon]="true"
            [registerInfo]="false"
            [numberOnly]="true"
            [maxlength]="'4'"
          ></app-input>
          <!-- <img
            *ngIf="form.get('yob').errors && clickBtn"
            class="form-alert"
            src="../../../../assets/images/form_alert.png"
          /> -->
          <div
            *ngIf="form.get('yob').errors && clickBtn"
            class="age-div-invalid d-flex justify-content-center align-items-center"
          >
            Invalid year
          </div>
          <!-- <img
            *ngIf="form.get('yob').valid"
            class="form-alert"
            src="../../../../assets/images/form_tick.png"
          /> -->
          <div
            *ngIf="form.get('yob').valid"
            class="age-div d-flex justify-content-center align-items-center"
          >
            {{ calculateAge() }} years old
          </div>
        </div>
      </div>
      <div *ngIf="form.get('yob').errors && clickBtn" class="error-msg">
        Please enter year of birth
      </div>
      <div class="form-1 d-flex" style="margin-top: 32px">
        <div class="form-font width-25">Country</div>
        <div class="d-flex width-75">
          <app-country
            [hint]="countryHint"
            [defaultValue]="countryHint"
            [itemList]="countryList"
            (countryOutput)="chooseCountry($event)"
            [error]="countryError"
            style="width: 100%"
          ></app-country>
        </div>
      </div>

      <div class="step3 d-flex align-items-center" style="margin: 40px 0px">
        <img
          class="hexa"
          style="margin-right: 24px"
          src="../../../../assets/images/hexa2.png"
        />
        <div id="step3" class="step-title">
          <span class="step_no">2. </span>Enter Your Payment Information
        </div>
      </div>
      <div class="form-1 d-flex" style="margin-top: 32px">
        <div id="email" class="form-font width-25">Email</div>
        <div style="position: relative" class="d-flex width-75">
          <app-input
            style="width: 100%"
            [control]="form.get('email')"
            [hint]="'Enter your email'"
            [class]="emailClass"
            [startIcon]="true"
            [registerInfo]="false"
          ></app-input>
          <img
            *ngIf="form.get('email').errors && clickBtn"
            class="form-alert"
            src="../../../../assets/images/form_alert.png"
          />
          <img
            *ngIf="form.get('email').valid"
            class="form-alert"
            src="../../../../assets/images/form_tick.png"
          />
        </div>
      </div>

      <div *ngIf="form.get('email').errors && clickBtn" class="error-msg">
        Please enter your email
      </div>
      <div class="shipping-title">Shipping Address</div>
      <div class="form-1 d-flex" style="margin-top: 32px">
        <div id="contact" class="form-font width-25">Contact Name</div>
        <div style="position: relative" class="d-flex width-75">
          <app-input
            style="width: 100%"
            [control]="form.get('contact')"
            [hint]="'Enter Contact Name'"
            [class]="contactClass"
            [startIcon]="true"
            [registerInfo]="false"
          ></app-input>
          <img
            *ngIf="form.get('contact').errors && clickBtn"
            class="form-alert"
            src="../../../../assets/images/form_alert.png"
          />
          <img
            *ngIf="form.get('contact').valid"
            class="form-alert"
            src="../../../../assets/images/form_tick.png"
          />
        </div>
      </div>
      <div *ngIf="form.get('contact').errors && clickBtn" class="error-msg">
        Please enter Contact Name
      </div>
      <div class="form-1 d-flex" style="margin-top: 32px">
        <div id="country_region" class="form-font width-25">
          Country/ Region
        </div>
        <div class="d-flex width-75">
          <app-country-list
            style="width: 100%"
            (selectedCountry)="countrySelect($event)"
            [countries]="countryData"
          ></app-country-list>
        </div>
      </div>
      <div *ngIf="countryRegion == '' && clickBtn" class="error-msg">
        Please enter Country/ Region
      </div>
      <div class="form-1 d-flex" style="margin-top: 32px">
        <div id="street" class="form-font width-25">Street Address</div>
        <div style="position: relative" class="d-flex width-75">
          <app-input
            style="width: 100%"
            [control]="form.get('street')"
            [hint]="'Enter Street Address'"
            [class]="streetClass"
            [startIcon]="true"
            [registerInfo]="false"
          ></app-input>
          <img
            *ngIf="form.get('street').errors && clickBtn"
            class="form-alert"
            src="../../../../assets/images/form_alert.png"
          />
          <img
            *ngIf="form.get('street').valid"
            class="form-alert"
            src="../../../../assets/images/form_tick.png"
          />
        </div>
      </div>
      <div
        style="
          margin-top: 40px;
          width: 100%;
          display: flex;
          justify-content: flex-end;
        "
      >
        <div style="position: relative" class="d-flex width-75">
          <app-input
            style="width: 100%"
            [control]="form.get('apartment')"
            [hint]="'Apartment, suite, unit etc. (Optional)'"
            [class]="apartmentClass"
            [startIcon]="true"
            [registerInfo]="false"
          ></app-input>
        </div>
      </div>
      <div *ngIf="form.get('street').errors && clickBtn" class="error-msg">
        Please enter Street Address
      </div>
      <div class="form-1 d-flex" style="margin-top: 32px">
        <div id="province" class="form-font width-25">
          State/ Province/ Region
        </div>
        <div style="position: relative" class="d-flex width-75">
          <app-input
            style="width: 100%"
            [control]="form.get('province')"
            [hint]="'State/ Province/Region (Optional)'"
            [class]="provinceClass"
            [startIcon]="true"
            [registerInfo]="false"
          ></app-input>
          <!-- <img
            *ngIf="form.get('province').errors && clickBtn"
            class="form-alert"
            src="../../../../assets/images/form_alert.png"
          />
          <img
            *ngIf="form.get('province').valid"
            class="form-alert"
            src="../../../../assets/images/form_tick.png"
          /> -->
        </div>
        <!-- <app-state-list
          class="width-75"
          (selectedState)="stateSelect($event)"
          [states]="states"
        ></app-state-list> -->
      </div>
      <!-- <div *ngIf="province == undefined && clickBtn" class="error-msg">
        Please enter State/ Province/Region
      </div> -->
      <div class="form-1 d-flex" style="margin-top: 32px">
        <div id="city" class="form-font width-25">City</div>
        <div style="position: relative" class="d-flex width-75">
          <app-input
            style="width: 100%"
            [control]="form.get('city')"
            [hint]="'City'"
            [class]="cityClass"
            [startIcon]="true"
            [registerInfo]="false"
          ></app-input>
          <img
            *ngIf="form.get('city').errors && clickBtn"
            class="form-alert"
            src="../../../../assets/images/form_alert.png"
          />
          <img
            *ngIf="form.get('city').valid"
            class="form-alert"
            src="../../../../assets/images/form_tick.png"
          />
        </div>
      </div>
      <div *ngIf="form.get('city').errors && clickBtn" class="error-msg">
        Please enter City
      </div>
      <div class="form-1 d-flex" style="margin-top: 32px">
        <div id="zip" class="form-font width-25">Zip/ Postal Code</div>
        <div style="position: relative" class="d-flex width-75">
          <app-input
            style="width: 100%"
            [control]="form.get('zip')"
            [hint]="'Zip/Postal Code'"
            [class]="zipClass"
            [startIcon]="true"
            [registerInfo]="false"
            [numberOnly]="true"
          ></app-input>
          <img
            *ngIf="form.get('zip').errors && clickBtn"
            class="form-alert"
            src="../../../../assets/images/form_alert.png"
          />
          <img
            *ngIf="form.get('zip').valid"
            class="form-alert"
            src="../../../../assets/images/form_tick.png"
          />
        </div>
      </div>
      <div *ngIf="form.get('zip').errors && clickBtn" class="error-msg">
        Please enter Zip/Postal Code
      </div>
      <div class="form-1 d-flex" style="margin-top: 32px">
        <div id="mobile" class="form-font width-25">Mobile</div>
        <div class="d-flex width-75">
          <app-input
            class="plus-width"
            [control]="form.get('plus')"
            [hint]="'+1'"
            [class]="plusClass"
            [startIcon]="true"
            [registerInfo]="false"
            [numberOnly]="true"
          ></app-input>
          <div
            style="position: relative; margin-left: 24px"
            class="d-flex mobile-width"
          >
            <app-input
              style="width: 100%"
              [control]="form.get('mobile')"
              [hint]="'Mobile'"
              [class]="mobileClass"
              [startIcon]="true"
              [registerInfo]="false"
              [numberOnly]="true"
            ></app-input>
            <img
              *ngIf="form.get('mobile').errors && clickBtn"
              class="form-alert"
              src="../../../../assets/images/form_alert.png"
            />
            <img
              *ngIf="form.get('mobile').valid"
              class="form-alert"
              src="../../../../assets/images/form_tick.png"
            />
          </div>
        </div>
      </div>
      <div *ngIf="form.get('mobile').errors && clickBtn" class="error-msg">
        Please enter Mobile Number
      </div>

      <div class="stripe-container">
        <img class="stripe" src="../../../../assets/images/stripe.svg" />
        <div style="margin-top: 20px; margin-bottom: 20px">
          After clicking the "Submit" button below, you will be directed to
          Stripe’s website. Once you have entered your payment information, you
          will be automatically re-directed back to STEMWerkz.
        </div>
        <div class="lock-div d-flex align-items-center">
          <img class="lock" src="../../../../assets/images/Lock-icon.png" />
          <div>Your information is safe and protected.</div>
        </div>
      </div>
      <div
        id="agreeCb"
        class="d-flex flex-column cb-container under-line cursor"
        (click)="toggleCb()"
      >
        <div class="d-flex">
          <div style="width: 7%; position: relative">
            <input
              #agreeCb
              class="cb cursor"
              style="position: absolute; opacity: 0"
              [checked]="agree"
              type="checkbox"
            />
            <div
              class="cb checkmark"
              [ngClass]="{ 'red-cb': !agree && clickBtn }"
            ></div>
          </div>
          <div class="cb-text" style="width: 93%">
            My STEMWerkz Solo subscription will renew automatically at
            <span *ngIf="!discountValid"
              >{{ addCurrencySymbol(monthlyPrice) }} </span
            ><span *ngIf="discountValid">{{
              addCurrencySymbol(calculatePrice())
            }}</span>
            <span *ngIf="intervalType == 'monthly'">monthly</span
            ><span *ngIf="intervalType == 'yearly'">yearly</span> from
            {{ dueDate | date: "dd LLL yyyy" }} and until cancelled in the
            Parent Portal.
          </div>
        </div>
        <div
          *ngIf="!agree && clickBtn"
          class="error-msg"
          style="margin-left: 15% !important"
        >
          Please check the box to indicate that you agree to these terms.
        </div>
      </div>
      <div class="term">
        By clicking Submit below, you agree to our
        <span class="pink">
          <a [href]="tos" target="_blank">Terms & Conditions</a></span
        >
        and
        <span class="pink">
          <a [href]="pp" target="_blank">Privacy Policy.</a></span
        >
      </div>
      <div
        *ngIf="deliNote?.length > 0 && deliNote != undefined"
        class="charge-today"
      >
        <!-- Note: Charges today is inclusive of delivery charges of
        {{ addCurrencySymbol(deliFee) }}. Please make sure delivery address is
        correct. -->
        {{ deliNote }}
      </div>
      <app-button [class]="submitButton" (click)="submitBtn()"
        >Submit</app-button
      >
    </div>
  </div>
</div>
<app-query-params-ls></app-query-params-ls>
<!-- <app-video
  *ngIf="showVideo"
  [src]="videoUrl"
  [type]="videoType"
  (videoEndNow)="redirectStemWerkz()"
></app-video> -->

<!-- <div *ngIf="showVideo" class="vid-container">
  <video
    #video
    width="100%"
    height="auto"
    autoplay
    (ended)="redirectStemWerkz()"
    (canplay)="video.play()"
    (loadedmetadata)="video.muted = true"
  >
    <source [src]="videoUrl" [type]="videoType" />
  </video>
</div> -->
