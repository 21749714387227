<app-howtoclaim
  class="coverall"
  (cancelBtn)="cancelBtnClicked($event)"
  *ngIf="popupFlag"
></app-howtoclaim>
<div class="wrapper">
  <app-moving-bg></app-moving-bg>
  <div style="position: relative; z-index: 99; height: 100%">
    <div class="foreground" *ngIf="!isBoarding">
      <div
        class="backwrapper back cursor"
        (click)="isBoarding = true; codeFlag = false"
      >
        <app-image [src]="arrowUrl"></app-image>
      </div>
    </div>
    <div class="d-flex flex-column align-items-center pt-40">
      <!-- <app-image [ngStyle]="{ 'width' : mobileView ? '175px' : '' }" [src]="logoUrl"></app-image> -->

      <app-logo [class]="'middle'"></app-logo>
      <ng-container *ngIf="!apiLoading && !codeFlag">
        <app-choice-button
          *ngIf="isBoarding"
          class="mt-10p"
          style="max-width: 680px"
          (trialBtn)="trialClicked($event)"
          (claimBtn)="claimClick($event)"
          (clever)="cleverClick($event)"
          [locale]="locale"
        ></app-choice-button>
        <app-content-code
          *ngIf="!isBoarding"
          class="mt-5p"
          [loading]="codeLoading"
          [locale]="locale"
          (submitClicked)="claimCodeClick($event)"
        ></app-content-code>
        <app-text
          [ngClass]="!mobileView ? 'normal-text' : 'mobile-text'"
          class="text-c100 mt-5p"
        >
          <span *ngFor="let val of locale?.mainPage.alreadyHaveAccount">
            {{ val == "login" ? "" : val }}
            <br *ngIf="mobileView" />
            <a *ngIf="val == 'login'" (click)="openPopup()" class="cursor">{{
              locale.mainPage[val]
            }}</a>
          </span>
        </app-text>
      </ng-container>
      <ng-container *ngIf="apiLoading || codeFlag">
        <app-failcode
          (loginBtn)="openPopup()"
          [locale]="locale"
          [loading]="codeLoading"
          (trialBtn)="trialClicked($event)"
          [codeNo]="code"
          [errorMsg]="codeError"
          (submitClicked)="claimCodeClick($event)"
        ></app-failcode>
      </ng-container>
    </div>
    <!-- <div class="terms" [ngClass]="!mobileView ? 'pos-ab' : ''"> -->
    <div class="terms">
      <span *ngFor="let terms of locale?.mainPage.policy">
        {{ terms == "tos" || terms == "pripolicy" ? "" : terms }}
        <a
          style="color: #3394ab"
          [href]="tos"
          target="_blank"
          *ngIf="terms == 'tos'"
          >{{ locale.mainPage[terms] }}</a
        >
        <a
          style="color: #3394ab"
          [href]="pp"
          target="_blank"
          *ngIf="terms == 'pripolicy'"
          >{{ locale.mainPage[terms] }}</a
        >
      </span>
    </div>
  </div>
</div>

<app-query-params-ls></app-query-params-ls>
