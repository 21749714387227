import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { locale_short_code } from '../../locale/locale-code';

@Injectable({
  providedIn: 'root',
})
export class LocaleService {
  public locale;
  public localeJson;
  public localeCodeList;

  constructor(private http: HttpClient, private router: Router) {
    this.localeCodeList = Object.keys(locale_short_code);

    this.localeCodeList = this.localeCodeList.map((data) =>
      // data.replaceAll('_', '-')
      data.replace(/\_/g, '-')
    );
  }

  getLocaleName() {
    // let localeLs = localStorage.getItem('locale');
    let localeLs = environment.locale;
    console.log('get locale code list', this.localeCodeList);
    console.log('localeLs', localeLs);
    // let locale =
    //   localeLs != undefined && this.localeCodeList.indexOf(localeLs) != -1
    //     ? localeLs.toLocaleLowerCase()
    //     : 'en-US'.toLocaleLowerCase();
    this.localeJson = 'locale/locale_' + localeLs + '.json';
    return this.localeJson;
  }

  getJson() {
    let localeName = this.getLocaleName();
    console.log('get locale name', localeName);
    return this.http.get(localeName).pipe(
      map((res: Response) => {
        let locale = {};
        locale = res[0];
        console.log('locale', locale);
        return locale;
      }),
      catchError((error) => {
        return this.getDefaultJson('locale/locale_en-us.json');
      })
    );
  }

  getDefaultJson(locale) {
    return this.http.get(locale).pipe(
      map((res: Response) => {
        let locale = {};
        locale = res[0];
        console.log('getDefaultJson', locale);
        return locale;
      })
    );
  }
}
