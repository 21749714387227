import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LogoListStore {

}

export enum LogoListStoreKeys {

}
