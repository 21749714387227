import {
  Component,
  OnInit,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  Output,
} from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-info-two',
  templateUrl: './info-two.component.html',
  styleUrls: ['./info-two.component.css'],
})
export class InfoTwoComponent implements OnInit {
  @Input() locale: any;
  @Output() continueBtn = new EventEmitter<any>();
  constructor() {}
  public infoTwo: any = {
    birthYear: '',
    birthMonth: '',
    gender: '',
  };
  public filledAllData = false;
  public yearsold = null;
  public monthold = null;
  ngOnInit(): void {}
  continue() {
    if (this.filledAllData) this.continueBtn.emit(this.infoTwo);
  }
  chooseGender(data) {
    this.infoTwo.gender = data;
    this.validateFilledData();
  }
  selectedMonth(e) {
    this.infoTwo.birthMonth = e;
    this.validateFilledData();
  }
  selectedYear(e) {
    this.infoTwo.birthYear = e;
    // this.yearsold = new Date().getFullYear() - Number(e);
    this.validateFilledData();
  }

  validateFilledData() {
    if (
      this.infoTwo.gender &&
      this.infoTwo.birthMonth &&
      this.infoTwo.birthYear
    ) {
      this.filledAllData = true;
      this.calculateMonth();
      //calculate month
    } else if (this.infoTwo.birthMonth && this.infoTwo.birthYear) {
      this.calculateMonth();
    } else {
      this.filledAllData = false;
    }
  }

  calculateMonth() {
    this.monthold = null;
    var currentDate = new Date();
    var formatCurrentDate = [
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1,
    ];
    var givenDate = [
      this.infoTwo.birthYear,
      this.formatMonth(this.infoTwo.birthMonth),
      1,
    ];
    var totalMonth = moment(formatCurrentDate).diff(
      moment(givenDate),
      'months',
      true
    );
    this.yearsold = Math.floor(totalMonth / 12);
    this.monthold = totalMonth - this.yearsold * 12;
    // console.log('totalMonth ', totalMonth);
  }
  formatMonth(month) {
    var monthList: any = [
      '1月',
      '2月',
      '3月',
      '4月',
      '5月',
      '6月',
      '7月',
      '8月',
      '9月',
      '10月',
      '11月',
      '12月',
    ];
    return monthList.indexOf(month);
  }
  @HostListener('document:keypress', ['$event'])
  onDown(e) {
    if (this.filledAllData && e.keyCode == 13) {
      this.continue();
    }
  }
}
