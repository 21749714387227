import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalComponentsModule } from '../../../global-components/global-components.module';
import { SharedComponentsModule } from '../shared-components/shared-components.module';
import { MonthlySignupControllerComponent } from './monthly-signup-controller.component';
// import {FeatherModule} from 'angular-feather';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [MonthlySignupControllerComponent],
  exports: [MonthlySignupControllerComponent],
  imports: [
    CommonModule,
    RouterModule,
    GlobalComponentsModule,
    SharedComponentsModule,
  ],
})
export class MonthlySignupModule {}
