export class BundleModel {
  dashboardMediumImageUrl : string
  gradeText : string
  levelBackground : any
  maxAge : number
  minAge : number
  nameImageUrl : string
  topicCount : number
  uniqueId : string
}
