import { FormBuilder, Validators } from '@angular/forms';
import {
  Component,
  OnInit,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  Output,
} from '@angular/core';
import { environment } from 'src/environments/environment';
import { SharedService } from 'src/app/lib/shared-service';

@Component({
  selector: 'app-login-start',
  templateUrl: './login-start.component.html',
  styleUrls: ['./login-start.component.css'],
})
export class LoginStartComponent implements OnInit {
  public usernameClass = 'login-form';
  public pwClass = 'login-form';
  public togglePassword = 'password';
  public btnFlag: boolean = false;
  public mobileView;
  public mobileViewService;
  public showToggle: boolean = false;

  @Input() locale: any;
  @Input() loading: any;
  @Output() cancelBtn = new EventEmitter<any>();
  @Output() loginEvent = new EventEmitter<any>();

  constructor(private fb: FormBuilder, private shared: SharedService) {}

  ngOnInit(): void {
    this.mobileViewService = this.shared.GetMobileView.subscribe((data) => {
      this.mobileView = data;
      console.log('mobo test', this.mobileView);
    });

    console.log('loading state', this.loading);
    this.form.valueChanges.subscribe((value) => {
      this.btnFlag = false;
      this.usernameClass = 'login-form';
      this.pwClass = 'login-form';
    });
  }
  public form = this.fb.group({
    username: this.fb.control('', [Validators.required]),
    password: this.fb.control('', [
      Validators.required,
      // Validators.minLength(8),
    ]),
  });

  cancelClick() {
    this.cancelBtn.emit();
  }
  LoginClick() {
    this.btnFlag = true;
    if (this.form.valid) {
      var data = {
        username: this.form.get('username').value.trim(),
        password: this.form.get('password').value.trim(),
      };
      this.loginEvent.emit(data);
    } else {
      if (this.form.get('username').errors)
        this.usernameClass = 'loginClass-error';
      if (this.form.get('password').errors) this.pwClass = 'loginClass-error';
    }
  }
  forgetLink() {
    return environment.membershipUrl + 'forgot-password';
  }
  pwToggle() {
    this.showToggle = !this.showToggle;
    if (this.showToggle) {
      this.togglePassword = 'text';
    } else {
      this.togglePassword = 'password';
    }
  }
}
