import { Component, Input, Output, EventEmitter } from '@angular/core';
import { PreviewStore } from './preview.store';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.css'],
})
export class PreviewComponent {
  constructor(private store: PreviewStore) {}
  // @Input() previewList = ["./assets/images/image 21.png"];
  @Input() previewList = [
    './assets/images/image 21.png',
    './assets/images/image 22.png',
    './assets/images/image 21.png',
  ];
  @Input() subInfo;
  @Input() locale;

  @Output() changeBackground = new EventEmitter<any>();

  clicked(index) {
    this.changeBackground.emit(index);
  }
}
