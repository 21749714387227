import { Inject, Component } from '@angular/core';
import {
  BusinessLogicRequirements,
  BusinessRequirementsInjectionToken,
} from '../business-logic.requirements';
import { ParentInfoStore } from './parent-info.store';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/lib/auth.service';
import { environment } from 'src/environments/environment';
import { LocaleService } from 'src/app/lib/locale.service';

@Component({
  selector: 'app-parent-info',
  templateUrl: './parent-info-controller.component.html',
  styleUrls: ['./parent-info-controller.component.css'],
})
export class ParentInfoControllerComponent {
  constructor(
    @Inject(BusinessRequirementsInjectionToken)
    private business: BusinessLogicRequirements,
    private store: ParentInfoStore,
    private router: Router,
    private _authService: AuthService,
    private _localeService: LocaleService
  ) {}
  ngOnInit() {
    if (
      !localStorage.getItem('userInfo') &&
      !localStorage.getItem('dashboardRedir')
    ) {
      this.router.navigateByUrl('');
    }
    this.getCountryList();
    this.getLocaleJson();
  }
  public Bundle = true;
  public step: any = 'two';
  public countryList: any;
  public feedbackTitle = '';
  public feedbackBody = '';
  public feedbackBtn = {
    status: false,
    text: '',
  };
  public tickIcon = true;
  public showFeedback = false;
  public locale: any;
  public codeLoading: boolean = false;

  continueClicked(e) {
    console.log('data is ', e);

    this.step = 'two';
  }
  getLocaleJson() {
    this._localeService.getJson().subscribe((data) => {
      console.log('locale in home', data);
      this.locale = data;
    });
  }
  public parentInfo;
  public phonecode;
  public parentLoading: boolean = false;
  saveClicked(e) {
    this.parentLoading = true;
    console.log('infotwo is', e);
    this.parentInfo = e;
    //api call
    var userInfo = this._authService.decrypt(
      localStorage.getItem('userInfo'),
      environment.secretkey
    );
    userInfo = JSON.parse(userInfo);
    console.log('user Info', userInfo);

    var header = {
      apikey: userInfo.apikey || userInfo.ApiKey,
      username: userInfo.username,
      password: userInfo.password || userInfo.Token,
      childOrParent: 'parent',
      userId: userInfo.parentId,
      relation: this.parentInfo.parent,
      phonecode: this.phonecode,
      phoneNumebr: this.parentInfo.phoneNo,
      email: this.parentInfo.email,
    };
    this.business.updateUserDetail(header).subscribe(
      (data) => {
        this.parentLoading = false;
        console.log('data', data);
        if (localStorage.getItem('bothInfo') == 'true') {
          //need both info
          this.step = 'success';
          setTimeout(() => {
            this.router.navigateByUrl('info/child'); //go to child info
          }, 3000);
        } else {
          // doesnt need both info
          if (localStorage.getItem('noSub') == 'true') {
            // checking bundle
            var header2 = {
              apikey: userInfo.apikey || userInfo.ApiKey,
              username: userInfo.username,
              password: userInfo.password || userInfo.Token,
              userId: userInfo.userid || userInfo.userId,
            };
            this.business.checkUserGrade(header2).subscribe(
              (res) => {
                console.log('res', res);
                // if bundle then go dashboard
                if (res.bundledGrades.length != 0) {
                  this.step = 'success';
                  setTimeout(() => {
                    window.location.href =
                      localStorage.getItem('dashboardRedir');
                  }, 3000);
                }
                // if no bundle then open claim box
                else {
                  this.step = 'success';
                  setTimeout(() => {
                    this.Bundle = false;
                  }, 3000);
                }
              },
              // check bundle error
              (err) => {
                console.log(err);
                console.log(err.message);
                this.feedbackTitle = err.error.message || err.message;
                this.feedbackBody = '';
                this.feedbackBtn = {
                  status: false,
                  text: '',
                };
                this.tickIcon = false;
                this.showFeedback = true;
                setTimeout(() => {
                  this.showFeedback = false;
                }, 3000);
              }
            );
          } else {
            this.step = 'success';
            setTimeout(() => {
              window.location.href = localStorage.getItem('dashboardRedir');
            }, 3000);
          }
        }
      },
      (err) => {
        this.parentLoading = false;
        console.log(err);
        console.log(err.message);
        this.feedbackTitle = err.error.message || err.message;
        this.feedbackBody = '';
        this.feedbackBtn = {
          status: false,
          text: '',
        };
        this.tickIcon = false;
        this.showFeedback = true;
        setTimeout(() => {
          this.showFeedback = false;
        }, 3000);
      }
    );
  }
  getCountryList() {
    this.business.getCountryList().subscribe(
      (data) => {
        console.log('country list', data);

        this.countryList = data;
      },
      (err) => {
        console.log(err);
        console.log(err.message);
        this.feedbackTitle = err.error.message || err.message;
        this.feedbackBody = '';
        this.feedbackBtn = {
          status: false,
          text: '',
        };
        this.tickIcon = false;
        this.showFeedback = true;
        setTimeout(() => {
          this.showFeedback = false;
        }, 3000);
      }
    );
  }
  backButton() {
    switch (this.step) {
      case 'two':
        this.router.navigateByUrl('');
        break;
      // case 'one':
      //   this.router.navigateByUrl('');
      //   break;

      default:
        this.router.navigateByUrl('');
        break;
    }
  }
  pickedCountry(e) {
    console.log('country picked', e);
    this.phonecode = e.phonecode;
  }
  callClaimBundle(bundleCode) {
    this.codeLoading = true;
    var temp = this._authService.decrypt(
      localStorage.getItem('userInfo'),
      environment.secretkey
    );
    var userInfo = JSON.parse(temp);
    console.log('whats in JSON', userInfo);
    var encryCode = btoa(bundleCode);
    var header = {
      apikey: userInfo.apikey || userInfo.ApiKey,
      username: userInfo.username,
      password: userInfo.password || userInfo.Token,
      user: userInfo.childId,
      code: encryCode,
    };
    this.business.claimBundle(header).subscribe(
      (data) => {
        this.codeLoading = false;
        console.log(data);
        localStorage.setItem(
          'bundleInfo',
          this._authService.encrypt(JSON.stringify(data), null)
        );
        this.router.navigate(['/code/successful']);
        // this.dashboardRedir = this.authService.getRedirectDashboardUrl(header);
        // this.redirectStemWerkz();
      },
      (err) => {
        this.codeLoading = false;
        console.log(err.message);
        this.feedbackTitle = err.error.message || err.message;
        if (err.error.message == 'Invalid code provided.') {
          this.feedbackTitle = this.locale?.login.invalid;
        }
        this.feedbackBody = '';
        this.feedbackBtn = {
          status: false,
          text: '',
        };
        this.tickIcon = false;
        this.showFeedback = true;
        setTimeout(() => {
          this.showFeedback = false;
        }, 3000);
      }
    );
  }
}
