import { Inject, Component } from '@angular/core';
import {
  BusinessLogicRequirements,
  BusinessRequirementsInjectionToken,
} from '../business-logic.requirements';
import { OnboardingCnStore } from './onboarding-cn.store';
import { Router, ActivatedRoute } from '@angular/router';
import { SharedService } from 'src/app/lib/shared-service';
import { AuthService } from 'src/app/lib/auth.service';
import { environment } from 'src/environments/environment';
import { param } from 'jquery';
import { LocaleService } from 'src/app/lib/locale.service';
import { AppleLoginService } from 'src/app/lib/apple-login.service';

@Component({
  selector: 'app-onboarding-cn',
  templateUrl: './onboarding-cn-controller.component.html',
  styleUrls: ['./onboarding-cn-controller.component.css'],
})
export class OnboardingCnControllerComponent {
  constructor(
    @Inject(BusinessRequirementsInjectionToken)
    private business: BusinessLogicRequirements,
    private store: OnboardingCnStore,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private shared: SharedService,
    private authService: AuthService,
    private _localeService: LocaleService,
    private _authService: AuthService,
    private appleService: AppleLoginService
  ) {
    this.code = this.activatedRoute.snapshot.paramMap.get('id');
  }
  getLocaleJson() {
    this._localeService.getJson().subscribe((data) => {
      console.log('locale in home', data);
      this.locale = data;
    });
  }

  public logoUrl = '../../../../assets/images/STEMWerkzLogo.svg';
  public plusUrl = '../../../../assets/images/plus.svg';
  public polygonUrl = '../../../../assets/images/polygon.svg';
  public arrowUrl = '../../../../assets/images/arrow.svg';
  public popupFlag: boolean = false;
  public locale: any;
  public isBoarding = true;
  public code;
  public refer;
  public contentCode;
  public apiLoading = false;
  public codeError: any = '';
  public codeFlag: boolean = false;

  public mobileView;
  public mobileViewService;
  // public tos = environment.termsOfService;
  // public pp = environment.privacyPolicy;
  public tos;
  public pp;
  public codeLoading: boolean = false;
  public logInLoading: boolean = false;
  public origin;

  public questId;
  public skill;
  public isLogin;
  public Bundle = true;
  public logoList = [];
  public previewList = [];
  public grades;
  public dashboardRedir = '';
  public feedbackTitle = '';
  public feedbackBody = '';
  public feedbackBtn = {
    status: false,
    text: '',
  };
  public tickIcon = true;
  public showFeedback = false;

  ngOnInit() {
    this.getLocaleJson();
    this.getCountryList();
    setTimeout(() => {
      this.origin = this.shared.getOrigin();
      this.goOrigin();
    }, 1000);
    this.activatedRoute.queryParams.subscribe((params) => {
      console.log(params);
      this.questId = params.questID;
      this.skill = params.skill;
      if (this.questId && this.skill) {
        this.isBoarding = false;
        this.shared.setParamsForSkill(this.questId, this.skill);
      }
    });
    // localStorage.removeItem('bundleInfo');
    // localStorage.removeItem('userInfo');
    // localStorage.removeItem('state');
    // localStorage.removeItem('contentCode');
    // localStorage.removeItem('sessionId');
    // localStorage.removeItem('dashboardRedir');
    // localStorage.removeItem('bothInfo');
    // localStorage.removeItem('noSub');
    if (localStorage.getItem('oam-sessionId')) {
      var sessionId = localStorage.getItem('oam-sessionId');
    }
    localStorage.clear();
    localStorage.setItem('oam-sessionId', sessionId);
    // if (this.code) {
    //   this.apiLoading = true;
    //   this.claimCodeClick(this.code);
    // }
    this.mobileViewService = this.shared.GetMobileView.subscribe((data) => {
      this.mobileView = data;
    });
  }

  ngAfterViewInit() {}

  goOrigin() {
    console.log(this.origin);
    switch (this.origin) {
      case 'us':
        this.tos = environment.termsOfService;
        this.pp = environment.privacyPolicy;
        console.log('us');
        break;
      case 'asia':
        this.tos = environment.asia_termsOfService;
        this.pp = environment.asia_privacyPolicy;
        console.log('asia');
        break;
      default:
        this.checkChina();
        break;
    }
  }

  checkChina() {
    if (environment.isChina) {
      this.tos = environment.china_termsOfService;
      this.pp = environment.china_privacyPolicy;
      console.log('china');
    } else {
      this.tos = environment.termsOfService;
      this.pp = environment.privacyPolicy;
      console.log('none');
    }
  }

  trialClicked(e) {
    // if (this.refer) this.router.navigate(['/trial/signup']);
    // else
    this.router.navigate(['/trial/signup']);
  }

  claimClick(e) {
    console.log('you want to claim?');
    this.isBoarding = false;
  }

  openPopup() {
    this.popupFlag = true;
  }

  cancelBtnClicked($event) {
    this.popupFlag = false;
  }

  claimCodeClick(bundleCode) {
    this.codeLoading = true;
    this.code = bundleCode;
    var encryCode = btoa(bundleCode);
    this.business.checkBundle(encryCode).subscribe(
      (data) => {
        this.codeLoading = false;
        // this.shared.setBundleData(data[0]);
        // this.authService.setBundleInfo(data[0]);
        localStorage.setItem(
          'bundleInfo',
          this.authService.encrypt(JSON.stringify(data), null)
        );
        this.apiLoading = false;
        console.log(this.refer && this.contentCode);
        localStorage.setItem('contentCode', btoa(this.code));
        this.router.navigate(['/code/signup']);
      },
      (err) => {
        this.codeLoading = false;
        console.log(err);
        this.codeError = err.status;
        this.codeFlag = true;
      }
    );
  }
  loginToggle() {
    this.isLogin = !this.isLogin;
  }
  public userName;
  // login step1
  loginClicked(data) {
    this.logInLoading = true;
    console.log('login clicked', data);
    //login called
    this.business.loginPlayer(data.username, data.password).subscribe(
      (res) => {
        this.feedbackTitle = this.locale?.login.loggedIn;
        this.feedbackBody = '';
        this.feedbackBtn = {
          status: false,
          text: '',
        };
        this.showFeedback = true;
        this.tickIcon = true;
        setTimeout(() => {
          this.showFeedback = false;
        }, 3000);
        console.log('res', res[0]);
        let userInfo = this._authService.encrypt(JSON.stringify(res[0]), null);
        localStorage.setItem('userInfo', userInfo);
        var header = {
          apikey: res[0].apikey,
          username: data.username,
          user: res[0].userid,
          password: res[0].password,
          playerid: res[0].playerid,
        };
        this.dashboardRedir = this.authService.getRedirectDashboardUrl(header);
        localStorage.setItem('dashboardRedir', this.dashboardRedir);
        console.log('head test', header);
        if (res[0].usertype == 3) {
          var header2 = {
            apikey: res[0].apikey,
            username: data.username,
            user: res[0].userid,
            password: res[0].password,
            childId: res[0].childId,
            parentId: res[0].parentId,
          };
          this.business.checkUserInfo(header2).subscribe(
            (res) => {
              console.log('info detail', res);
              if (res.skipChildInfo == true && res.skipParentInfo == true) {
                this.redirectStemWerkz(); // if sub then go flow
              }
              if (res.skipChildInfo == false && res.skipParentInfo == false) {
                localStorage.setItem('bothInfo', 'true');
                this.router.navigateByUrl('info/parent');
              }
              if (res.skipChildInfo == false && res.skipParentInfo == true) {
                this.router.navigateByUrl('info/child');
              }
              if (res.skipChildInfo == true && res.skipParentInfo == false) {
                this.router.navigateByUrl('info/parent');
              }
            },
            //check bundle api error
            (err) => {}
          );
        } else if (res[0].usertype == 5) {
          this.redirectStemWerkz();
        }
      },
      // login error
      (err) => {
        localStorage.setItem('noSub', 'true');
        this.logInLoading = false;
        console.log(err);
        this.feedbackTitle =
          err.error.message == null
            ? err.error.error.message
            : err.error.message;
        this.tickIcon = false;
        if (err.error.message == 'User does not exist') {
          this.feedbackTitle = this.locale?.login.notexist;
        } else if (err.error.message == 'Password Mismatch') {
          this.feedbackTitle = this.locale?.login.mismatch;
        } else if (err.error.error?.code == 'subscriptionExpiryError') {
          this.feedbackTitle = this.locale?.login.loggedIn;
          this.tickIcon = true;
        }
        this.feedbackBody = '';
        this.feedbackBtn = {
          status: false,
          text: '',
        };
        this.showFeedback = true;
        setTimeout(() => {
          this.showFeedback = false;
        }, 3000);
        // if no sub then check bundle again
        if (err.error.error?.code == 'subscriptionExpiryError') {
          let userInfo = this._authService.encrypt(
            JSON.stringify(err.error),
            null
          );
          localStorage.setItem('userInfo', userInfo);

          var header = {
            apikey: err.error.ApiKey,
            username: err.error.username,
            user: err.error.userId,
            password: err.error.Token,
            playerid: err.error.idPlayer
              ? err.error.idPlayer
              : err.error.idUser,
          };
          this.dashboardRedir =
            this.authService.getRedirectDashboardUrl(header);
          localStorage.setItem('dashboardRedir', this.dashboardRedir);
          if (err.error.UserType == 3) {
            var header2 = {
              apikey: err.error.ApiKey,
              username: err.error.username,
              user: err.error.userId,
              password: err.error.Token,
              childId: err.error.childId,
              parentId: err.error.parentId,
            };
            this.business.checkUserInfo(header2).subscribe(
              (res) => {
                console.log('info detail', res);
                if (res.skipChildInfo == true && res.skipParentInfo == true) {
                  // checking bundle
                  this.business.checkUserGrade(header).subscribe(
                    (res) => {
                      console.log('res', res);
                      // if bundle then go dashboard
                      if (res.bundledGrades.length != 0) {
                        this.redirectStemWerkz();
                      }
                      // if no bundle then open claim box
                      else {
                        this.Bundle = false;
                      }
                    },
                    // check bundle error
                    (err) => {
                      console.log(err);
                      console.log(err.message);
                      this.feedbackTitle = err.error.message;
                      this.feedbackBody = '';
                      this.feedbackBtn = {
                        status: false,
                        text: '',
                      };
                      this.tickIcon = false;
                      this.showFeedback = true;
                      setTimeout(() => {
                        this.showFeedback = false;
                        // this.Bundle = false;
                        this.router.navigate(['']);
                      }, 3000);
                    }
                  );
                }
                if (res.skipChildInfo == false && res.skipParentInfo == false) {
                  localStorage.setItem('bothInfo', 'true');
                  this.router.navigateByUrl('info/parent');
                }
                if (res.skipChildInfo == false && res.skipParentInfo == true) {
                  this.router.navigateByUrl('info/child');
                }
                if (res.skipChildInfo == true && res.skipParentInfo == false) {
                  this.router.navigateByUrl('info/parent');
                }
              },
              //check bundle api error
              (err) => {}
            );
          } else if (err.error.UserType == 5) {
            // checking bundle
            this.business.checkUserGrade(header).subscribe(
              (res) => {
                console.log('res', res);
                // if bundle then go dashboard
                if (res.bundledGrades.length != 0) {
                  this.redirectStemWerkz();
                }
                // if no bundle then open claim box
                else {
                  this.Bundle = false;
                }
              },
              // check bundle error
              (err) => {
                console.log(err);
                console.log(err.message);
                this.feedbackTitle = err.error.message;
                this.feedbackBody = '';
                this.feedbackBtn = {
                  status: false,
                  text: '',
                };
                this.tickIcon = false;
                this.showFeedback = true;
                setTimeout(() => {
                  this.showFeedback = false;
                  // this.Bundle = false;
                  this.router.navigate(['']);
                }, 3000);
              }
            );
          }
        }
      }
    );
  }
  gotoDashboard() {
    window.location.href = environment.dashboardurl;
  }

  callClaimBundle(bundleCode) {
    this.codeLoading = true;
    var temp = this._authService.decrypt(
      localStorage.getItem('userInfo'),
      environment.secretkey
    );
    var data = JSON.parse(temp);
    console.log('whats in JSON', data);
    var encryCode = btoa(bundleCode);
    var header = {
      username: this.userName == undefined ? data.username : this.userName,
      password: data.password == undefined ? data.Token : data.password,
      user: data.userid == undefined ? data.userId : data.userid,
      apikey: data.apikey == undefined ? data.ApiKey : data.apikey,
      code: encryCode,
      playerid: data.idPlayer ? data.idPlayer : data.idUser,
    };
    this.business.claimBundle(header).subscribe(
      (data) => {
        this.codeLoading = false;
        console.log(data);
        localStorage.setItem(
          'bundleInfo',
          this.authService.encrypt(JSON.stringify(data), null)
        );
        this.router.navigate(['/code/successful']);
        // this.dashboardRedir = this.authService.getRedirectDashboardUrl(header);
        // this.redirectStemWerkz();
      },
      (err) => {
        this.codeLoading = false;
        console.log(err.message);
        this.feedbackTitle = err.error.message;
        if (err.error.message == 'Invalid code provided.') {
          this.feedbackTitle = this.locale?.login.invalid;
        }
        this.feedbackBody = '';
        this.feedbackBtn = {
          status: false,
          text: '',
        };
        this.tickIcon = false;
        this.showFeedback = true;
        setTimeout(() => {
          this.showFeedback = false;
        }, 3000);
      }
    );
  }

  redirectStemWerkz() {
    window.location.href = this.dashboardRedir;
  }
  wechatClicked() {
    var redir = window.location.href + 'wechat/login';
    var url =
      'https://open.weixin.qq.com/connect/qrconnect?appid=wxbccf4a9b85a7aae9&redirect_uri=' +
      encodeURIComponent(redir) +
      '&response_type=code&scope=snsapi_login&state=STATE#wechat_redirect';
    window.location.href = url;
  }
  public isPhoneLogin: boolean = false;
  phoneClicked() {
    this.isPhoneLogin = true;
  }

  backHome() {
    this.Bundle = true;
    this.isBoarding = true;
  }
  public countryList: any;
  getCountryList() {
    this.business.getCountryList().subscribe(
      (data) => {
        console.log('country list', data);

        this.countryList = data;
      },
      (err) => {}
    );
  }
  appleClicked($event) {
    // this.appleService.signIn();
    this.router.navigate(['/apple/redirect']);
  }
}
