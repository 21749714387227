<app-moving-bg></app-moving-bg>
<div class="back-div" (click)="backButton()">
  <img src="./assets/images/arrow.svg" />
</div>
<div class="d-flex flex-column justify-content-center align-items-center">
  <app-logo [class]="'middle'"></app-logo>
  <app-info-one
    [locale]="locale"
    *ngIf="step == 'one' && Bundle"
    (continueBtn)="continueClickedOne($event)"
  ></app-info-one>
  <app-info-two
    [locale]="locale"
    (continueBtn)="continueClickedTwo($event)"
    *ngIf="step == 'two' && Bundle"
  ></app-info-two>
  <app-info-three
    [locale]="locale"
    [loading]="childLoading"
    (countryOutput)="countrySelected($event)"
    (stateOutput)="stateSelected($event)"
    [countries]="countryList"
    [states]="stateList"
    [cities]="cityList"
    (saveBtn)="saveClicked($event)"
    *ngIf="step == 'three' && Bundle"
  ></app-info-three>
  <app-content-code
    *ngIf="!Bundle"
    class="mt-5p"
    [loading]="codeLoading"
    [locale]="locale"
    (submitClicked)="callClaimBundle($event)"
  ></app-content-code>
  <app-successful-show
    [locale]="locale"
    *ngIf="step == 'success' && Bundle"
    [accountType]="'Child'"
  ></app-successful-show>
</div>
<app-custom-feedback
  *ngIf="showFeedback"
  [feedbackTitle]="feedbackTitle"
  [feedbackBody]="feedbackBody"
  [feedbackBtn]="feedbackBtn"
  [tickIcon]="tickIcon"
></app-custom-feedback>
