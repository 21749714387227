<!-- <div class="testing">
  <img src="../../../assets/background/poly1.svg" width=100px height=100px/>
</div> -->
<div class="coverall">
  <div *ngIf="!mobileView" class="container-div">
    <img class="big1" src="../../../assets/background/big3.svg" />
    <img class="big2" src="../../../assets/background/big2.svg" />
    <img class="big3" src="../../../assets/background/big1.svg" />

    <img class="poly1" src="../../../assets/background/poly1.svg"/>
    <img class="poly2" src="../../../assets/background/poly2.svg"/>
    <img class="poly3" src="../../../assets/background/poly3.svg"/>

    <img class="polybot1" src="../../../assets/background/poly1.svg"/>
    <img class="polybot2" src="../../../assets/background/poly2.svg"/>
    <img class="polybot3" src="../../../assets/background/poly3.svg"/>

    <img class="small1" src="../../../assets/background/small1.svg" />
    <img class="small2" src="../../../assets/background/small2.svg" />
    <img class="small3" src="../../../assets/background/small3.svg" />

    <img class="wpoly1" src="../../../assets/background/wpoly1.svg" />
    <img class="wpoly2" src="../../../assets/background/wpoly2.svg" />
    <img class="wpoly3" src="../../../assets/background/wpoly3.svg" />
  </div>

  <div *ngIf="mobileView" class="container-div">
    <img class="big1 w-60p" src="../../../assets/background/big3.svg" />
    <img class="big2 w-60p" src="../../../assets/background/big2.svg" />
    <img class="big3 w-60p" src="../../../assets/background/big1.svg" />
<!--
    <img class="poly1" src="../../../assets/background/poly1.svg"/>
    <img class="poly2" src="../../../assets/background/poly2.svg"/>
    <img class="poly3" src="../../../assets/background/poly3.svg"/>

    <img class="polybot1" src="../../../assets/background/poly1.svg"/>
    <img class="polybot2" src="../../../assets/background/poly2.svg"/>
    <img class="polybot3" src="../../../assets/background/poly3.svg"/> -->

    <img class="small1 w-60p" src="../../../assets/background/small1.svg" />
    <img class="small2 w-60p" src="../../../assets/background/small2.svg" />
    <img class="small3 w-60p" src="../../../assets/background/small3.svg" />
<!--
    <img class="wpoly1" src="../../../assets/background/wpoly1.svg" />
    <img class="wpoly2" src="../../../assets/background/wpoly2.svg" />
    <img class="wpoly3" src="../../../assets/background/wpoly3.svg" /> -->
  </div>
</div>
