import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/lib/shared-service';

@Component({
  selector: 'app-moving-bg',
  templateUrl: './moving-bg.component.html',
  styleUrls: ['./moving-bg.component.css']
})
export class MovingBGComponent implements OnInit {
  public mobileView;
  public mobileViewService;
  constructor(private shared: SharedService) { }

  ngOnInit(): void {
    this.mobileViewService = this.shared.GetMobileView.subscribe(data=>{
      this.mobileView = data;
      console.log("mobo test", this.mobileView);

    });
  }

}
