import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalComponentsModule } from '../../../global-components/global-components.module';
import { SharedComponentsModule } from '../shared-components/shared-components.module';
import { GlobalLoginControllerComponent } from './global-login-controller.component';
import { RouterModule } from '@angular/router';
import { LoginFormComponent } from './components/login-form/login-form.component';
import { CleverLoginComponent } from './components/clever-login/clever-login.component';
import { ClaimPageComponent } from './components/claim-page/claim-page.component';
import { ContentCodeComponent } from './components/content-code/content-code.component';
import { FailcodeComponent } from './components/failcode/failcode.component';

@NgModule({
  declarations: [
    GlobalLoginControllerComponent,
    LoginFormComponent,
    CleverLoginComponent,
    ClaimPageComponent,
    ContentCodeComponent,
    FailcodeComponent,
  ],
  exports: [GlobalLoginControllerComponent],
  imports: [
    CommonModule,
    RouterModule,
    GlobalComponentsModule,
    SharedComponentsModule,
  ],
})
export class GlobalLoginModule {}
