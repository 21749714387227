import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ChoiceButtonStore {

}

export enum ChoiceButtonStoreKeys {

}
