<div class="parent-wrap">
  <div class="parent-info">{{ locale?.childInfo.childInfo }}</div>
  <div class="mb-24">
    <div class="mb-40 normal-text">{{ locale?.childInfo.urName }}</div>
    <div class="input-div">
      <input
        [(ngModel)]="infoOne.firstName"
        (ngModelChange)="validateName()"
        [placeholder]="locale?.childInfo.fName"
        class="wechat-textbox"
      />
    </div>
  </div>
  <div class="input-div mb-32">
    <input
      [(ngModel)]="infoOne.lastName"
      (ngModelChange)="validateName()"
      [placeholder]="locale?.childInfo.lName"
      class="wechat-textbox"
    />
  </div>
  <div class="d-flex align-items-center justify-content-center">
    <app-button
      (click)="continue()"
      [class]="
        validName ? 'monthly-button-blue' : 'monthly-button-blue-disable'
      "
      [height]="'64px'"
      [width]="'300px'"
      ><span class="continue-text">{{
        locale?.childInfo.continue
      }}</span></app-button
    >
  </div>
</div>
