import {CountryModel} from '../../../models/country.model';
import {ItemMapper} from '../../../lib/item-mapper';

export class CountryMapper extends ItemMapper<CountryModel> {
  protected mapFromItem(item): CountryModel {
      const result = new CountryModel();
      result.currentCountry = item.country;
      result.countryCode = item.countryCode;
      result.countries = [];
      if(item.countries){
        item.countries.forEach(element => {
          var data = { name: element.name, dial_code: element.dial_code, code: element.code }
          result.countries.push(data)
        });
      }
      return result;
  }
}
