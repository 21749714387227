import { Inject, Injectable } from '@angular/core';
import {
  DataRequirements,
  DataRequirementsInjectionToken,
} from '../data.requirements';

@Injectable({
  providedIn: 'root',
})
export class AppleLoginUseCase {
  constructor(
    @Inject(DataRequirementsInjectionToken) private data: DataRequirements
  ) {}

  run(code) {
    return this.data.appleLogin(code);
  }
}
