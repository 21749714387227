import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-year-list',
  templateUrl: './year-list.component.html',
  styleUrls: ['./year-list.component.css'],
})
export class YearListComponent implements OnInit {
  public years: any = [];
  @Input() locale;
  @Output() pickedYear = new EventEmitter<any>();

  constructor(private fb: FormBuilder) {}
  public isScrolled: boolean = false;
  // public form = this.fb.group({
  //   name: this.fb.control(''),
  //   search: this.fb.control(''),
  // });
  public currentYear: any = 'Year';

  public searchName: any;
  public dropdownToggle: boolean = false;
  ngOnInit(): void {
    this.currentYear = this.locale?.childInfo.year;
    this.getYearList(80);
    console.log(this.years);
  }
  ngAfterViewChecked() {}
  getYearList(supportYear) {
    this.years = [];
    var currentYear = new Date().getFullYear();
    for (var i = 3; i <= supportYear; i++) {
      //support year starts from 3 years
      this.years.push({ name: (currentYear - i).toString() });
    }
  }
  @HostListener('document:click', ['$event'])
  onClick(e) {
    var target = e.target;

    if (target.closest('.country-input')) {
      try {
        // this.scrollTo();
      } catch (error) {
        console.log('closing dropdown');
      }
    } else if (
      !target.closest('.month-div') &&
      !target.closest('.search-input')
    ) {
      this.dropdownToggle = false;
    }
  }

  ngOnChanges() {}

  // scrollTo() {
  //   console.log('scroll func');

  //   var myElement = document.getElementById('MM');
  //   var topPos = myElement.offsetTop - 160;
  //   document.getElementById('scrolling_div').scrollTop = topPos;
  // }
  selectCountry(data) {
    this.currentYear = data.name;
    this.pickedYear.emit(this.currentYear);
    this.dropdownToggle = false;
  }
  dropdownClicked() {
    console.log('dropdown func');

    this.dropdownToggle = !this.dropdownToggle;
  }
}
