<app-custom-feedback
  *ngIf="showFeedback"
  [feedbackTitle]="feedbackTitle"
  [feedbackBody]="feedbackBody"
  [feedbackBtn]="feedbackBtn"
  [tickIcon]="tickIcon"
></app-custom-feedback>

<div class="outer" *ngIf="!showVideo">
  <app-howtoclaim
    class="coverall"
    (cancelBtn)="cancelBtnClicked($event)"
    *ngIf="popupFlag"
  ></app-howtoclaim>

  <app-back (output)="clickedBack($event)"></app-back>
  <app-moving-bg></app-moving-bg>
  <app-logo [class]="'middle'"></app-logo>
  <div class="wrapper">
    <!-- <div [ngClass]="{'box':!isClaim}"> -->
    <div class="box">
      <app-trialcreate
        *ngIf="isClaim"
        [locale]="locale"
        (nextOutput)="closeClaim($event)"
      ></app-trialcreate>
      <app-register-info
        [apotest]="nameError"
        [loading]="registerLoading"
        [locale]="locale"
        [usernameError]="usernameError"
        [firstName]="firstName"
        *ngIf="isRegisterInfo"
        (nextOutput)="closeRegister($event)"
        (loginOutput)="openModal($event)"
        (errorOutput)="changeError(false)"
      ></app-register-info>
      <app-additional-info
        *ngIf="isAdditionalInfo"
        [locale]="locale"
        [loading]="additionalLoading"
        [countryList]="countryList"
        [countryHint]="countryHint"
        (createAccount)="createAccount($event)"
      ></app-additional-info>
    </div>
  </div>
</div>
<app-video
  *ngIf="showVideo"
  [src]="videoUrl"
  [type]="videoType"
  (videoEndNow)="redirectStemWerkz()"
></app-video>
<app-query-params-ls></app-query-params-ls>
