import {Component,Input} from '@angular/core';
import {LogoListStore} from './logo-list.store'

@Component({
    selector: 'app-logo-list',
    templateUrl: './logo-list.component.html',
    styleUrls: ['./logo-list.component.css']
})
export class LogoListComponent {
    constructor(private store: LogoListStore) {}
    @Input() logoList = ["./assets/images/image 21.png"];
    // @Input() logoList = ["./assets/images/image 21.png","./assets/images/image 22.png"];
}
