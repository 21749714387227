import { Inject, Component, HostListener } from '@angular/core';
import {
  BusinessLogicRequirements,
  BusinessRequirementsInjectionToken,
} from '../business-logic.requirements';
import { PromoSignupStore } from './promo-signup.store';
import { FormBuilder, Validators } from '@angular/forms';
import { SharedService } from 'src/app/lib/shared-service';
// import { loadStripe } from '@stripe/stripe-js';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/lib/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as moment from 'moment';

@Component({
  selector: 'app-promo-signup',
  templateUrl: './promo-signup-controller.component.html',
  styleUrls: ['./promo-signup-controller.component.css'],
})
export class PromoSignupControllerComponent {
  constructor(
    @Inject(BusinessRequirementsInjectionToken)
    private business: BusinessLogicRequirements,
    private store: PromoSignupStore,
    private fb: FormBuilder,
    private shared: SharedService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private activatedRoute: ActivatedRoute
  ) {
    this.code = this.activatedRoute.snapshot.paramMap.get('code');
    this.getJSON().subscribe((data) => {
      console.log('countries json', data);
      this.countries = data;
    });
  }
  @HostListener('window:resize', ['$event'])
  onResize(e) {
    // console.log('resize',window.innerWidth,document.body.clientWidth)
    if (window.innerWidth < 480 || document.body.clientWidth < 480)
      this.mini = true;
    else this.mini = false;
  }
  ngOnDestroy() {
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
  }
  ngOnInit() {
    this.getCountries();
    localStorage.removeItem('vendor');
    localStorage.removeItem('ref');
    // this.setCountryDummy();
    if (this.code) {
      this.getPromo();
    }
    this.route.queryParams.subscribe((params) => {
      let code = params['monthlyDiscountCode'];
      console.log('code', code);
      this.dCode = code;
      let email = params['email'];
      console.log('email', email);
      let id = params['customerId'];
      this.customerId = id;
      console.log('id', id);
      if (email) {
        this.form.controls['email'].setValue(email);
      }
      if (this.dCode != undefined) {
        this.business.discountCheck(this.dCode).subscribe(
          (data) => {
            console.log('discount check', data);
            this.discountValid = true;
            this.discountData = data;
            this.business.getCustomerInfo(this.customerId).subscribe(
              (data) => {
                console.log(data);
                this.alreadyClaimed = data.claimed;
                if (this.alreadyClaimed) {
                  this.feedbackTitle =
                    'This customer has already claimed the coupon.';
                  this.feedbackBody = '';
                  this.feedbackBtn = {
                    status: false,
                    text: '',
                  };
                  this.tickIcon = false;
                  this.showFeedback = true;
                  setTimeout(() => {
                    this.showFeedback = false;
                    this.router
                      .navigateByUrl('/', { skipLocationChange: true })
                      .then(() => this.router.navigate(['/monthly/signup']));
                  }, 5000);
                }
              },
              (err) => {
                console.log(err);
              }
            );
          },
          (err) => {
            this.discountValid = false;
            console.log(err);
            this.feedbackTitle = err.error.message;
            this.feedbackBody = '';
            this.feedbackBtn = {
              status: false,
              text: '',
            };
            this.tickIcon = false;
            this.showFeedback = true;
            setTimeout(() => {
              this.showFeedback = false;
              this.router
                .navigateByUrl('/', { skipLocationChange: true })
                .then(() => this.router.navigate(['/monthly/signup']));
            }, 5000);
          }
        );
      }
    });
    this.form.get('username').valueChanges.subscribe((value) => {
      if (this.form.get('username').valid) {
        console.log(
          'check check username',
          this.form.get('username').value.trim()
        );
        this.business
          .checkUsername(btoa(this.form.get('username').value.trim()))
          .subscribe(
            (data) => {
              console.log(data);
              this.usernameTaken = false;
            },
            (err) => {
              console.log(err.message);
              this.usernameTaken = true;
            }
          );
      }
    });
    this.form.valueChanges.subscribe((value) => {
      this.firstnameClass = 'monthly-form';
      this.lastnameClass = 'monthly-form';
      this.usernameClass = 'monthly-form';
      this.pwClass = 'monthly-form';
      this.yobClass = 'monthly-form';
      this.contactClass = 'monthly-form';
      this.streetClass = 'monthly-form';
      this.provinceClass = 'monthly-form';
      this.cityClass = 'monthly-form';
      this.zipClass = 'monthly-form';
      this.plusClass = 'monthly-form';
      this.mobileClass = 'monthly-form';
      this.apartmentClass = 'monthly-form';
      this.emailClass = 'monthly-form';
    });

    if (window.innerWidth < 480 || document.body.clientWidth < 480) {
      this.mini = true;
    } else this.mini = false;
  }

  ngAfterViewInit() {
    this.refer = localStorage.getItem('ref');
    this.vendor = localStorage.getItem('vendor');
  }
  public code: any;
  public countries: any;
  public alreadyClaimed: boolean = false;
  public discountData: any;
  public discountValid: boolean = false;
  public feedbackTitle = '';
  public feedbackBody = '';
  public feedbackBtn = {
    status: false,
    text: '',
  };
  public tickIcon = true;
  public showFeedback = false;

  public customerId: any = '';
  public dCode: any = '';
  public mini: boolean = false;
  public countryList;
  public countryHint;
  public countryData: any;
  public countryError = false;
  public firstname;
  public lastname;
  public username;
  public email;
  public password;
  public yob;
  public contact;
  public street;
  public province;
  public city;
  public zip;
  public plus;
  public mobile;
  public apartment = '';
  public eyeFlag: boolean = false;
  public gender = {
    boy: false,
    girl: false,
    other: false,
  };
  public country;
  public countryRegion;
  public togglePassword = 'password';
  public firstnameClass = 'monthly-form';
  public lastnameClass = 'monthly-form';
  public usernameClass = 'monthly-form';
  public yobClass = 'monthly-form';
  public contactClass = 'monthly-form';
  public streetClass = 'monthly-form';
  public provinceClass = 'monthly-form';
  public cityClass = 'monthly-form';
  public zipClass = 'monthly-form';
  public plusClass = 'monthly-form';
  public mobileClass = 'monthly-form';
  public apartmentClass = 'monthly-form';
  public pwClass = 'monthly-form';
  public emailClass = 'monthly-form';
  public genderError = false;
  public clickBtn = false;
  public mobileView;
  public mobileViewService;
  public emailPattern =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  public yearPattern = /^(19[0-9]\d|20[0-2]\d|2020)$/;
  public numberOnly = /^(0|[1-9][0-9]*)$/;
  public signupObj;
  public usernameTaken: boolean = false;
  public agree: boolean = false;
  public videoUrl = environment.videoUrl;
  public videoType = environment.videoType;
  public dashboardRedir: any = '';
  public showVideo: boolean = false;
  public tos = environment.termsOfService;
  public pp = environment.privacyPolicy;
  public loading: boolean = false;
  public submitButton: any = 'monthly-button';
  public refer;
  public vendor;
  public invalidCode: boolean = false;
  public date = new Date();
  public momentToday = moment(this.date);
  public countryCode: any;

  public form = this.fb.group({
    firstname: this.fb.control('', [Validators.required]),
    lastname: this.fb.control('', [Validators.required]),
    username: this.fb.control('', [Validators.required]),
    email: this.fb.control('', [
      Validators.required,
      Validators.pattern(this.emailPattern),
    ]),
    password: this.fb.control('', [
      Validators.required,
      Validators.minLength(8),
    ]),
    yob: this.fb.control('', [
      Validators.required,
      Validators.pattern(this.yearPattern),
    ]),
    contact: this.fb.control('', [Validators.required]),
    street: this.fb.control('', [Validators.required]),
    province: this.fb.control(''),
    city: this.fb.control('', [Validators.required]),
    zip: this.fb.control('', [
      Validators.required,
      Validators.pattern(this.numberOnly),
    ]),
    plus: this.fb.control('', [Validators.required]),
    mobile: this.fb.control('', [
      Validators.required,
      Validators.pattern(this.numberOnly),
    ]),
    apartment: this.fb.control(''),
  });
  blurInput($event) {
    console.log('get blur event');
    if (this.form.get('username').valid) {
      console.log(
        'check check username',
        this.form.get('username').value.trim()
      );
      this.business
        .checkUsername(btoa(this.form.get('username').value.trim()))
        .subscribe(
          (data) => {
            console.log(data);
            this.usernameTaken = false;
          },
          (err) => {
            console.log(err.message);
            this.usernameTaken = true;
          }
        );
    }
  }
  public getJSON(): Observable<any> {
    return this.http.get('./assets/countries.json');
  }
  public dial_code: any;
  getCountries() {
    this.business.getCountries().subscribe(
      (data) => {
        console.log(data[0]);

        this.countryData = data[0];
        this.countryList = this.countryData.countries;
        this.countryHint = this.countryData.currentCountry;
        this.country = this.countryData.currentCountry;
        this.countryRegion = this.countryData.currentCountry;
        this.countryCode = this.countryData.countryCode;
        this.dial_code = this.countryData.dial_code;
        this.form.controls['plus'].setValue(this.dial_code);
        this.getStates();
        console.log('country country test test', this.country);

        console.log('this.countyrHint ', this.countryHint);
      },
      (err) => {
        this.setCountryDummy();
      }
    );
  }
  chooseCountry(country) {
    console.log('country is ', country);
    this.country = country;
  }
  countrySelect(country) {
    console.log('country is zha', country);
    this.countryRegion = country.name;
    this.countryCode = country.code;
    this.dial_code = country.dial_code;
    this.form.controls['plus'].setValue(this.dial_code);
    // this.getStates();
  }
  submitBtn() {
    this.clickBtn = true;
    console.log('submit');
    if (
      this.form.valid &&
      (this.gender.boy || this.gender.girl || this.gender.other) &&
      this.agree
    ) {
      this.firstname = this.form.get('firstname').value.trim();
      this.lastname = this.form.get('lastname').value.trim();
      this.username = this.form.get('username').value.trim();
      this.email = this.form.get('email').value.trim();
      this.password = this.form.get('password').value.trim();
      this.yob = this.form.get('yob').value.trim();
      this.contact = this.form.get('contact').value.trim();
      this.street = this.form.get('street').value.trim();
      this.province = this.form.get('province').value.trim();
      this.city = this.form.get('city').value.trim();
      this.zip = this.form.get('zip').value.trim();
      this.plus = this.form.get('plus').value.trim();
      this.mobile = this.form.get('mobile').value.trim();
      this.apartment = this.form.get('apartment').value.trim();
      var signupObj = {
        username: this.username,
        password: btoa(this.password),
        usertype: '3',
        firstName: this.firstname,
        lastName: this.lastname,
        yearOfBirth: this.yob,
        shippingAddress: {
          contactName: this.contact,
          country: this.countryRegion,
          address:
            this.apartment.length > 0
              ? this.street + ' ' + this.apartment
              : this.street,
          region: this.province,
          city: this.city,
          zip: this.zip,
          mobile: this.plus + '' + this.mobile,
        },
        country: this.country,
        gender: this.gender.boy
          ? 'boy'
          : this.gender.girl
          ? 'girl'
          : 'i-prefer-not-to-say',
        email: this.email,
        redirectURL: window.location.origin + '/continue/signup',
        trialEnable: false,
        registeredFrom: 'stemwerkz-start',
        stripeSub: 'solo-month',
        subscriptionId: this.subId,
        vendor:
          this.intervalType == 'yearly'
            ? 'ONEPAGE-YEARLY'
            : this.intervalType == 'monthly'
            ? 'ONEPAGE-MONTHLY'
            : 'ONEPAGE-MONTHLY',
        discountCode: this.dCode,
        customerId: this.customerId,
        referral: this.refer,
        promoCode: this.code,
      };
      if (this.vendor) signupObj.vendor = this.vendor;
      if (this.province == undefined || this.province == '')
        delete signupObj.shippingAddress.region;

      if (this.refer) signupObj.referral = this.refer;
      else delete signupObj.referral;

      console.log('signupObj', signupObj);
      if (!this.discountValid || this.alreadyClaimed) {
        delete signupObj.discountCode;
        delete signupObj.customerId;
        this.signUp(signupObj);
      } else {
        this.signUp(signupObj);
      }

      var userInfo = {
        firstname: signupObj.firstName,
        lastname: signupObj.lastName,
        username: signupObj.username,
        yob: signupObj.yearOfBirth,
        gender: signupObj.gender,
        country: signupObj.country,
        email: signupObj.email,
      };
      localStorage.setItem('userInfo', JSON.stringify(userInfo));
    } else {
      if (!this.agree && this.clickBtn) {
        document.getElementById('agreeCb').scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
      }
      if (this.form.get('plus').errors) {
        this.plusClass = 'monthly-error';
        document.getElementById('mobile').scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
      }
      if (this.form.get('mobile').errors) {
        this.mobileClass = 'monthly-error';
        document.getElementById('mobile').scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
      }
      if (this.form.get('zip').errors) {
        this.zipClass = 'monthly-error';
        document.getElementById('zip').scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
      }
      if (this.form.get('city').errors) {
        this.cityClass = 'monthly-error';
        document.getElementById('city').scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
      }
      // if (this.province == undefined && this.clickBtn) {
      //   document.getElementById('province').scrollIntoView({
      //     behavior: 'smooth',
      //     block: 'start',
      //     inline: 'nearest',
      //   });
      // }
      // if (this.form.get('apartment').errors) {
      //   this.apartmentClass = 'monthly-error';
      //   document.getElementById('street').scrollIntoView({
      //     behavior: 'smooth',
      //     block: 'start',
      //     inline: 'nearest',
      //   });
      // }
      if (this.form.get('street').errors) {
        this.streetClass = 'monthly-error';
        document.getElementById('street').scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
      }
      if (this.countryRegion == '' && this.clickBtn) {
        document.getElementById('country_region').scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
      }
      if (this.form.get('contact').errors) {
        this.contactClass = 'monthly-error';
        document.getElementById('contact').scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
      }
      if (this.form.get('email').errors) {
        this.emailClass = 'monthly-error';
        document.getElementById('email').scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
      }
      if (this.form.get('yob').errors) {
        this.yobClass = 'monthly-error';
        document.getElementById('yob').scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
      }
      if (!this.gender.boy && !this.gender.girl && !this.gender.other) {
        this.genderError = true;
        document.getElementById('gender').scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
        console.log('testing', document.getElementsByClassName('gender'));
        var ele = document.getElementsByClassName('gender');
        for (var i = 0; i < ele.length; i++) {
          ele[i].classList.add('error-border');
        }
      }
      if (this.form.get('password').errors) {
        this.pwClass = 'monthly-error';
        document.getElementById('password').scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
      }
      if (this.form.get('username').errors) {
        this.usernameClass = 'monthly-error';
        document.getElementById('username').scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
      }
      if (this.form.get('firstname').errors) {
        this.firstnameClass = 'monthly-error';
        document.getElementById('name').scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
      }
      if (this.form.get('lastname').errors) {
        this.lastnameClass = 'monthly-error';
        document.getElementById('name').scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
      }
    }
  }

  eyeToggle() {
    this.eyeFlag = !this.eyeFlag;
    if (this.eyeFlag) {
      this.togglePassword = 'text';
    } else {
      this.togglePassword = 'password';
    }
  }

  genderSelect(obj) {
    if (obj == 'boy') {
      this.gender.boy = true;
      this.gender.girl = false;
      this.gender.other = false;
      console.log('boy log', this.gender);
      var ele = document.getElementsByClassName('gender');
      for (var i = 0; i < ele.length; i++) {
        ele[i].classList.remove('error-border');
        console.log('removed', ele[i]);
      }
    }
    if (obj == 'girl') {
      this.gender.boy = false;
      this.gender.girl = true;
      this.gender.other = false;
      console.log('girl log', this.gender);
      var ele = document.getElementsByClassName('gender');
      for (var i = 0; i < ele.length; i++) {
        ele[i].classList.remove('error-border');
        console.log('removed', ele[i]);
      }
    }
    if (obj == 'other') {
      this.gender.boy = false;
      this.gender.girl = false;
      this.gender.other = true;
      console.log('other log', this.gender);
      var ele = document.getElementsByClassName('gender');
      for (var i = 0; i < ele.length; i++) {
        ele[i].classList.remove('error-border');
        console.log('removed', ele[i]);
      }
    }
  }
  signUp(obj) {
    this.loading = true;
    this.submitButton = 'monthly-button-disable';
    var publish_key = '';
    this.business.signup(obj).subscribe(
      (data) => {
        console.log('res', data);
        var header = {
          username: data[0].username,
          password: data[0].token,
          user: data[0]._id,
          apikey: data[0].apikey,
          playerid: data[0].idPlayer,
        };
        localStorage.setItem('sessionId', data[0].sessionId);
        var url = this.authService.getRedirectDashboardUrl(header);
        localStorage.setItem('dashboardRedir', url);
        // this.dashboardRedir = url;
        this.router.navigate(['/continue/signup']);
        // this.business.getPublishKey().subscribe(async (res) => {
        //   publish_key = res.publishableKey;
        //   console.log('publish key', publish_key);
        //   const stripe = await loadStripe(publish_key);
        //   stripe
        //     .redirectToCheckout({
        //       sessionId: data[0].sessionId,
        //     })
        //     .then(function (result) {
        //       console.log(result);
        //     });
        // });
      },
      (err) => {
        console.log(err.message);
        this.loading = false;
        this.submitButton = 'monthly-button';

        console.log(err);
        this.feedbackTitle = err.error.message;
        this.feedbackBody = '';
        this.feedbackBtn = {
          status: false,
          text: '',
        };
        this.tickIcon = false;
        this.showFeedback = true;
        setTimeout(() => {
          this.showFeedback = false;
        }, 3000);
      }
    );
  }

  // checkboxClick(e) {
  //   console.log('checkbox clicked', e);
  //   this.agree = e;
  // }
  // redirectStemWerkz() {
  //   window.location.href = this.dashboardRedir;
  //   localStorage.clear();
  // }
  gotoDashboard() {
    window.location.href = environment.dashboardurl;
  }
  toggleCb() {
    this.agree = !this.agree;
  }
  public monthlyPrice: any;
  public currency: any;
  calculatePrice() {
    var price;
    if (this.discountData?.amount_off != null) {
      price = (this.monthlyPrice - this.discountData.amount_off).toPrecision(3);
    }
    if (this.discountData?.percent_off != null) {
      price = (
        this.monthlyPrice *
        ((100 - this.discountData.percent_off) / 100)
      ).toPrecision(3);
    }
    return price;
  }

  setCountryDummy() {
    this.countryData = this.countries;
    this.countryList = this.countryData.countries;
    this.countryHint = this.countryData.currentCountry;
    this.country = this.countryData.currentCountry;
  }
  public subId: any;
  public specialOfferLandscape: any;
  public specialOfferPortrait: any;
  public intervalType: any;
  public deliFee: any;
  public deliNote: any;
  getPromo() {
    this.business.getPromoInfo(this.code).subscribe(
      (data) => {
        console.log(data);
        this.monthlyPrice = data[0].monthlyPrice;
        this.subId = data[0]._id;
        this.currency = data[0].currency.toUpperCase();
        this.specialOfferLandscape = data[0].metadata.landscapeURL;
        this.specialOfferPortrait = data[0].metadata.portraitURL;
        this.intervalType = data[0].metadata.intervalType;
        this.deliFee = data[0].metadata.deliveryFees;
        this.deliNote = data[0].metadata.note;
        this.durationMonth = data[0].metadata.durationInMonth;
        this.calculateNextDue();
      },
      (err) => {
        console.log(err);
        this.invalidCode = true;
        document.getElementsByTagName('body')[0].style.overflow = 'hidden';
        // this.feedbackTitle = err.error.message;
        // this.feedbackBody = '';
        // this.feedbackBtn = {
        //   status: false,
        //   text: '',
        // };
        // this.tickIcon = false;
        // this.showFeedback = true;
        // setTimeout(() => {
        //   this.showFeedback = false;
        //   this.router.navigate(['']);
        // }, 3000);
      }
    );
  }
  addCurrencySymbol(data) {
    var currency_symbols = {
      USD: '$', // US Dollar
      EUR: '€', // Euro
      CRC: '₡', // Costa Rican Colón
      GBP: '£', // British Pound Sterling
      ILS: '₪', // Israeli New Sheqel
      INR: '₹', // Indian Rupee
      JPY: '¥', // Japanese Yen
      KRW: '₩', // South Korean Won
      NGN: '₦', // Nigerian Naira
      PHP: '₱', // Philippine Peso
      PLN: 'zł', // Polish Zloty
      PYG: '₲', // Paraguayan Guarani
      THB: '฿', // Thai Baht
      UAH: '₴', // Ukrainian Hryvnia
      VND: '₫', // Vietnamese Dong
      SGD: 'S$', // Singapore custom added
    };
    return currency_symbols[this.currency] + data;
  }
  backHome() {
    this.router.navigate(['']);
  }
  public dueDate: any;
  public durationMonth: any;
  calculateNextDue() {
    console.log('duration', this.durationMonth, Number(this.durationMonth));

    var dateDue = moment(this.momentToday).add(Number(this.durationMonth), 'M');
    var formatDate = dateDue.format();
    this.dueDate = formatDate;
    console.log('nextDue', formatDate);
  }
  calculateAge() {
    var year = new Date().getFullYear();
    // console.log('year', year);
    // console.log('yob', this.form.get('yob').value.trim());

    return Number(year) - this.form.get('yob').value.trim();
  }
  public states: any;
  getStates() {
    this.business.getState(this.countryCode).subscribe(
      (data) => {
        console.log('states', data);
        this.states = data;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  // stateSelect(data) {
  //   console.log('province', data);

  //   this.province = data.name;
  // }

  offerImgSetting() {
    if (window.innerWidth < 768 || document.body.clientWidth < 768) {
      return this.specialOfferPortrait;
    } else return this.specialOfferLandscape;
  }
}
