import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SharedService } from 'src/app/lib/shared-service';
import { QueryParamsLsStore } from './query-params-ls.store';

@Component({
  selector: 'app-query-params-ls',
  templateUrl: './query-params-ls.component.html',
  styleUrls: ['./query-params-ls.component.css'],
})
export class QueryParamsLsComponent {
  constructor(
    private store: QueryParamsLsStore,
    private activatedRoute: ActivatedRoute,
    private shared: SharedService
  ) {}

  public vendor;
  public refer;
  public contentCode;
  public origin;
  public questId;
  public skill;
  public nextTo;

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params) => {
      console.log('nani kore', params);

      this.refer = params.ref;
      this.vendor = params.vendor;
      this.contentCode = params.contentcode;
      this.origin = params.origin;
      this.questId = params.questId;
      this.skill = params.skill;
      this.nextTo = params.nextto;

      if (this.refer) localStorage.setItem('ref', this.refer);
      if (this.vendor) localStorage.setItem('vendor', this.vendor);
      if (this.origin) this.shared.setOrigin(this.origin);
      // else localStorage.removeItem('vendor');
      if (this.questId && this.skill) {
        this.shared.setParamsForSkill(this.questId, this.skill);
      }
      if (this.nextTo) this.shared.setNextTo(this.nextTo);
      // if (this.contentCode) localStorage.setItem('contentCode',this.contentCode);
      // else localStorage.removeItem('contentCode');
    });
  }
}
