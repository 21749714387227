import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalComponentsModule } from '../../../global-components/global-components.module';
import { SharedComponentsModule } from '../shared-components/shared-components.module';
import { ChildInfoControllerComponent } from './child-info-controller.component';
import { RouterModule } from '@angular/router';
import { InfoOneComponent } from './components/info-one/info-one.component';
import { InfoTwoComponent } from './components/info-two/info-two.component';
import { InfoThreeComponent } from './components/info-three/info-three.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContentCodeComponent } from './components/content-code/content-code.component';

@NgModule({
  declarations: [
    ChildInfoControllerComponent,
    InfoOneComponent,
    InfoTwoComponent,
    InfoThreeComponent,
    ContentCodeComponent,
  ],
  exports: [ChildInfoControllerComponent],
  imports: [
    CommonModule,
    RouterModule,
    GlobalComponentsModule,
    SharedComponentsModule,
    ReactiveFormsModule,
    FormsModule,
  ],
})
export class ChildInfoModule {}
