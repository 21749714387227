<div class="btn-div" *ngIf="!mobileView">
  <div class="header-text text-c300">{{ locale?.mainPage.welcome }}</div>
  <div class="normal-text text-c300 mt-8">
    {{ locale?.mainPage.whatWouldYouDo }}
  </div>
  <div
    class="btnwrapper mt-40 d-flex justify-content-around align-items-center"
  >
    <app-button
      style="margin-right: 32px"
      [class]="'btn-choice btn-red'"
      (click)="trialClick($event)"
    >
      <app-text class="bold-text text-s0">{{
        locale?.mainPage.trial
      }}</app-text>
    </app-button>
    <app-button [class]="'btn-choice btn-green'" (click)="claimClick($event)">
      <app-image
        [src]="'../../../../../assets/images/plus.svg'"
        class="mr-16"
      ></app-image>
      <app-text class="bold-text text-c300">{{
        locale?.mainPage.claim
      }}</app-text>
    </app-button>
  </div>
  <div class="clever-div">
    <div (click)="cleverLogin($event)">Login with Clever</div>
  </div>
</div>
<div class="btn-div" *ngIf="mobileView">
  <div class="header-text-mb text-c300">{{ locale?.mainPage.welcome }}</div>
  <div class="normal-text-mb text-c300 mt-8">
    {{ locale?.mainPage.whatWouldYouDo }}
  </div>
  <div
    class="btnwrapper-mb mt-40 d-flex justify-content-around align-items-center"
  >
    <app-button
      style="margin-bottom: 24px"
      [class]="'btn-choice btn-red'"
      (click)="trialClick($event)"
    >
      <app-text class="bold-text text-s0">{{
        locale?.mainPage.trial
      }}</app-text>
    </app-button>
    <app-button [class]="'btn-choice btn-green'" (click)="claimClick($event)">
      <app-image
        [src]="'../../../../../assets/images/plus.svg'"
        class="mr-16"
      ></app-image>
      <app-text class="bold-text text-c300">{{
        locale?.mainPage.claim
      }}</app-text>
    </app-button>
  </div>
  <div class="clever-div">
    <div (click)="cleverLogin($event)">Login with Clever</div>
  </div>
</div>
