import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalComponentsModule } from '../global-components/global-components.module';
import { SharedComponentsModule } from './presentation/shared-components/shared-components.module';
import { BusinessLogicFacade } from './business-logic/business-logic.facade';
import { BusinessRequirementsInjectionToken } from './presentation/business-logic.requirements';
import { SignupHomeModule } from './presentation/signup-home/signup-home.module';
import { TrialModule } from './presentation/trial/trial.module';
import { MonthlySignupModule } from './presentation/monthly-signup/monthly-signup.module';
import { ContinueSignupModule } from './presentation/continue-signup/continue-signup.module';
import { YearlySignupModule } from './presentation/yearly-signup/yearly-signup.module';
import { SignupHomeCnModule } from './presentation/signup-home-cn/signup-home-cn.module';
import { PromoSignupModule } from './presentation/promo-signup/promo-signup.module';

@NgModule({
  imports: [
    BusinessLogicFacade,
    CommonModule,
    GlobalComponentsModule,
    SharedComponentsModule,
    SignupHomeModule,
    SignupHomeCnModule,
    TrialModule,
    MonthlySignupModule,
    PromoSignupModule,
    ContinueSignupModule,
    YearlySignupModule,
  ],
  providers: [
    {
      provide: BusinessRequirementsInjectionToken,
      useClass: BusinessLogicFacade,
    },
  ],
  exports: [],
})
export class SignupFeatureModule {}
