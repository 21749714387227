import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
export interface BusinessLogicRequirements {
  checkBundle(code): Observable<any>;
  checkUserGrade(header): Observable<any>;
  checkUserInfo(header): Observable<any>;
  getOTP(header): Observable<any>;
  verifyOTP(header): Observable<any>;
  updateUserDetail(header): Observable<any>;
  wechatLogin(body): Observable<any>;
  appleLogin(body): Observable<any>;
  cleverLogin(body): Observable<any>;
  claimBundle(code): Observable<any>;
  loginPlayer(username, password): Observable<any>;
  globalLogin(username, password): Observable<any>;
  getCountryList(): Observable<any>;
  getStateList(code): Observable<any>;
  getCityList(cCode, sCode): Observable<any>;
  checkPlan(header): Observable<any>;
}

export const BusinessRequirementsInjectionToken =
  new InjectionToken<BusinessLogicRequirements>('home Business Requirements');
