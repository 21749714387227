import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
// import { AuthService } from "src/app/lib/auth.service";
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserApiService {
  private baseUrl = environment.apiurl;
  private dashboardUrl = environment.dashboardApiurl;
  private apiKey = environment.apikey;
  private nodmaUrl = environment.nodmaApi;
  private questUrl = environment.questurl;
  private userInfo = {
    username: '',
    password: '',
    api_key: '',
  };
  constructor(
    private http: HttpClient,
    private _router: Router // private _authService: AuthService
  ) {
    // this.userInfo = this._authService.getUserInfo();
    // this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    console.log('Home DataFacade', this.userInfo);
  }

  checkBundle(code) {
    var apikey = 'ndmc4acd43956e895b4de8aa7ec742edd3c';
    var secretkey = 'ndmc4acd43956e895b4de8aa7ec742edd3c';
    // let url = this.questUrl+ '/api/v1/bundledgrade/check?code='+code;
    let url = this.questUrl + '/api/v2/bundledgrade/check?code=' + code;
    // let url =  'https://devquest-api.pagewerkz.com/api/v1/bundledgrade/check?code='+code;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        apikey: apikey,
        secretkey: secretkey,
      }),
    };
    return this.http.get(url, httpOptions).pipe(
      map((res: Response) => {
        let result = res;
        return result;
      })
    );
  }
  checkUserGrade(header) {
    console.log('got here', header);
    let url = this.questUrl + '/api/v1/users/gradesummary';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'text/plain',
        apikey: header.apikey,
        username: header.username,
        user: header.user || header.userId,
        password: header.password,
      }),
    };
    var body = {};
    return this.http.post(url, body, httpOptions).pipe(
      map((res: Response) => {
        let result = res;
        return result;
      })
    );
  }
  checkPlan(header): Observable<any> {
    let url = this.baseUrl + '/api/v2/users/current-subscription';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'text/plain',
        apikey: header.apikey,
        username: header.username,
        password: header.password,
      }),
    };
    return this.http.get(url, httpOptions).pipe(
      map((res: Response) => {
        let result = res;
        console.log(result);
        return result;
      })
    );
  }

  wechatLogin(body) {
    console.log('got here');
    let url = this.baseUrl + '/api/v1/wechat/login';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        apikey: this.apiKey,
      }),
    };
    return this.http.post(url, body, httpOptions).pipe(
      map((res: Response) => {
        let result = res;
        return result;
      })
    );
  }
  appleLogin(body) {
    console.log('got here', body);
    let url = this.baseUrl + '/api/v1/apple-signin-web';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        apikey: this.apiKey,
      }),
    };
    return this.http.post(url, body, httpOptions).pipe(
      map((res: Response) => {
        let result = res;
        return result;
      })
    );
  }
  cleverLogin(body) {
    console.log('got here', body);
    let url = this.baseUrl + '/api/v1/clever-signin';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        apikey: this.apiKey,
      }),
    };
    return this.http.post(url, body, httpOptions).pipe(
      map((res: Response) => {
        let result = res;
        return result;
      })
    );
  }

  loginUser(UserName, Password): Observable<any> {
    var body = {
      username: UserName,
      password: Password,
    };
    let url = this.baseUrl + '/api/v1/china-login';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        apikey: this.apiKey,
      }),
    };
    return this.http.post(url, body, httpOptions).pipe(
      map((res: Response) => {
        console.log('testing zha', res);

        let result = [];
        result.push(res);
        return result;
      })
    );
  }
  globalLogin(UserName, Password): Observable<any> {
    let url = this.dashboardUrl + 'api/v1/login';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        apikey: this.apiKey,
        username: UserName,
        password: Password,
      }),
    };
    return this.http.get(url, httpOptions).pipe(
      map((res: Response) => {
        console.log('testing zha', res);

        let result = res;
        return result;
      })
    );
  }
  claimBundle(code) {
    console.log('claimBundle', code);

    let url =
      this.questUrl +
      '/api/v2/bundledgrade/claim?code=' +
      code.code +
      '&onboarding=true';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        apikey: code.apikey,
        username: code.username,
        password: code.password,
        user: code.user,
      }),
    };

    var body = {};

    return this.http.post(url, body, httpOptions).pipe(
      map(
        (res: Response) => {
          // let result = [];
          // result = [res];
          console.log('data');
          return res;
        },
        (err) => {
          console.log('data error');
          console.log(err);
        }
      )
    );
  }
  getCountryList() {
    let url = this.baseUrl + '/api/v1/countries';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.get(url, httpOptions).pipe(
      map((res: Response) => {
        let result = res;
        return result;
      })
    );
  }
  getStateList(code) {
    let url = this.baseUrl + '/api/v1/states?countryCode=' + code;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.get(url, httpOptions).pipe(
      map((res: Response) => {
        let result = res;
        return result;
      })
    );
  }
  getCityList(cCode, sCode) {
    let url =
      this.baseUrl +
      '/api/v1/cities?countryCode=' +
      cCode +
      '&stateCode=' +
      sCode;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.get(url, httpOptions).pipe(
      map((res: Response) => {
        let result = res;
        return result;
      })
    );
  }
  checkUserInfo(header) {
    console.log('got here', header);
    let url = this.baseUrl + '/api/v2/users/check-firstuser-info';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        apikey: header.apikey,
        username: header.username,
        user: header.user,
        password: header.password,
      }),
    };
    var body = {
      childId: header.childId,
      parentId: header.parentId,
    };
    return this.http.post(url, body, httpOptions).pipe(
      map((res: Response) => {
        let result = res;
        return result;
      })
    );
  }
  updateUserDetail(header) {
    console.log('got here', header);
    let url = this.baseUrl + '/api/v2/users/' + header.userId;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        apikey: header.apikey,
        username: header.username,
        // user: header.user,
        password: header.password,
      }),
    };
    var body;
    if (header.childOrParent == 'parent') {
      body = {
        email: header.email,
        metadata: {
          relation: header.relation,
          phone: {
            countryCode: header.phonecode,
            number: header.phoneNumebr,
          },
        },
      };
    } else if (header.childOrParent == 'child') {
      body = {
        firstName: header.firstName,
        lastName: header.lastName,
        yearOfBirth: header.birthYear,
        monthOfBirth: header.birthMonth,
        gender: header.gender,
        metadata: {
          country: header.country,
          state: header.state,
          city: header.city,
        },
      };
    }
    return this.http.put(url, body, httpOptions).pipe(
      map((res: Response) => {
        let result = res;
        return result;
      })
    );
  }
  getOTP(header) {
    console.log('got here', header);
    let url = this.baseUrl + '/api/v1/ph-otp-send';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post(url, header, httpOptions).pipe(
      map((res: Response) => {
        let result = res;
        return result;
      })
    );
  }
  verifyOTP(header) {
    console.log('got here', header);
    let url = this.baseUrl + '/api/v1/ph-otp-verify';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        apikey: this.apiKey,
      }),
    };

    return this.http.post(url, header, httpOptions).pipe(
      map((res: Response) => {
        let result = res;
        return result;
      })
    );
  }
}
