import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ChildInfoStore {

}

export enum ChildInfoStoreKeys {

}
