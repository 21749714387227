import { Component, Output, EventEmitter, Input } from '@angular/core';
import { ClaimStore } from './claim.store';
import { FormBuilder, Validators } from '@angular/forms';
import { SharedService } from 'src/app/lib/shared-service';
import { AuthService } from 'src/app/lib/auth.service';
@Component({
  selector: 'app-claim',
  templateUrl: './claim.component.html',
  styleUrls: ['./claim.component.css'],
})
export class ClaimComponent {
  constructor(
    private store: ClaimStore,
    private fb: FormBuilder,
    private shared: SharedService,
    private authService: AuthService
  ) {}
  @Input() previewList;
  @Input() subInfo;
  @Input() locale;
  @Output() nextOutput = new EventEmitter<any>();
  @Output() popUpFlag = new EventEmitter<any>();
  @Output() backgroundFlag = new EventEmitter<any>();

  public form = this.fb.group({
    firstName: this.fb.control('', [Validators.required]),
    lastName: this.fb.control('', [Validators.required]),
  });
  public firstName;
  public lastName;
  public firstNameClass = 'signup-half';
  public lastNameClass = 'signup-half';
  public rightArrowUrl = './assets/images/right_arrow.svg';
  public clickBtn = false;

  // this.form.get("firstName").valueChanges.subscribe(() => {
  //   this.firstNameClass = "singup-half";
  // });

  // this.form.get("lastName").valueChanges.subscribe(() => {
  //   this.firstNameClass = "singup-half";
  // });

  ngOnInit() {
    this.form.valueChanges.subscribe((value) => {
      this.clickBtn = false;
      this.firstNameClass = 'signup-half';
      this.lastNameClass = 'signup-half';
    });
  }

  changeBackground(index) {
    this.backgroundFlag.emit(index);
  }

  continueBtnClicked() {
    this.clickBtn = true;
    if (this.form.valid) {
      this.firstName = this.form.get('firstName').value.trim();
      this.lastName = this.form.get('lastName').value.trim();
      var nameObj = {
        firstName: this.firstName,
        lastName: this.lastName,
      };
      this.shared.setNameData(nameObj);
      this.authService.setNameInfo(nameObj);
      this.nextOutput.emit(nameObj);
    } else {
      var ele = document.getElementsByClassName('claim-wrapper');
      this.firstNameClass = 'input-half-err';
      this.lastNameClass = 'input-half-err';
    }
    console.log(this.firstName, this.lastName);
  }

  blurInput($event) {
    console.log('get blur event');
  }
  openPopup($event) {
    console.log('test test');
    this.popUpFlag.emit($event);
  }
}
