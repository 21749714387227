import { Component, Input, Output, EventEmitter } from '@angular/core';
import { TrialcreateStore } from './trialcreate.store';
import { FormBuilder, Validators } from '@angular/forms';
import { SharedService } from 'src/app/lib/shared-service';
import { AuthService } from 'src/app/lib/auth.service';

@Component({
  selector: 'app-trialcreate',
  templateUrl: './trialcreate.component.html',
  styleUrls: ['./trialcreate.component.css'],
})
export class TrialcreateComponent {
  constructor(
    private store: TrialcreateStore,
    private fb: FormBuilder,
    private shared: SharedService,
    public authService: AuthService
  ) {}
  public firstName: any;
  public lastName: any;
  public firstNameClass: any = 'trialClass';
  public lastNameClass: any = 'trialClass';
  public btnFlag: boolean = false;

  ngOnInit() {
    this.form.valueChanges.subscribe((value) => {
      this.btnFlag = false;
      this.firstNameClass = 'trialClass';
      this.lastNameClass = 'trialClass';
    });
  }
  @Input() locale;
  @Output() nextOutput = new EventEmitter<any>();

  public form = this.fb.group({
    firstName: this.fb.control('', [Validators.required]),
    lastName: this.fb.control('', [Validators.required]),
  });

  continueBtnClicked() {
    this.btnFlag = true;
    if (this.form.valid) {
      this.firstName = this.form.get('firstName').value.trim();
      this.lastName = this.form.get('lastName').value.trim();
      var nameObj = {
        firstName: this.firstName,
        lastName: this.lastName,
      };
      this.shared.setNameData(nameObj);
      this.authService.setNameInfo(nameObj);
      this.nextOutput.emit(nameObj);
      console.log('next');
    } else {
      if (this.form.get('firstName').errors)
        this.firstNameClass = 'trialClass-error';
      if (this.form.get('lastName').errors)
        this.lastNameClass = 'trialClass-error';
    }
  }
}
