<div class="claim-wrapper">
  <app-text class="text-accent500 successful-text">{{
    locale?.codeSuccessful.codeSuccessful
  }}</app-text>
  <app-preview
    [previewList]="previewList"
    [locale]="locale"
    [subInfo]="subInfo"
    class="w-100"
    (changeBackground)="changeBackground($event)"
  ></app-preview>
  <div class="lower-wrapper">
    <app-text class="create-text text-c300">{{
      locale?.codeSuccessful.createAccToStart.createAccToStart1
    }}</app-text>
    <app-logo
      [width]="'150px'"
      [height]="'36px'"
      style="margin: 0px 12px 0px 12px"
      [class]="'create-small-image'"
    ></app-logo>
    <app-text class="create-text text-c300">{{
      locale?.codeSuccessful.createAccToStart.createAccToStart2
    }}</app-text>
  </div>
  <form
    style="width: 100%"
    (keyup.enter)="$event.preventDefault(); continueBtnClicked()"
  >
    <div class="name-form">
      <app-input
        [hint]="locale?.codeSuccessful.firstName"
        [control]="form.get('firstName')"
        [class]="firstNameClass"
        (blurEvent)="blurInput($event)"
      ></app-input>
      <app-input
        [hint]="locale?.codeSuccessful.lastName"
        [control]="form.get('lastName')"
        [class]="lastNameClass"
        (blurEvent)="blurInput($event)"
      ></app-input>
      <app-button [class]="'red-primary-rect'" (click)="continueBtnClicked()">
        <app-image [src]="rightArrowUrl"></app-image>
      </app-button>
    </div>
  </form>
  <app-text
    *ngIf="
      (form.get('firstName').errors || form.get('lastName').errors) && clickBtn
    "
    [class]="'form-error-label'"
    [withoutImage]="false"
  >
    {{ locale?.error.pleaseEnter }}
  </app-text>
  <!-- <app-text class="normal-text text-c100" [ngClass]="{ 'mt-24': !clickBtn }"
    >{{ locale?.codeSuccessful.alreadyHaveAccount.already1 }}
    <span style="color: #3394ab" (click)="openPopup($event)" class="cursor">{{
      locale?.codeSuccessful.alreadyHaveAccount.already2
    }}</span></app-text
  > -->
</div>
