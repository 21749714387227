import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ClaimStore {

}

export enum ClaimStoreKeys {

}
