import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class GenderItemStore {

}

export enum GenderItemStoreKeys {

}
