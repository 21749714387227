<div class="login-wrap">
  <div class="background-img">
    <app-logo [width]="'175px'" [height]="'42px'"></app-logo>
  </div>
  <div class="login-div d-flex flex-column">
    <div class="login-choice">
      <div class="welcome d-flex justify-content-center">
        Welcome to STEMWerkz!
      </div>
      <div class="mb-32 need-acc d-flex justify-content-center">
        Need an Account?
      </div>
      <div
        class="mb-32 choice-div d-flex justify-content-center flex-column align-items-center"
      >
        <div
          (click)="trialClicked()"
          class="trial-div cursor d-flex justify-content-center mb-32"
        >
          Sign up for a 7-DAY FREE TRIAL
        </div>
        <div
          (click)="claimClicked()"
          class="claim-div cursor d-flex justify-content-center"
        >
          <img class="plus-icon" src="./assets/images/plus.svg" />
          Claim custom content
        </div>
      </div>
    </div>
    <div
      *ngIf="loginMethods.loginForm"
      class="or-wrap d-flex justify-content-center align-items-center"
    >
      <div class="or-text d-flex justify-content-center align-items-center">
        OR
      </div>
    </div>
    <div *ngIf="loginMethods.loginForm" class="form-div">
      <app-login-form
        [loading]="loginLoading"
        [locale]="locale"
        (loginEvent)="loginClicked($event)"
      ></app-login-form>
    </div>
    <div
      *ngIf="loginMethods.loginWith"
      class="or-wrap d-flex justify-content-center align-items-center"
    >
      <div class="or-text d-flex justify-content-center align-items-center">
        OR
      </div>
    </div>
    <div *ngIf="loginMethods.loginWith" class="login-with">
      <!-- <div class="apple-div" style="margin-bottom: 32px">
        <img
          class="apple-icon"
          src="../../../../assets/images/black_apple.svg"
        />

        <span>Login with Apple</span>
      </div> -->
      <div class="clever-div" (click)="cleverClicked()">
        <img
          class="apple-icon"
          src="../../../../assets/images/clever_logo.svg"
        />
        <span>Login with Clever</span>
      </div>
    </div>
    <div class="login-with">
      <div class="terms">
        By signing up, you agree to our
        <a [href]="tos" target="_blank" class="tos cursor">Terms of Services</a>
        and
        <a [href]="pp" target="_blank" class="tos cursor">Privacy Policy</a>.
      </div>
    </div>
  </div>
</div>
<app-custom-feedback
  *ngIf="showFeedback"
  [feedbackTitle]="feedbackTitle"
  [feedbackBody]="feedbackBody"
  [feedbackBtn]="feedbackBtn"
  [tickIcon]="tickIcon"
></app-custom-feedback>
<app-query-params-ls></app-query-params-ls>
