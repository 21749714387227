import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AgeStore } from './age.store';

@Component({
  selector: 'app-age',
  templateUrl: './age.component.html',
  styleUrls: ['./age.component.css'],
})
export class AgeComponent {
  constructor(private store: AgeStore) {}
  @Input() error;
  @Input() locale;
  @Output() ageOutput = new EventEmitter<any>();
  public leftArrow = './assets/images/left-arrow.svg';
  public rightArrow = './assets/images/right-arrow.svg';
  public redArrowUrl = './assets/images/red_cancel_s.svg';
  public digitArray = [
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    29,
  ];
  public showDigitArray = [null, null, null, null, null];
  public middleIndex;
  public selected = false;
  public rightClass = 'increase-btn';
  public leftClass = 'decrease-btn';

  ngOnInit() {
    this.middleIndex = 0;
    this.checkMiddleIndexForBtnClass();
    this.changeDigit();
  }

  changeDigit() {
    this.showDigitArray = [null, null, null, null, null];
    if (this.middleIndex == 0) {
      var startIndex = 0;
      for (var i = 2; i < 5; i++) {
        this.showDigitArray[i] = this.digitArray[startIndex];
        startIndex++;
      }
    } else if (this.middleIndex == 1) {
      var startIndex = 0;
      for (var i = 1; i < 5; i++) {
        this.showDigitArray[i] = this.digitArray[startIndex];
        startIndex++;
      }
    } else if (this.middleIndex == 2) {
      var startIndex = 0;
      for (var i = 0; i < 5; i++) {
        this.showDigitArray[i] = this.digitArray[startIndex];
        startIndex++;
      }
    } else {
      var startIndex = this.middleIndex - 2;
      for (var i = 0; i < 5; i++) {
        this.showDigitArray[i] = this.digitArray[startIndex];
        startIndex++;
      }
    }
    console.log(this.showDigitArray, 'show dg aray');
  }

  clickedNumber(age) {
    var index = this.digitArray.indexOf(age);
    console.log(index, ' current index '); //2
    this.middleIndex = index;
    this.selected = true;
    this.ageOutput.emit(this.digitArray[this.middleIndex]);
    this.changeDigit();
    this.ageOutput.emit(this.digitArray[this.middleIndex]);
    this.checkMiddleIndexForBtnClass();
  }

  // clickedRight($event){
  //   console.log(this.middleIndex, this.digitArray.length-2);
  //   if(this.middleIndex <= (this.digitArray.length-2)){
  //     this.middleIndex++;
  //     this.changeDigit();
  //     this.selected = true;
  //     this.ageOutput.emit(this.digitArray[this.middleIndex]);
  //   }
  // }

  // clickedLeft($event){
  //   console.log(this.middleIndex, this.digitArray.length-2);
  //   if(this.middleIndex != 0){
  //     this.middleIndex--;
  //     this.changeDigit();
  //     this.selected = true;
  //     this.ageOutput.emit(this.digitArray[this.middleIndex]);
  //   }
  // }

  public myInterval: any;

  // mouseDownRight($event){
  //   console.log('down');
  //   this.myInterval = setInterval(() => {
  //     this.clickedRight($event);
  //   }, 100);
  // }

  // mouseUpRight($event){
  //   console.log('up');
  //   clearInterval(this.myInterval);
  // }

  // mouseDownLeft($event){
  //   console.log('down');
  //   this.myInterval = setInterval(() => {
  //     this.clickedLeft($event);
  //   }, 100);
  // }

  // mouseUpLeft($event){
  //   console.log('up');
  //   clearInterval(this.myInterval);
  // }

  // touchStartRight($event){
  //   event.preventDefault();
  //   this.mouseDownRight($event);
  // }

  // touchEndRight($event){
  //   event.preventDefault();
  //   this.mouseUpRight($event);
  // }

  // touchStartLeft($event){
  //   event.preventDefault();
  //   this.mouseDownLeft($event);
  // }

  // touchEndLeft($event){
  //   event.preventDefault();
  //   this.mouseUpLeft($event);
  // }

  mouseDown(navigator) {
    console.log('down');
    if (navigator == 'left') {
      this.myInterval = setInterval(() => {
        this.clicked('left');
      }, 100);
    } else {
      this.myInterval = setInterval(() => {
        this.clicked('right');
      }, 100);
    }
  }

  mouseUp(navigator) {
    console.log('up');
    if (navigator == 'left') {
      clearInterval(this.myInterval);
    } else {
      clearInterval(this.myInterval);
    }
  }

  checkMiddleIndexForBtnClass() {
    if (this.middleIndex == 0) {
      this.leftClass = 'disable-nav';
      this.rightClass = 'increase-btn';
    } else if (this.middleIndex > this.digitArray.length - 2) {
      this.leftClass = 'decrease-btn';
      this.rightClass = 'disable-nav';
    } else {
      this.leftClass = 'decrease-btn';
      this.rightClass = 'increase-btn';
    }
  }

  clicked(navigator) {
    console.log('clicked');
    if (navigator == 'left') {
      console.log(this.middleIndex, this.digitArray.length - 2);
      if (this.middleIndex != 0) {
        this.middleIndex--;
        this.changeDigit();
        this.selected = true;
        this.ageOutput.emit(this.digitArray[this.middleIndex]);
      }
    } else {
      if (this.middleIndex <= this.digitArray.length - 2) {
        this.middleIndex++;
        this.changeDigit();
        this.selected = true;
        this.ageOutput.emit(this.digitArray[this.middleIndex]);
      }
    }
    this.checkMiddleIndexForBtnClass();
  }

  disableContextMenu() {
    event.preventDefault();
    event.stopPropagation();
    return false;
  }

  touchStart(navigator) {
    console.log('start');
    window.oncontextmenu = () => {
      event.preventDefault();
      event.stopPropagation();
      return false;
    };
    // event.preventDefault();
    // event.stopPropagation();
    if (navigator == 'left') {
      this.myInterval = setInterval(() => {
        this.clicked('left');
      }, 200);
    } else {
      this.myInterval = setInterval(() => {
        this.clicked('right');
      }, 200);
    }
  }

  touchEnd(navigator) {
    console.log('end');
    window.oncontextmenu = () => {
      return true;
    };
    // event.preventDefault();
    if (navigator == 'left') {
      clearInterval(this.myInterval);
    } else {
      clearInterval(this.myInterval);
    }
  }
}
