import {
  Component,
  OnInit,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-info-two',
  templateUrl: './info-two.component.html',
  styleUrls: ['./info-two.component.css'],
})
export class InfoTwoComponent implements OnInit {
  @Input() countries;
  @Input() locale;
  @Input() loading;
  @Output() saveBtn = new EventEmitter<any>();
  @Output() currentCountry = new EventEmitter<any>();
  public infoTwo: any = {
    parent: '',
    phoneNo: '',
    email: '',
  };
  public allDataFilled = false;
  public emailIsValid = false;

  constructor() {}

  ngOnInit(): void {}
  save() {
    if (this.allDataFilled && !this.loading) this.saveBtn.emit(this.infoTwo);
  }
  phoneNumberSelected(e) {
    this.infoTwo.phoneNo = e;
    this.checkAllDataFilled();
  }
  chooseParent(data) {
    this.infoTwo.parent = data;
    this.checkAllDataFilled();
  }
  selectedCountry(e) {
    //dom changes
    console.log('width is ', $('.country-icon').width());
    console.log('width is ', e.phonecode, e.phonecode.length);
    var codeLength = e.phonecode.length;
    var normalPadding = 94;
    if (codeLength == 2) {
      $('#inputBoxNum').css('paddingLeft', normalPadding + 'px');
    } else {
      var morePadding = (codeLength - 2) * 12;
      var totalPadding = normalPadding + morePadding;
      $('#inputBoxNum').css('paddingLeft', totalPadding + 'px');
    }
    this.currentCountry.emit(e);
  }
  validateEmail(e) {
    const str = e;
    console.log(e, 'e is from here');
    const regex = new RegExp(
      '[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}'
    );
    if (regex.test(str)) {
      console.log(regex.test(str));
      this.emailIsValid = true;
      this.checkAllDataFilled();
    } else {
      this.emailIsValid = false;
      this.checkAllDataFilled();
    }
  }
  checkAllDataFilled() {
    if (this.infoTwo.phoneNo && this.infoTwo.parent) this.allDataFilled = true;
    else this.allDataFilled = false;
  }
  @HostListener('document:keypress', ['$event'])
  onDown(e) {
    if (this.allDataFilled && e.keyCode == 13) {
      this.save();
    }
  }
}
