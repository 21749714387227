import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalComponentsModule } from '../../../global-components/global-components.module';
import { SharedComponentsModule } from '../shared-components/shared-components.module';
import { SignupHomeControllerCnComponent } from './signup-home-controller-cn.component';
// import {FeatherModule} from 'angular-feather';
import { RouterModule } from '@angular/router';
import { ClaimComponent } from './components/claim/claim.component';
import { PreviewComponent } from './components/preview/preview.component';
import { LogoListComponent } from './components/logo-list/logo-list.component';

@NgModule({
  declarations: [
    SignupHomeControllerCnComponent,
    ClaimComponent,
    PreviewComponent,
    LogoListComponent,
  ],
  exports: [SignupHomeControllerCnComponent],
  imports: [
    CommonModule,
    RouterModule,
    GlobalComponentsModule,
    SharedComponentsModule,
  ],
})
export class SignupHomeCnModule {}
