<!-- <div class="trial-wrapper"> -->
<div class="trial-container">
  <div class="title">
    {{ locale?.trialSignup.createAcc }}
  </div>
  <div class="small-title">
    {{ locale?.trialSignup.getStarted }}
  </div>
  <form
    style="width: 100%"
    (keyup.enter)="$event.preventDefault(); continueBtnClicked()"
  >
    <div class="first-name">
      <app-input
        [control]="form.get('firstName')"
        [textAlign]="'left'"
        [class]="firstNameClass"
        [hint]="locale?.codeSuccessful.firstName"
        [width]="'492px'"
        [height]="'68px'"
      ></app-input>
      <div style="margin-top: 12px">
        <app-text
          *ngIf="form.get('firstName').errors && btnFlag"
          [class]="'form-error-label'"
          [withoutImage]="false"
        >
          {{ locale?.error.enterFirstName }}
        </app-text>
      </div>
    </div>
    <div>
      <app-input
        [control]="form.get('lastName')"
        [textAlign]="'left'"
        [class]="lastNameClass"
        [hint]="locale?.codeSuccessful.lastName"
        [width]="'492px'"
        [height]="'68px'"
      ></app-input>
      <div style="margin-top: 12px">
        <app-text
          *ngIf="form.get('lastName').errors && btnFlag"
          [class]="'form-error-label'"
          [withoutImage]="false"
        >
          {{ locale?.error.enterLastName }}
        </app-text>
      </div>
    </div>
  </form>
  <div
    class="d-flex justify-content-center continue-btn"
    (click)="continueBtnClicked()"
  >
    <app-button [class]="'red-primary-l'">{{
      locale?.trialSignup.continue
    }}</app-button>
  </div>
</div>
<!-- </div> -->
