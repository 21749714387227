import { DataRequirements } from '../business-logic/data.requirements';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { UserApiService } from './api-services/user-api.service';
import { Observable } from 'rxjs';
@NgModule({
  imports: [HttpClientModule],
})
export class DataFacade implements DataRequirements {
  constructor(private userApiService: UserApiService) {}

  checkBundle(code): Observable<any> {
    return this.userApiService.checkBundle(code);
  }
  checkUserGrade(header): Observable<any> {
    return this.userApiService.checkUserGrade(header);
  }
  checkUserInfo(header): Observable<any> {
    return this.userApiService.checkUserInfo(header);
  }
  getOTP(header): Observable<any> {
    return this.userApiService.getOTP(header);
  }
  verifyOTP(header): Observable<any> {
    return this.userApiService.verifyOTP(header);
  }
  checkPlan(header): Observable<any> {
    return this.userApiService.checkPlan(header);
  }
  updateUserDetail(header): Observable<any> {
    return this.userApiService.updateUserDetail(header);
  }
  wechatLogin(body): Observable<any> {
    return this.userApiService.wechatLogin(body);
  }
  appleLogin(body): Observable<any> {
    return this.userApiService.appleLogin(body);
  }
  cleverLogin(body): Observable<any> {
    return this.userApiService.cleverLogin(body);
  }
  claimBundle(code): Observable<any> {
    return this.userApiService.claimBundle(code);
  }
  playerLogin(username, password): Observable<any> {
    return this.userApiService.loginUser(username, password);
  }
  globalLogin(username, password): Observable<any> {
    return this.userApiService.globalLogin(username, password);
  }
  getCountryList(): Observable<any> {
    return this.userApiService.getCountryList();
  }
  getStateList(code): Observable<any> {
    return this.userApiService.getStateList(code);
  }
  getCityList(cCode, sCode): Observable<any> {
    return this.userApiService.getCityList(cCode, sCode);
  }
}
