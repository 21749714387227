import { Inject, Injectable } from '@angular/core';
import {
  DataRequirements,
  DataRequirementsInjectionToken,
} from '../data.requirements';
import { BundleMapper } from '../mapper/bundle.mapper';

@Injectable({
  providedIn: 'root',
})
export class UpdateUserDetailUseCase {
  constructor(
    @Inject(DataRequirementsInjectionToken) private data: DataRequirements,
    private mapper: BundleMapper
  ) {}

  run(header) {
    return this.data.updateUserDetail(header);
  }
}
