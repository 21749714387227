import { Inject, Injectable } from '@angular/core';
import {
  DataRequirements,
  DataRequirementsInjectionToken,
} from '../data.requirements';
import { PlayerMapper } from '../mapper/player.mapper';

@Injectable({
  providedIn: 'root',
})
export class GlobalLoginUseCase {
  constructor(
    @Inject(DataRequirementsInjectionToken) private data: DataRequirements
  ) {}

  run(username, password) {
    return this.data.globalLogin(username, password);
  }
}
