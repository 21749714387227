import { Component, Input } from '@angular/core';
import { SharedService } from 'src/app/lib/shared-service';
import { environment } from 'src/environments/environment';
import { LogoStore } from './logo.store';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.css'],
})
export class LogoComponent {
  @Input() src;
  @Input() alt = 'alt';
  @Input() width;
  @Input() height;
  @Input() class = 'default-class';
  @Input() maxWidth;
  @Input() borderRadius;
  @Input() objectFit;
  public logoUrl =
    environment.locale == 'en-us'
      ? '../../../../assets/images/STEMWerkzLogo.svg'
      : '../../../../assets/images/cn-STEMWerkz.png';
  constructor(private store: LogoStore, private shared: SharedService) {}

  public origin;

  logoClicked() {
    this.origin = this.shared.getOrigin();
    if (this.origin == null) this.origin = 'us';
    console.log('testing here', this.origin);

    this.goOrigin();
  }

  goOrigin() {
    switch (this.origin) {
      case 'us':
        window.location.href = environment.startNormalUrl;
        console.log('us');
        break;
      case 'asia':
        window.location.href = environment.startAsiaUrl;
        break;
      default:
        this.checkChina();
    }
  }

  checkChina() {
    if (environment.isChina) {
      window.location.href = environment.startChinaUrl;
      console.log('china');
    } else {
      console.log('no origin in list');
    }
  }
}
