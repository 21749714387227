import { Inject, Component } from '@angular/core';
import {
  BusinessLogicRequirements,
  BusinessRequirementsInjectionToken,
} from '../business-logic.requirements';
import { ContinueSignupStore } from './continue-signup.store';
import { FormBuilder, Validators } from '@angular/forms';
import { SharedService } from 'src/app/lib/shared-service';
import { loadStripe } from '@stripe/stripe-js/pure';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/lib/auth.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-continue-signup',
  templateUrl: './continue-signup-controller.component.html',
  styleUrls: ['./continue-signup-controller.component.css'],
})
export class ContinueSignupControllerComponent {
  public dashboardRedir: any = '';
  public cancelFlag: boolean = false;
  public failFlag: boolean = false;
  public username: any = '';
  public name: any = '';
  public loading: boolean = false;
  public createFlag: boolean = true;
  public message: any = 'Creating your account now …';

  constructor(
    @Inject(BusinessRequirementsInjectionToken)
    private business: BusinessLogicRequirements,
    private store: ContinueSignupStore,
    private fb: FormBuilder,
    private shared: SharedService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((data) => {
      if (data['show-purchase-success-message'] == '1') {
        this.createFlag = false;
        this.dashboardRedir = localStorage.getItem('dashboardRedir');
        window.location.href = this.dashboardRedir;
        localStorage.clear();
      }
    });
    const paramValue: string = this.route.snapshot.queryParamMap.get('status');
    if (paramValue) {
      const status = paramValue.toLocaleLowerCase();
      if (status == 'failed') {
        this.failFlag = true;
        this.createFlag = false;
      } else if (status == 'cancelled') {
        // alert('Subscription cancelled!');
        this.createFlag = false;
        var userInfo = JSON.parse(localStorage.getItem('userInfo'));
        console.log('userinfo', userInfo);
        this.username = userInfo.username;
        this.name = userInfo.firstname + ' ' + userInfo.lastname;
        this.cancelFlag = true;
      }
    } else if (this.createFlag) {
      setTimeout(() => {
        this.message = 'Redirecting to payment site ...';
        setTimeout(() => {
          this.subscription();
        }, 2000);
      }, 2000);
    }
  }
  subscription() {
    this.loading = true;
    var publish_key = '';
    this.business.getPublishKey().subscribe(async (res) => {
      publish_key = res.publishableKey;
      console.log('publish key', publish_key);
      const stripe = await loadStripe(publish_key);
      stripe
        .redirectToCheckout({
          sessionId: localStorage.getItem('sessionId'),
        })
        .then(function (result) {
          console.log(result);
        });
    });
  }
  back() {
    window.location.href = window.location.origin + '/monthly/signup';
    localStorage.clear();
  }
}
