<app-custom-feedback
  style="position: fixed; top: 0; right: 0; z-index: 999"
  *ngIf="showFeedback"
  [feedbackTitle]="feedbackTitle"
  [feedbackBody]="feedbackBody"
  [feedbackBtn]="feedbackBtn"
  [tickIcon]="tickIcon"
></app-custom-feedback>
<div class="d-flex flex-column" style="height: 100%">
  <div class="banner d-flex flex-column">
    <div class="d-flex justify-content-between upper-div">
      <app-logo [width]="'200px'" [height]="'48px'"></app-logo>
      <app-button
        id="login"
        [class]="'monthly-button'"
        class="login"
        (click)="gotoDashboard()"
        >Log in</app-button
      >
    </div>
    <div class="container-wrapper d-flex" style="height: 100%">
      <div class="d-flex banner-wrapper">
        <div class="left-div d-flex justify-content-center align-items-center">
          <div>
            <div class="title-1">Become an <br />Annual Member!</div>
          </div>
        </div>
        <div
          class="alpha right-div d-flex justify-content-center align-items-center"
          style="position: relative"
        >
          <div>
            <img class="kat" src="../../../../assets/images/alpha.png" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="wrapper">
    <div class="form-container">
      <div class="offer-div flex-column d-flex submit">
        <div class="special d-flex align-items-center justify-content-center">
          Special Subscription Offer
        </div>
        <div class="d-flex img-div">
          <div style="width: 40%">
            <img class="step2-img" src="../../../../assets/images/step2.png" />
          </div>
          <div class="d-flex flex-column day-7" style="margin-bottom: 20px">
            <div class="title-1 d-flex title-7-div">
              <div>
                <img
                  class="mb-8 tick-7"
                  src="../../../../assets/images/tick_2.png"
                  style="margin-right: 20px"
                />
              </div>
              <div class="d-flex" style="position: relative">
                <div *ngIf="discountValid" class="strikethrough"></div>
                <div class="text-59">{{ yearlySplit[0] }}</div>
                <div
                  class="d-flex flex-column justify-content-center"
                  style="margin-left: 8px"
                >
                  <div class="text-99">{{ yearlySplit[1] }}</div>
                  <div class="per-year">per year until canceled</div>
                </div>
              </div>
            </div>
            <div *ngIf="discountValid" class="text-99 per-month">
              {{ addCurrencySymbol(calculatePrice()) }}
            </div>
            <div class="per-month">
              (Over 2 months free, compared to our monthly pricing!)
            </div>
          </div>
        </div>
        <div class="mt-32">
          <div class="easy-cancel">Easy Cancellation</div>
          <div class="portal-cancel">
            If your family does not absolutely love STEMWerkz, you can cancel in
            the Parent Portal to avoid future charges.
          </div>
        </div>
      </div>
      <div class="step1 d-flex align-items-center">
        <img
          class="hexa"
          style="margin-right: 24px"
          src="../../../../assets/images/hexa1.png"
        />
        <div id="step1" class="step-title">
          <span class="step_no">1. </span>Create Player Account
        </div>
      </div>
      <div class="form-1 d-flex" style="margin-top: 40px">
        <div class="form-font width-25">Name</div>
        <div
          style="position: relative"
          class="d-flex justify-content-between width-75"
        >
          <div style="width: 48%; position: relative">
            <app-input
              [control]="form.get('firstname')"
              [hint]="'First name'"
              [class]="firstnameClass"
              [startIcon]="true"
              [registerInfo]="false"
            >
            </app-input
            ><img
              *ngIf="form.get('firstname').errors && clickBtn"
              class="form-alert"
              src="../../../../assets/images/form_alert.png"
            />
            <img
              *ngIf="form.get('firstname').valid"
              class="form-alert"
              src="../../../../assets/images/form_tick.png"
            />
          </div>
          <div style="width: 48%; position: relative">
            <app-input
              [control]="form.get('lastname')"
              [hint]="'Last name'"
              [class]="lastnameClass"
              [startIcon]="true"
              [registerInfo]="false"
            ></app-input>
            <img
              *ngIf="form.get('lastname').errors && clickBtn"
              class="form-alert"
              src="../../../../assets/images/form_alert.png"
            />
            <img
              *ngIf="form.get('lastname').valid"
              class="form-alert"
              src="../../../../assets/images/form_tick.png"
            />
          </div>
        </div>
      </div>
      <div
        *ngIf="
          (form.get('firstname').errors || form.get('lastname').errors) &&
          clickBtn
        "
        class="error-msg"
      >
        Please enter First name and Last name
      </div>
      <div class="form-1 d-flex" style="margin-top: 32px">
        <div class="form-font width-25">Username</div>
        <div style="position: relative" class="d-flex width-75">
          <app-input
            style="width: 100%"
            [control]="form.get('username')"
            [hint]="'Pick player username'"
            [class]="usernameClass"
            [startIcon]="true"
            [registerInfo]="false"
            (blurEvent)="blurInput($event)"
          ></app-input>
          <img
            *ngIf="(form.get('username').errors && clickBtn) || usernameTaken"
            class="form-alert"
            src="../../../../assets/images/form_alert.png"
          />
          <img
            *ngIf="form.get('username').valid && !usernameTaken"
            class="form-alert"
            src="../../../../assets/images/form_tick.png"
          />
        </div>
      </div>
      <div *ngIf="form.get('username').errors && clickBtn" class="error-msg">
        Please enter username
      </div>
      <div
        *ngIf="form.get('username').valid && usernameTaken"
        class="error-msg"
      >
        There is already an account with this username. Please pick another
        username.
      </div>
      <div class="form-1 d-flex" style="margin-top: 32px">
        <div class="form-font width-25">Password</div>
        <div style="position: relative" class="d-flex width-75">
          <app-input
            style="width: 100%"
            [control]="form.get('password')"
            [hint]="'Set player password'"
            [type]="togglePassword"
            [class]="pwClass"
            [startIcon]="true"
            [registerInfo]="false"
          ></app-input>
          <img
            *ngIf="!eyeFlag"
            (click)="eyeToggle()"
            class="eye"
            src="../../../../assets/images/eye_open.svg"
          />
          <img
            *ngIf="eyeFlag"
            (click)="eyeToggle()"
            class="eye"
            src="../../../../assets/images/eye_close.svg"
          />
          <!-- <img src="../../../../assets/images/eye_open.svg"/> -->
        </div>
      </div>
      <div
        class="pw-length note"
        *ngIf="
          form.get('password').value.length > 0 &&
          form.get('password').errors &&
          !clickBtn
        "
      >
        Passwords must be 8 characters or more
      </div>
      <div *ngIf="form.get('password').errors && clickBtn" class="error-msg">
        Passwords must be 8 characters or more
      </div>
      <div class="form-1 d-flex" style="margin-top: 32px">
        <div class="form-font width-25">Gender</div>
        <div class="d-flex gender-container width-75">
          <div
            [ngClass]="{ 'selected-gender': gender.boy }"
            (click)="genderSelect('boy')"
            class="gender d-flex justify-content-center align-items-center"
          >
            Boy
          </div>
          <div
            [ngClass]="{ 'selected-gender': gender.girl }"
            (click)="genderSelect('girl')"
            class="girl gender d-flex justify-content-center align-items-center"
          >
            Girl
          </div>
          <div
            [ngClass]="{ 'selected-gender': gender.other }"
            (click)="genderSelect('other')"
            class="other gender d-flex justify-content-center align-items-center"
          >
            I prefer not to say
          </div>
        </div>
      </div>
      <div
        *ngIf="!gender.boy && !gender.girl && !gender.other && clickBtn"
        class="error-msg"
      >
        Please choose player gender
      </div>
      <div class="form-1 d-flex" style="margin-top: 32px">
        <div class="form-font width-25">Year of birth</div>
        <div style="position: relative" class="d-flex width-75">
          <app-input
            style="width: 100%"
            [control]="form.get('yob')"
            [hint]="'Enter player birth year'"
            [class]="yobClass"
            [startIcon]="true"
            [registerInfo]="false"
            [numberOnly]="true"
            [maxlength]="'4'"
          ></app-input>
          <img
            *ngIf="form.get('yob').errors && clickBtn"
            class="form-alert"
            src="../../../../assets/images/form_alert.png"
          />
          <img
            *ngIf="form.get('yob').valid"
            class="form-alert"
            src="../../../../assets/images/form_tick.png"
          />
        </div>
      </div>
      <div *ngIf="form.get('yob').errors && clickBtn" class="error-msg">
        Please enter year of birth
      </div>
      <div class="form-1 d-flex" style="margin-top: 32px">
        <div class="form-font width-25">Country</div>
        <div class="d-flex width-75">
          <app-country
            [hint]="countryHint"
            [defaultValue]="countryHint"
            [itemList]="countryList"
            (countryOutput)="chooseCountry($event)"
            [error]="countryError"
            style="width: 100%"
          ></app-country>
        </div>
      </div>

      <div class="step3 d-flex align-items-center" style="margin: 40px 0px">
        <img
          class="hexa"
          style="margin-right: 24px"
          src="../../../../assets/images/hexa2.png"
        />
        <div id="step2" class="step-title">
          <span class="step_no">2. </span>Enter Your Payment Information
        </div>
      </div>
      <div class="form-1 d-flex" style="margin-top: 32px">
        <div class="form-font width-25">Email</div>
        <div style="position: relative" class="d-flex width-75">
          <app-input
            style="width: 100%"
            [control]="form.get('email')"
            [hint]="'Enter your email'"
            [class]="emailClass"
            [startIcon]="true"
            [registerInfo]="false"
          ></app-input>
          <img
            *ngIf="form.get('email').errors && clickBtn"
            class="form-alert"
            src="../../../../assets/images/form_alert.png"
          />
          <img
            *ngIf="form.get('email').valid"
            class="form-alert"
            src="../../../../assets/images/form_tick.png"
          />
        </div>
      </div>
      <div *ngIf="form.get('email').errors && clickBtn" class="error-msg">
        Please enter your email
      </div>
      <!-- <div class="warn">
        Monthly subscribers will not be charged until the 7-day trial has ended!
      </div> -->
      <div class="stripe-container">
        <img class="stripe" src="../../../../assets/images/stripe.svg" />
        <div style="margin-top: 20px; margin-bottom: 20px">
          After clicking the "Submit" button below, you will be directed to
          Stripe’s website. Once you have entered your payment information, you
          will be automatically re-directed back to STEMWerkz.
        </div>
        <div class="lock-div d-flex align-items-center">
          <img class="lock" src="../../../../assets/images/Lock-icon.png" />
          <div>Your information is safe and protected.</div>
        </div>
      </div>
      <div
        class="d-flex flex-column cb-container under-line cursor"
        (click)="toggleCb()"
      >
        <div class="d-flex">
          <div style="width: 7%; position: relative">
            <input
              #agreeCb
              class="cb cursor"
              style="position: absolute; opacity: 0"
              [checked]="agree"
              type="checkbox"
            />
            <div
              class="cb checkmark"
              [ngClass]="{ 'red-cb': !agree && clickBtn }"
            ></div>
          </div>
          <div class="cb-text" style="width: 93%">
            I will be charged
            <span *ngIf="!discountValid">
              {{ addCurrencySymbol(yearlyPrice) }}
            </span>
            <span *ngIf="discountValid">
              {{ addCurrencySymbol(calculatePrice()) }}</span
            >
            now, and my STEMWerkz Solo subscription will renew automatically
            every year at the same price until canceled in the Parent Portal.
          </div>
        </div>
        <div
          *ngIf="!agree && clickBtn"
          class="error-msg"
          style="margin-left: 15% !important"
        >
          Please check the box to indicate that you agree to these terms.
        </div>
      </div>
      <div class="term">
        By clicking Submit below, you agree to our
        <span class="pink">
          <a style="color: #3394ab" [href]="tos" target="_blank"
            >Terms & Conditions</a
          ></span
        >
        and
        <span class="pink">
          <a style="color: #3394ab" [href]="pp" target="_blank"
            >Privacy Policy.</a
          ></span
        >
      </div>
      <app-button [class]="submitButton" (click)="submitBtn()"
        >Submit</app-button
      >
    </div>
  </div>
</div>
