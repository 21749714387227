<div class="vid-container">
  <video
    #video
    width="100%"
    height="auto"
    autoplay
    (ended)="vidEnded()"
    (canplay)="video.play()"
    (loadedmetadata)="video.muted = true"
  >
    <source [src]="src" [type]="type" />
  </video>
</div>
