import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
declare var require: any;
@Injectable({
  providedIn: 'root',
})
export class AppleLoginService {
  public detail: any;
  private readonly appleScriptUrl: string =
    'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
  private ready: Promise<boolean> = new Promise((resolve) => {
    if (typeof window !== undefined) {
      const script = require('scriptjs');
      script(this.appleScriptUrl, () => resolve(true));
    } else {
      resolve(false);
    }
  });
  signIn() {
    console.log('inside sign in');
    window['AppleID'].auth.signIn();
    // throw new Error('Method not implemented.');
  }
  setDetail(data) {
    this.detail = data;
  }

  getDetail() {
    return this.detail;
  }

  private SetDetail = new BehaviorSubject({});
  GetDetail = this.SetDetail.asObservable();
  defineDetail(data: any) {
    this.SetDetail.next(data);
  }

  constructor() {
    console.log('constructor works');

    this.ready.then((isReady) => {
      console.log('ready or not');

      if (isReady) {
        this.init();
      }
    });
  }
  private init(): void {
    console.log('init is called');

    window['AppleID'].auth.init({
      clientId: environment.appleClientID,
      scope: 'name email',
      redirectURI: environment.appleRedirURL,
      state: `${window.location.origin}/apple/redirect`,
      usePopup: true,
    });
    document.addEventListener('AppleIDSignInOnSuccess', (data) => {
      console.log('Got a message: ' + JSON.stringify((<any>data).detail));
      this.defineDetail((<any>data).detail);
    });
    document.addEventListener('AppleIDSignInOnFailure', (error) => {
      console.log('Got a message: ' + error);
    });
  }
}
