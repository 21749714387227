<div>
  <app-moving-bg></app-moving-bg>
  <div class="d-flex flex-column align-items-center pt-40" *ngIf="!Bundle">
    <app-logo [class]="'middle'"></app-logo>
    <app-content-code
      class="mt-5p"
      [loading]="codeLoading"
      [locale]="locale"
      (submitClicked)="callClaimBundle($event)"
    ></app-content-code>
  </div>
  <div
    style="width: 100%; height: 100%"
    class="d-flex justify-content-center align-items-center"
  >
    <div *ngIf="spinning" class="loading-wrap">
      <div>{{ this.locale?.login.loading }}</div>
      <div class="spinner-border text-success" role="status">
        <span class="sr-only">{{ this.locale?.login.loading }}</span>
      </div>
    </div>
  </div>
</div>
<app-custom-feedback
  *ngIf="showFeedback"
  [feedbackTitle]="feedbackTitle"
  [feedbackBody]="feedbackBody"
  [feedbackBtn]="feedbackBtn"
  [tickIcon]="tickIcon"
></app-custom-feedback>
