import { Component, OnInit, Inject } from '@angular/core';
import {
  BusinessLogicRequirements,
  BusinessRequirementsInjectionToken,
} from '../../../business-logic.requirements';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/lib/auth.service';

@Component({
  selector: 'app-clever-login',
  templateUrl: './clever-login.component.html',
  styleUrls: ['./clever-login.component.css'],
})
export class CleverLoginComponent implements OnInit {
  constructor(
    @Inject(BusinessRequirementsInjectionToken)
    private business: BusinessLogicRequirements,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      console.log(params);
      this.cleverLogin(params['code']);
    });
  }
  public dashboardRedir: any;
  public feedbackTitle = '';
  public feedbackBody = '';
  public feedbackBtn = {
    status: false,
    text: '',
  };
  public tickIcon = true;
  public showFeedback = false;

  cleverLogin(code) {
    var body = {
      code: code,
      redirect_uri: window.origin + '/clever/redirect',
    };
    this.business.cleverLogin(body).subscribe(
      (data) => {
        console.log('data', data);
        var header = {
          apikey: data.ApiKey,
          username: data.username,
          user: data.userId,
          password: data.Token,
          playerid: data.idPlayer,
        };
        let userInfo = this.authService.encrypt(JSON.stringify(data), null);
        localStorage.setItem('userInfo', userInfo);
        this.dashboardRedir = this.authService.getRedirectDashboardUrl(header);
        localStorage.setItem('dashboardRedir', this.dashboardRedir);
        window.location.replace(this.dashboardRedir);
      },
      (err) => {
        console.log('err', err);
        if (
          err.status == '400' &&
          err.error.error.code == 'subscriptionExpiryError'
        ) {
          var header = {
            apikey: err.error.ApiKey,
            username: err.error.username,
            user: err.error.userId,
            password: err.error.Token,
            playerid: err.error.idPlayer,
          };
          let userInfo = this.authService.encrypt(
            JSON.stringify(err.error),
            null
          );
          localStorage.setItem('userInfo', userInfo);
          this.dashboardRedir =
            this.authService.getRedirectDashboardUrl(header);
          localStorage.setItem('dashboardRedir', this.dashboardRedir);
          this.checkGrade(header);
        }
        if (err.error.message == 'invalid code') {
          this.feedbackTitle = err.error.message;
          this.feedbackBody = '';
          this.feedbackBtn = {
            status: false,
            text: '',
          };
          this.tickIcon = false;
          this.showFeedback = true;
          setTimeout(() => {
            this.showFeedback = false;
            // this.Bundle = false;
            this.router.navigate(['']);
          }, 3000);
        }
      }
    );
  }
  checkGrade(header) {
    this.business.checkUserGrade(header).subscribe(
      (res) => {
        console.log('check plan', res);
        if (res.bundledGrades.length != 0) {
          setTimeout(() => {
            window.location.replace(this.dashboardRedir);
          }, 3000);
        } else {
          localStorage.setItem('noSub&Grade', 'true');
          this.router.navigate(['/claim/code']);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
}
