<app-text class="normal-text">{{ locale?.claimedSignup.howOld }}</app-text>
<div class="age-wrapper">
  <!-- <div class="nav-btn" (click)="clickedLeft()" (mousedown)="mouseDownLeft()" (mouseup)="mouseUpLeft()">
    <app-image [src]="leftArrow"></app-image>
  </div> -->
  <app-button
    [class]="leftClass"
    (btnClick)="clicked('left')"
    (btnDown)="mouseDown('left')"
    (btnUp)="mouseUp('left')"
    (btnStart)="touchStart('left')"
    (btnEnd)="touchEnd('left')"
    (btnCancel)="touchEnd('left')"
  >
    <app-image [src]="leftArrow"></app-image>
  </app-button>
  <div class="digit-wrapper">
    <div
      class="sm"
      [ngClass]="{
        cursor: showDigitArray[0],
        'non-cursor': !showDigitArray[0]
      }"
      (click)="clickedNumber(showDigitArray[0])"
    >
      {{ showDigitArray[0] ? showDigitArray[0] : "" }}
    </div>
    <div
      class="md"
      [ngClass]="{
        cursor: showDigitArray[1],
        'non-cursor': !showDigitArray[1]
      }"
      (click)="clickedNumber(showDigitArray[1])"
    >
      {{ showDigitArray[1] ? showDigitArray[1] : "" }}
    </div>
    <div
      class="lg cursor"
      (click)="clickedNumber(showDigitArray[2])"
      [ngClass]="{ 'normal-bg': !selected, 'selected-bg': selected }"
    >
      {{ showDigitArray[2] ? showDigitArray[2] : "" }}
    </div>
    <div
      class="md"
      [ngClass]="{
        cursor: showDigitArray[3],
        'non-cursor': !showDigitArray[3]
      }"
      (click)="clickedNumber(showDigitArray[3])"
    >
      {{ showDigitArray[3] ? showDigitArray[3] : "" }}
    </div>
    <div
      class="sm"
      [ngClass]="{
        cursor: showDigitArray[4],
        'non-cursor': !showDigitArray[4]
      }"
      (click)="clickedNumber(showDigitArray[4])"
    >
      {{ showDigitArray[4] ? showDigitArray[4] : "" }}
    </div>
  </div>
  <!-- <div class="nav-btn right-btn" (click)="clickedRight()" (mousedown)="mouseDownRight()" (mouseup)="mouseUpRight()">
    <app-image [src]="rightArrow"></app-image>
  </div> -->
  <app-button
    [class]="rightClass"
    (btnClick)="clicked('right')"
    (btnDown)="mouseDown('right')"
    (btnUp)="mouseUp('right')"
    (btnStart)="touchStart('right')"
    (btnEnd)="touchEnd('right')"
    (btnCancel)="touchEnd('right')"
  >
    <app-image [src]="rightArrow"></app-image>
  </app-button>
</div>
<div class="d-flex justify-content-center w-100" style="margin-bottom: 24px">
  <app-text
    *ngIf="!selected"
    class="normal-text-400"
    [ngClass]="{ 'text-accent500': !error, 'text-r100': error }"
    [withoutImage]="!error"
  >
    <!-- <app-image [src]="redArrowUrl" [width]="'20px'" [height]="'20px'" [maxWidth]="'20px'" style="margin-right: 12px"></app-image> -->
    {{ locale?.claimedSignup.pickYourAge }}
  </app-text>
  <app-text *ngIf="selected" class="normal-text-400 text-accent500">
    {{ digitArray[middleIndex] }} {{ locale?.claimedSignup.yearOld }}
  </app-text>
</div>
