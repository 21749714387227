import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class BackStore {

}

export enum BackStoreKeys {

}
