import {
  Component,
  Inject,
  Input,
  Output,
  OnInit,
  ElementRef,
  HostListener,
} from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/lib/auth.service';
import { SharedService } from 'src/app/lib/shared-service';
import {
  BusinessLogicRequirements,
  BusinessRequirementsInjectionToken,
} from '../../../business-logic.requirements';
import { interval } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-phone-login',
  templateUrl: './phone-login.component.html',
  styleUrls: ['./phone-login.component.css'],
})
export class PhoneLoginComponent implements OnInit {
  @Input() countries: any;
  @Input() locale: any;
  public toggleVerify: boolean = false;
  public currentCountry;
  public phone;
  public fullCode: any;
  public dashboardRedir = '';
  public timerToggle: boolean;
  public feedbackTitle = '';
  public feedbackBody = '';
  public feedbackBtn = {
    status: false,
    text: '',
  };
  public tickIcon = true;
  public showFeedback = false;
  public Bundle: boolean = true;
  public codeLoading: boolean = false;
  public verifyLoading: boolean = false;
  public sendOtpLoading: boolean = false;
  constructor(
    @Inject(BusinessRequirementsInjectionToken)
    private business: BusinessLogicRequirements,
    private shared: SharedService,
    private _authService: AuthService,
    private router: Router,
    private elementRef: ElementRef
  ) {}

  ngOnInit(): void {}
  ngAfterViewInit() {}
  // this called every time when user changed the code
  onCodeChanged(code: string) {
    if (code.length < 4) {
      this.codeFilled = false;
    }
  }

  // this called only if user entered full code
  public codeFilled: boolean = false;
  onCodeCompleted(code: string) {
    console.log('full code is', code);
    this.fullCode = code;
    this.codeFilled = true;
  }
  continueVerify() {
    this.sendOtpLoading = true;
    this.timerToggle = false;
    var callDuration = this.elementRef.nativeElement.querySelector('#time');
    this.startTimer(callDuration);
    var body = {
      phone: {
        countryCode: this.currentCountry.phonecode,
        number: this.phone,
      },
    };
    this.business.getOTP(body).subscribe(
      (data) => {
        this.sendOtpLoading = false;
        console.log('otp otp', data);
        this.toggleVerify = true;
      },
      (err) => {
        this.sendOtpLoading = false;
        console.log(err);
        console.log(err.message);
        this.feedbackTitle = err.error.message;
        this.feedbackBody = '';
        this.feedbackBtn = {
          status: false,
          text: '',
        };
        this.tickIcon = false;
        this.showFeedback = true;
        setTimeout(() => {
          this.showFeedback = false;
        }, 3000);
      }
    );
  }
  public invalidOTP: boolean = false;
  verifyBtn() {
    this.verifyLoading = true;
    var body = {
      phone: {
        countryCode: this.currentCountry.phonecode,
        number: this.phone,
      },
      otp: this.fullCode,
    };
    if (this.fullCode) {
      this.business.verifyOTP(body).subscribe(
        (data) => {
          this.feedbackTitle = this.locale?.login.loggedIn;
          this.feedbackBody = '';
          this.feedbackBtn = {
            status: false,
            text: '',
          };
          this.showFeedback = true;
          this.tickIcon = true;
          setTimeout(() => {
            this.showFeedback = false;
          }, 3000);

          this.verifyLoading = false;
          console.log('otp otp', data);
          if (data.error.code == 'subscriptionExpiryError') {
            localStorage.setItem('noSub', 'true');
          }
          let userInfo = this._authService.encrypt(JSON.stringify(data), null);
          localStorage.setItem('userInfo', userInfo);
          var header = {
            apikey: data.ApiKey,
            username: data.username,
            user: data.userId,
            password: data.Token,
            playerid: data.idPlayer ? data.idPlayer : data.idUser,
          };
          this.dashboardRedir =
            this._authService.getRedirectDashboardUrl(header);
          localStorage.setItem('dashboardRedir', this.dashboardRedir);
          console.log('head test', header);
          if (data.UserType == 3) {
            var header2 = {
              apikey: data.ApiKey,
              username: data.username,
              user: data.userId,
              password: data.Token,
              childId: data.childId,
              parentId: data.parentId,
            };
            this.business.checkUserInfo(header2).subscribe(
              (res) => {
                console.log('info detail', res);
                if (res.skipChildInfo == true && res.skipParentInfo == true) {
                  this.business.checkUserGrade(header).subscribe(
                    (res) => {
                      console.log('res', res);
                      // if bundle then go dashboard
                      if (res.bundledGrades.length != 0) {
                        this.redirectStemWerkz();
                      }
                      // if no bundle then open claim box
                      else {
                        this.Bundle = false;
                      }
                    },
                    // check bundle error
                    (err) => {
                      console.log(err);
                      console.log(err.message);
                      this.feedbackTitle = err.error.message || err.message;
                      this.feedbackBody = '';
                      this.feedbackBtn = {
                        status: false,
                        text: '',
                      };
                      this.tickIcon = false;
                      this.showFeedback = true;
                      setTimeout(() => {
                        this.showFeedback = false;
                        // this.Bundle = false;
                        this.router.navigate(['']);
                      }, 3000);
                    }
                  );
                }
                if (res.skipChildInfo == false && res.skipParentInfo == false) {
                  localStorage.setItem('bothInfo', 'true');
                  this.router.navigateByUrl('info/parent');
                }
                if (res.skipChildInfo == false && res.skipParentInfo == true) {
                  this.router.navigateByUrl('info/child');
                }
                if (res.skipChildInfo == true && res.skipParentInfo == false) {
                  this.router.navigateByUrl('info/parent');
                }
              },
              //check bundle api error
              (err) => {}
            );
          } else if (data.UserType == 5) {
            this.redirectStemWerkz();
          }
        },
        (err) => {
          this.verifyLoading = false;
          this.invalidOTP = true;
          console.log(err);
          console.log(err.message);
          this.feedbackTitle = err.error.message;
          this.feedbackBody = '';
          this.feedbackBtn = {
            status: false,
            text: '',
          };
          this.tickIcon = false;
          this.showFeedback = true;
          setTimeout(() => {
            this.showFeedback = false;
          }, 3000);
          //call DOM CHANGES
          this.forceDomChange();
        }
      );
    }
  }
  selectedCountry(e) {
    console.log('country', e);
    //dom changes
    console.log('width is ', $('.country-icon').width());
    console.log('width is ', e.phonecode, e.phonecode.length);
    var codeLength = e.phonecode.length;
    var normalPadding = 94;
    if (codeLength == 2) {
      $('#inputBoxNum').css('paddingLeft', normalPadding + 'px');
    } else {
      var morePadding = (codeLength - 2) * 12;
      var totalPadding = normalPadding + morePadding;
      $('#inputBoxNum').css('paddingLeft', totalPadding + 'px');
    }
    this.currentCountry = e;
  }
  public phoneFilled: boolean = false;
  phoneNumberSelected(e) {
    console.log('phone', e);
    this.phone = e;
    if (this.phone.length > 0) {
      this.phoneFilled = true;
    }
  }
  redirectStemWerkz() {
    window.location.href = this.dashboardRedir;
  }

  startTimer(display) {
    var timer = 60;
    var minutes;
    var seconds;

    var myInterval = interval(1000).subscribe((x) => {
      minutes = Math.floor(timer / 60);
      seconds = Math.floor(timer % 60);

      // minutes = minutes < 10 ? '0' + minutes : minutes;
      seconds = seconds < 10 ? '0' + seconds : seconds;

      display.textContent = minutes + ':' + seconds;

      --timer;
      console.log('timer going', this.timerToggle);

      if (timer < 0) {
        console.log('timer is ended');
        this.timerToggle = true;
        myInterval.unsubscribe();
      }
    });
  }
  // enterClicked(e) {
  //   this.continueVerify();
  // }
  callClaimBundle(bundleCode) {
    this.codeLoading = true;
    var encryCode = btoa(bundleCode);
    var userInfo = this._authService.decrypt(
      localStorage.getItem('userInfo'),
      environment.secretkey
    );
    userInfo = JSON.parse(userInfo);
    var header = {
      apikey: userInfo.ApiKey,
      username: userInfo.username,
      user: userInfo.userId,
      password: userInfo.Token,
      playerid: userInfo.idPlayer ? userInfo.idPlayer : userInfo.idUser,
      code: encryCode,
    };
    this.business.claimBundle(header).subscribe(
      (data) => {
        this.codeLoading = false;
        console.log(data);
        localStorage.setItem(
          'bundleInfo',
          this._authService.encrypt(JSON.stringify(data), null)
        );
        this.router.navigate(['/code/successful']);
      },
      (err) => {
        this.codeLoading = false;
        console.log(err.message);
        this.feedbackTitle = err.error.message || err.message;
        if (err.error.message == 'Invalid code provided.') {
          this.feedbackTitle = this.locale?.login.invalid;
        }
        this.feedbackBody = '';
        this.feedbackBtn = {
          status: false,
          text: '',
        };
        this.tickIcon = false;
        this.showFeedback = true;
        setTimeout(() => {
          this.showFeedback = false;
        }, 3000);
      }
    );
  }

  @HostListener('document:keypress', ['$event'])
  onDown(e) {
    if (this.codeFilled && e.keyCode == 13) {
      this.verifyBtn();
    }
  }
  forceDomChange() {
    if (this.invalidOTP && this.codeFilled) {
      $('.has-value').css('border', '1px solid red');
    }
  }
}
