import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { environment } from '../../environments/environment';
import * as CryptoJS from 'crypto-js';
import { SharedService } from './shared-service';

@Injectable()
export class AuthService {
  constructor(private router: Router, private shared: SharedService) {}

  public userData = {
    email: '',
    username: '',
    password: '',
    usertype: 3,
    firstName: '',
    lastName: '',
    yearOfBirth: '',
    country: '',
    gender: '',
    vendor: '',
    referral: '',
    contentcode: '',
  };

  encrypt(message: string, secretkey: string) {
    let encrypted = CryptoJS.AES.encrypt(
      message,
      secretkey ? secretkey : environment.secretkey
    ).toString();
    return encrypted;
  }

  decrypt(encrypted: string, secretkey: string) {
    let decrypted = CryptoJS.AES.decrypt(
      encrypted,
      secretkey ? secretkey : environment.secretkey
    ).toString(CryptoJS.enc.Utf8);
    return decrypted;
  }

  getBundleInforInfo() {
    let bundleInfo = null;
    try {
      var encodedData = localStorage.getItem('bundleInfo');
      console.log('auth , encode ', encodedData);
      bundleInfo = JSON.parse(this.decrypt(encodedData, null));
      console.log('auth , bundleinfo ', bundleInfo);
      return bundleInfo;
    } catch (err) {
      return bundleInfo;
    }
  }

  setNameInfo(name) {
    this.userData.firstName = name.firstName;
    this.userData.lastName = name.lastName;
    localStorage.setItem(
      'userInfo',
      this.encrypt(JSON.stringify(this.userData), null)
    );
    var encodedData = localStorage.getItem('userInfo');
    var userInfo = JSON.parse(this.decrypt(encodedData, null));
    console.log('userInfo', userInfo);
  }

  setRegisterInfo(name) {
    this.userData.username = name.username;
    this.userData.password = name.password;
    this.userData.email = name.email;
    localStorage.setItem(
      'userInfo',
      this.encrypt(JSON.stringify(this.userData), null)
    );
  }

  getUserData() {
    let userInfo = null;
    try {
      var encodedData = localStorage.getItem('userInfo');
      userInfo = JSON.parse(this.decrypt(encodedData, null));
      return userInfo;
    } catch (err) {
      return userInfo;
    }
  }

  getRedirectDashboardUrl(header) {
    console.log('getRedirectDashboardUrl', header);

    var username = encodeURIComponent(
      this.encrypt(header.username, environment.secretkey)
    );
    var userid = encodeURIComponent(
      this.encrypt(header.user, environment.secretkey)
    );
    var token = encodeURIComponent(
      this.encrypt(header.password, environment.secretkey)
    );
    var apikey = encodeURIComponent(
      this.encrypt(header.apikey, environment.secretkey)
    );
    var playerid = encodeURIComponent(
      this.encrypt(header.playerid, environment.secretkey)
    );
    var questID = this.shared.getParamsForSkill().questId;
    var skill = this.shared.getParamsForSkill().skill;
    let url;
    console.log('here test', this.shared.getParamsForSkill());
    var sessionId = localStorage.getItem('oam-sessionId');
    if (
      this.shared.getParamsForSkill().questId &&
      this.shared.getParamsForSkill().skill
    )
      url =
        environment.dashboardurl +
        '?username=' +
        username +
        '&userid=' +
        userid +
        '&token=' +
        token +
        '&apikey=' +
        apikey +
        '&playerid=' +
        playerid +
        '&questID=' +
        questID +
        '&skill=' +
        skill +
        '&sessionId=' +
        sessionId;
    else
      url =
        environment.dashboardurl +
        '?username=' +
        username +
        '&userid=' +
        userid +
        '&token=' +
        token +
        '&apikey=' +
        apikey +
        '&playerid=' +
        playerid +
        '&sessionId=' +
        sessionId;
    console.log('url', url);
    if (this.shared.getNextTo()) {
      url += '&nextto=' + this.shared.getNextTo();
    }

    return url;
  }
}
