<div>
  <app-moving-bg></app-moving-bg>
  <div class="d-flex justify-content-center align-items-center">
    <div class="clever-wrap">
      <div style="margin-bottom: 12px">Logging in with Clever ...</div>
      <div class="spinner-border text-success" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</div>
<app-custom-feedback
  *ngIf="showFeedback"
  [feedbackTitle]="feedbackTitle"
  [feedbackBody]="feedbackBody"
  [feedbackBtn]="feedbackBtn"
  [tickIcon]="tickIcon"
></app-custom-feedback>
