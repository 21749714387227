import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-state-list',
  templateUrl: './state-list.component.html',
  styleUrls: ['./state-list.component.css'],
})
export class StateListComponent implements OnInit {
  @Input() states;
  @Output() selectedState = new EventEmitter<any>();
  constructor(private fb: FormBuilder) {}
  public isScrolled: boolean = false;
  // public form = this.fb.group({
  //   name: this.fb.control(''),
  //   search: this.fb.control(''),
  // });
  public currentCountry: any = {
    name: '',
    translatedName: '',
    countryCode: '',
    isoCode: '',
  };

  public searchName: any;
  public dropdownToggle: boolean = false;
  ngOnInit(): void {
    // this.form.get('name').valueChanges.subscribe((value) => {
    //   console.log('name has changed', value);
    // });
  }
  ngAfterViewChecked() {
    // try {
    //   this.scrollTo();
    // } catch (error) {
    //   console.log('not loaded yet..');
    // }
  }
  ngOnChanges() {
    try {
      console.log('whats in states', this.states);
      this.currentCountry = {
        name: '',
        translatedName: '',
        countryCode: '',
        isoCode: '',
      };
      // this.currentCountry = this.states[0];
    } catch (error) {
      console.log('not loaded yet..');
    }
  }
  @HostListener('document:click', ['$event'])
  onClick(e) {
    var target = e.target;

    if (target.closest('.state-input')) {
      try {
        this.scrollTo();
      } catch (error) {
        console.log('closing dropdown');
      }
    } else if (!target.closest('.search-input')) {
      this.dropdownToggle = false;
    }
  }
  scrollTo() {
    var isoCode = this.currentCountry.isoCode;
    var myElement = document.getElementById(isoCode);
    var topPos = myElement.offsetTop - 160;
    document.getElementById('scrolling_div').scrollTop = topPos;
  }
  selectCountry(data) {
    this.currentCountry = data;
    this.selectedState.emit(data);
  }
  dropdownClicked() {
    this.dropdownToggle = !this.dropdownToggle;
  }
}
