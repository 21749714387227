<div style="position: relative">
  <div style="position: relative" (click)="dropdownClicked()">
    <!-- <div class="country-icon">
      <div>{{ currentCountry?.flag }}</div>
    </div> -->
    <input
      [placeholder]="'Search Country'"
      [(ngModel)]="currentCountry.name"
      class="country-input country-origin"
      readonly
    />
    <img class="down-icon" src="./assets/images/downIcon.svg" />
  </div>
  <div *ngIf="dropdownToggle" class="dropdown">
    <input
      [placeholder]="'Search Country'"
      [(ngModel)]="searchName"
      class="search-input"
    />
    <div id="scrolling_div" class="country-list">
      <div
        [ngClass]="
          currentCountry.name == item.name ? 'country-selected' : 'country-item'
        "
        (click)="selectCountry(item)"
        [id]="item.isoCode"
        class="country-item d-flex justify-content-between"
        *ngFor="let item of countries.countries | filter: searchName"
      >
        <span>{{ item.name }}</span>
        <!-- <span>{{ item.phonecode }}</span> -->
      </div>
    </div>
  </div>
</div>
