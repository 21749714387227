import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
@Component({
  selector: 'app-successful-show',
  templateUrl: './successful-show.component.html',
  styleUrls: ['./successful-show.component.css'],
})
export class SuccessfulShowComponent implements OnInit {
  @Input() accountType: any;
  @Input() locale: any;
  constructor() {}

  ngOnInit(): void {}
}
