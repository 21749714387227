import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.css'],
})
export class LoginFormComponent implements OnInit {
  public btnFlag: boolean = false;
  public usernameClass = 'login-form global';
  public pwClass = 'login-form  global';
  public showToggle: boolean = false;
  public togglePassword = 'password';

  @Input() locale: any;
  @Input() loading: any;
  @Output() loginEvent = new EventEmitter<any>();

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.form.valueChanges.subscribe((value) => {
      this.btnFlag = false;
      this.usernameClass = 'login-form  global';
      this.pwClass = 'login-form  global';
    });
  }
  public form = this.fb.group({
    username: this.fb.control('', [Validators.required]),
    password: this.fb.control('', [
      Validators.required,
      // Validators.minLength(8),
    ]),
  });
  LoginClick() {
    this.btnFlag = true;
    if (this.form.valid) {
      var data = {
        username: this.form.get('username').value.trim(),
        password: this.form.get('password').value.trim(),
      };
      this.loginEvent.emit(data);
    } else {
      if (this.form.get('username').errors)
        this.usernameClass = 'loginClass-error  global';
      if (this.form.get('password').errors)
        this.pwClass = 'loginClass-error  global';
    }
  }
  pwToggle() {
    this.showToggle = !this.showToggle;
    if (this.showToggle) {
      this.togglePassword = 'text';
    } else {
      this.togglePassword = 'password';
    }
  }
  forgetLink() {
    return environment.membershipUrl + 'forgot-password';
  }
}
