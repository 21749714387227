<app-text class="normal-text">{{ locale?.claimedSignup.gender }}</app-text>
<div class="gender-list">
  <ng-container *ngFor="let item of genderList; let index = index">
    <app-gender-item
      [itemName]="item.gender"
      [index]="index"
      [class]="item.wrapperClass"
      [itemClass]="item.class"
      (click)="chooseGender(index)"
    ></app-gender-item>
  </ng-container>
</div>
<div style="margin-top: 12px; margin-bottom: 24px" *ngIf="error">
  <app-text class="text-r100 error-text" [withoutImage]="false">{{
    locale?.claimedSignup.pickgender
  }}</app-text>
</div>
