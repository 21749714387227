import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  Output,
} from '@angular/core';
import { FailcodeStore } from './failcode.store';
import { FormBuilder, Validators } from '@angular/forms';
import { SharedService } from 'src/app/lib/shared-service';

@Component({
  selector: 'app-failcode',
  templateUrl: './failcode.component.html',
  styleUrls: ['./failcode.component.css'],
})
export class FailcodeComponent {
  @Output() submitClicked = new EventEmitter<any>();
  @Input() errorMsg: any;
  @Input() locale: any;
  @Input() codeNo: any = 'ERROR FEEDBACK MESSAGE';
  @Input() loading: boolean = false;
  @Output() trialBtn = new EventEmitter<any>();
  @Output() loginBtn = new EventEmitter<any>();
  public showError: any;
  public mobileView;
  public mobileViewService;
  public codeArray = [];

  ngOnInit() {
    console.log('fail loading', this.loading);

    // var str = 'abcdefghijkl';
    this.codeArray = this.codeNo.match(/.{1,4}/g);
    console.log(this.codeArray);
    // console.log(str.match(/.{1,3}/g));
    console.log('zha error', this.errorMsg);

    this.form.valueChanges.subscribe((value) => {
      this.codeClass = 'content-code';
    });

    this.mobileViewService = this.shared.GetMobileView.subscribe((data) => {
      this.mobileView = data;
      console.log('mobo test', this.mobileView);
    });
  }

  ngOnChanges() {
    if (this.errorMsg == 400) {
      this.showError = this.locale.failCode.invalid;
    } else if (this.errorMsg == 422) {
      this.showError = this.locale.failCode.claimed;
    }
  }

  public mask = {
    guide: false,
    showMask: true,
    mask: [
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      '-',
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      '-',
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      '-',
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
    ],
  };
  public code: any;
  public codeClass: any = 'content-code';
  public form = this.fb.group({
    code: this.fb.control('', [Validators.required, Validators.minLength(19)]),
  });

  submitBtn($event) {
    if (this.form.valid) {
      // this.showerror = false;
      // console.log("code",this.form.get('code').value);
      var newStr = this.form.get('code').value.replace(/-/g, '');
      this.submitClicked.emit(newStr);
    } else {
      this.codeClass = 'code-error';
      // this.showerror = true;
      // this.errorMsg = 'Please enter 16-digit code';
    }
  }
  trialClick($event) {
    this.trialBtn.emit($event);
  }

  loginClick($event) {
    this.loginBtn.emit($event);
  }

  constructor(
    private store: FailcodeStore,
    private fb: FormBuilder,
    private shared: SharedService
  ) {}
}
