import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ParentInfoStore {

}

export enum ParentInfoStoreKeys {

}
