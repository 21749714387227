import {BundleModel} from '../../../models/bundle.model';
import {ItemMapper} from '../../../lib/item-mapper';

export class BundleMapper extends ItemMapper<BundleModel> {
  protected mapFromItem(item): BundleModel {
      const result = new BundleModel();
      result.dashboardMediumImageUrl = item.dashBoardMediumImageURL;
      result.gradeText = item.gradeText;
      result.levelBackground = item.levelBackground;
      result.maxAge = item.maxAge;
      result.minAge = item.minAge;
      result.nameImageUrl = item.nameImageURL;
      result.topicCount = item.topicCount;
      result.uniqueId = item.uniqueID;
      return result;
  }
}
