import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import { TextComponent } from './text/text.component';
import { InputComponent } from './input/input.component';
import { ButtonComponent } from './button/button.component';
import { ImageComponent } from './image/image.component';
import { LogoComponent } from './logo/logo.component';
import { FormsModule } from '@angular/forms';
import { TextMaskModule } from 'angular2-text-mask';
import { MovingBGComponent } from './moving-bg/moving-bg.component';
import { VideoComponent } from './video/video.component';
import { LoaderComponent } from './loader/loader.component';
import { CustomFeedbackComponent } from './custom-feedback/custom-feedback.component';
import { QueryParamsLsComponent } from './query-params-ls/query-params-ls.component';
import { HowtoclaimComponent } from './howtoclaim/howtoclaim.component';

const components = [
  TextComponent,
  InputComponent,
  ButtonComponent,
  ImageComponent,
  LogoComponent,
  MovingBGComponent,
  VideoComponent,
  LoaderComponent,
  CustomFeedbackComponent,
  QueryParamsLsComponent,
  HowtoclaimComponent
]

@NgModule({
    declarations: components,
    exports: components,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        TextMaskModule
    ]
})
export class GlobalComponentsModule {}
