import { BusinessLogicRequirements } from '../presentation/business-logic.requirements';
import { NgModule } from '@angular/core';
import { DataRequirementsInjectionToken } from './data.requirements';
import { DataFacade } from '../data/data.facade';
import { BundleMapper } from './mapper/bundle.mapper';
import { CheckBundleUseCase } from './use-cases/check-bundle.use-case';
import { CheckUserGradeUseCase } from './use-cases/check-usergrade.use-case';
import { Observable } from 'rxjs';
import { LoginPlayerUseCase } from './use-cases/login-player.use-case';
import { PlayerMapper } from './mapper/player.mapper';
import { ClaimBundleUseCase } from './use-cases/claim-bundle.use-case';
import { WechatLoginUseCase } from './use-cases/wechat-login.use-case';
import { GetCountryListUseCase } from './use-cases/get-countrylist';
import { GetStateListUseCase } from './use-cases/get-statelist';
import { GetCityListUseCase } from './use-cases/get-citylist';
import { CheckUserInfoUseCase } from './use-cases/check-userInfo.use-case';
import { UpdateUserDetailUseCase } from './use-cases/update-userDetail.use-case';
import { GetOTPUseCase } from './use-cases/get-OTP.use-case';
import { VerifyOTPUseCase } from './use-cases/verify-OTP.use-case';
import { AppleLoginUseCase } from './use-cases/apple-login.use-case';
import { CleverLoginUseCase } from './use-cases/clever-login.use-case';
import { GlobalLoginUseCase } from './use-cases/global-login.use-case';
import { CheckPlanUseCase } from './use-cases/check-plan.use-case';
@NgModule({
  imports: [DataFacade],

  providers: [
    {
      provide: DataRequirementsInjectionToken,
      useClass: DataFacade,
    },
    BundleMapper,
    PlayerMapper,
  ],
})
export class BusinessLogicFacade implements BusinessLogicRequirements {
  constructor(
    private checkBundleUseCase: CheckBundleUseCase,
    private checkUserGradeUseCase: CheckUserGradeUseCase,
    private wechatLoginUseCase: WechatLoginUseCase,
    private appleLoginUseCase: AppleLoginUseCase,
    private cleverLoginUseCase: CleverLoginUseCase,
    private claimBundleUseCase: ClaimBundleUseCase,
    private checkUserInfoUseCase: CheckUserInfoUseCase,
    private getOTPUseCase: GetOTPUseCase,
    private checkPlanUseCase: CheckPlanUseCase,
    private verifyOTPUseCase: VerifyOTPUseCase,
    private updateUserDetailUseCase: UpdateUserDetailUseCase,
    private loginPlayerusecase: LoginPlayerUseCase,
    private globalLoginusecase: GlobalLoginUseCase,
    private getCountryListusecase: GetCountryListUseCase,
    private getStateListusecase: GetStateListUseCase,
    private getCityListusecase: GetCityListUseCase
  ) {}
  checkBundle(code): Observable<any> {
    return this.checkBundleUseCase.run(code);
  }
  checkUserGrade(header): Observable<any> {
    return this.checkUserGradeUseCase.run(header);
  }
  checkUserInfo(header): Observable<any> {
    return this.checkUserInfoUseCase.run(header);
  }
  getOTP(header): Observable<any> {
    return this.getOTPUseCase.run(header);
  }
  verifyOTP(header): Observable<any> {
    return this.verifyOTPUseCase.run(header);
  }
  updateUserDetail(header): Observable<any> {
    return this.updateUserDetailUseCase.run(header);
  }
  wechatLogin(body): Observable<any> {
    return this.wechatLoginUseCase.run(body);
  }
  appleLogin(body): Observable<any> {
    return this.appleLoginUseCase.run(body);
  }
  cleverLogin(body): Observable<any> {
    return this.cleverLoginUseCase.run(body);
  }
  claimBundle(code): Observable<any> {
    console.log('bl facade');
    return this.claimBundleUseCase.run(code);
  }
  loginPlayer(username, password): Observable<any> {
    return this.loginPlayerusecase.run(username, password);
  }
  checkPlan(header): Observable<any> {
    return this.checkPlanUseCase.run(header);
  }
  globalLogin(username, password): Observable<any> {
    return this.globalLoginusecase.run(username, password);
  }
  getCountryList(): Observable<any> {
    return this.getCountryListusecase.run();
  }
  getStateList(code): Observable<any> {
    return this.getStateListusecase.run(code);
  }
  getCityList(cCode, sCode): Observable<any> {
    return this.getCityListusecase.run(cCode, sCode);
  }
}
