import {Component, Input, Output, EventEmitter} from '@angular/core';
import {VideoStore} from './video.store'

@Component({
    selector: 'app-video',
    templateUrl: './video.component.html',
    styleUrls: ['./video.component.css']
})
export class VideoComponent {
    constructor(private store: VideoStore) {}
    @Input() src;
    @Input() type;
    @Output() videoEndNow = new EventEmitter <any> ();

    vidEnded(){
      this.videoEndNow.emit();
    }
}
