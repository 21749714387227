<div class="preview-wrapper">
  <app-text class="text-c300 claimed-text">{{
    locale?.codeSuccessful.youHaveClaimed
  }}</app-text>
  <div
    class="image-wrapper"
    [ngClass]="{ 'justify-content-center': previewList.length < 2 }"
    style="position: relative"
  >
    <ng-container *ngFor="let data of previewList; let index = index">
      <app-image
        class="cursor"
        [src]="data"
        [width]="'275px'"
        [height]="'170px'"
        [maxWidth]="'280px'"
        [class]="'small-preview'"
        (click)="clicked(index)"
      ></app-image>
      <div class="topic-wrapper"></div>
    </ng-container>
  </div>

  <app-text *ngIf="subInfo && previewList.length != 0" style="font-size: 24px">
    +
  </app-text>

  <app-text
    class="text-c300 claimed-text"
    *ngIf="subInfo"
    style="margin-bottom: 16px"
  >
    {{ subInfo }}
    {{ locale?.codeSuccessful.month }} STEMWerkz
    {{ locale?.codeSuccessful.membership }}</app-text
  >
</div>
