<div>
  <div style="position: relative; cursor: pointer" (click)="dropdownClicked()">
    <div class="country-icon">
      <div class="month-div d-flex align-items-center">
        {{ currentMonth }}
        <img class="down-icon" src="./assets/images/downIcon.svg" />
      </div>
    </div>
  </div>
  <div *ngIf="dropdownToggle" class="dropdown">
    <input
      [placeholder]="locale?.placeholder.searchMonth"
      [(ngModel)]="searchName"
      class="search-input"
    />
    <div id="scrolling_div" class="country-list">
      <div
        (click)="selectCountry(item)"
        [id]="item.isoCode"
        class="country-item"
        *ngFor="let item of months | filter: searchName"
      >
        <span>{{ item.name }}</span>
      </div>
    </div>
  </div>
</div>
