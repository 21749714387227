<div class="parent-wrap">
  <div class="parent-info">{{ locale?.childInfo.childInfo }}</div>
  <div class="mb-32">
    <div class="mb-12 normal-text">{{ locale?.childInfo.country }}</div>
    <app-country-list
      [locale]="locale"
      (selectedCountry)="countrySelect($event)"
      [countries]="countries"
    ></app-country-list>
  </div>
  <div class="mb-32">
    <div class="mb-12 normal-text">{{ locale?.childInfo.province }}</div>
    <app-state-list
      [locale]="locale"
      (selectedState)="stateSelect($event)"
      [states]="states"
    ></app-state-list>
  </div>
  <div class="mb-32">
    <div class="mb-12 normal-text">{{ locale?.childInfo.city }}</div>
    <app-city-list
      [locale]="locale"
      (selectedCity)="citySelect($event)"
      [cities]="cities"
    ></app-city-list>
  </div>
  <div class="d-flex align-items-center justify-content-center">
    <app-button
      (click)="save()"
      [class]="
        loading
          ? 'monthly-button-blue-disable'
          : filledAllData
          ? 'monthly-button-blue'
          : 'monthly-button-blue-disable'
      "
      [height]="'64px'"
      [width]="'300px'"
      ><span class="continue-text">{{
        locale?.childInfo.save
      }}</span></app-button
    >
  </div>
</div>
